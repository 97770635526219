export enum LinkCategoriesTypeEnum {
  commonLink = 'common-link',
  dashboardSidebar = 'dashboard-sidebar',
}

export interface ICommonLink {
  id: string;
  links: ILink[];
  roles: { id: string; title: string }[];
  sub_categories: ISubCategory[];
  title: string;
  type: LinkCategoriesTypeEnum;
  weight: number;
  slug?: string;
}

export interface ISubCategory {
  title: string;
  weight: number;
  links: ILink[];
  slug?: string;
  type?: LinkCategoriesTypeEnum;
}

export interface ILink {
  title: string;
  id: string;
  url: string;
  weight: number;
  is_trusted: boolean;
  icon?: string | null;
  slug?: string;
}

export interface IWeightedLinks {
  links: { weight: number }[];
}
