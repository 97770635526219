export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED';
export const MFA_ENROLL = 'MFA_ENROLL';
export const MFA_REQUIRED = 'MFA_REQUIRED';
export const MFA_ENROLL_ACTIVATE = 'MFA_ENROLL_ACTIVATE';
export const SUCCESS = 'SUCCESS';
export const PENDING_REDIRECT = 'PENDING_REDIRECT';
export const MFA = 'MFA';
export const LOGGED_IN = 'LOGGED_IN';
export const INVALID_STATE_TOKEN = 'INVALID_STATE_TOKEN';
export const MFA_SENT = 'MFA_SENT';
export const INTERNAL_ERROR = 'INTERNAL_ERROR';
export const SET_SECURITY_QUESTION = 'SET_SECURITY_QUESTION';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const AWAITING_VERIFICATION = 'AWAITING_VERIFICATION';
export const INVALID_GATEWAY_ID = 'INVALID_GATEWAY_ID';
export const INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN';
export const INVALID_SESSION = 'INVALID_SESSION';
export const LOGGED_OUT = 'LOGGED_OUT';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
