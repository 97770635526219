import { createSlice } from '@reduxjs/toolkit';

import { Types } from '../constants';

interface IInitialState {
  midasFields: Types.IMidasFields2[];
  singleEditingFieldLocation: { docId: string; columnName: string; distanceToScroll: number | undefined };
  PDFWindow: Window | null;
  sortedRows: {
    id: string;
    state: string;
  }[];
}

const initialState: IInitialState = {
  midasFields: [],
  singleEditingFieldLocation: { docId: '', columnName: '', distanceToScroll: undefined },
  PDFWindow: null,
  sortedRows: [],
};

export const jobTableEditingSlice = createSlice({
  name: 'reli-table-editing-data',
  initialState,
  reducers: {
    setMidasFields(state, action) {
      state.midasFields = action.payload;
    },
    setSingleEditingFieldLocation(state, action) {
      state.singleEditingFieldLocation = action.payload;
    },
    resetSingleEditingFieldLocation(state) {
      state.singleEditingFieldLocation = initialState.singleEditingFieldLocation;
    },
    setSortedRows(state, action) {
      state.sortedRows = action.payload;
    },
    setOpenedPDFWindow(state, action) {
      state.PDFWindow = action.payload;
    },
  },
});

export const reliTableEditingActions = jobTableEditingSlice.actions;

export default jobTableEditingSlice.reducer;
