import React, { useEffect, useState } from 'react';
import { Box, Typography, Fade, Button, SxProps, Theme } from '@mui/material';

import { convertSecondsToText, getSessionExpireCountDownTime } from './utility';
import { Cookie } from '../services';
import { INVALID_SESSION } from '../constants/StatusConstants';

const refreshSessionPopupStyles: Record<string, SxProps<Theme> | undefined> = {
  modal: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(5, 5, 5, .7)',
    zIndex: 2000,
    '& p': {
      color: '#493E3E',
    },
  },
  warningContainer: (theme) => ({
    position: 'relative',
    borderRadius: '8px',
    margin: '0 auto',
    backgroundColor: 'rgb(242, 240, 237)',
    height: 'fit-content',
    width: '500px',
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '10px',
    },
  }),
  modalHeading: (theme) => ({
    display: 'flex',
    color: '#0A354C !important',
    fontSize: '24px',
    lineHeight: '24px',
    fontFamily: 'Neuzeit-Grotesk, sans-serif',
    margin: '0 0 20px 0',
    fontWeight: 'bold',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '20px',
      fontSize: '20px',
      margin: '10px 0 10px 0',
    },
  }),
  contentWrapper: (theme) => ({
    width: '450px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  }),
  warningText: (theme) => ({
    fontFamily: 'Neuzeit-Grotesk, sans-serif',
    margin: '0 0 20px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 20px 0',
      fontSize: '15px',
    },
  }),
  btnContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.up('sm')]: {
      margin: '0 0 10px 0',
    },
    [theme.breakpoints.down(360)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  btnProceed: (theme) => ({
    backgroundColor: 'rgb(12 185 18)',
    color: 'white',
    transition: 'all .2s',
    '&:hover': {
      backgroundColor: 'rgb(12 185 18)',
      transform: 'scale(1.1)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down(360)]: {
      width: '80%',
      margin: '0 0 15px 0',
    },
  }),
  btnLogout: (theme) => ({
    backgroundColor: '#b71c1c',
    color: 'white',
    transition: 'all .2s',
    '&:hover': {
      backgroundColor: '#b71c1c',
      transform: 'scale(1.1)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down(360)]: {
      width: '80%',
    },
  }),
};

interface RefreshSessionPopupProps {
  showSessionExpireDialog: boolean;
  handleKeepLogin: () => void;
  handleLogout: () => void;
}

export const RefreshSessionPopup: React.FC<RefreshSessionPopupProps> = ({
  showSessionExpireDialog,
  handleKeepLogin,
  handleLogout,
}) => {
  const [countDownTimer, setCountDownTimer] = useState<number | null>(getSessionExpireCountDownTime());

  useEffect(() => {
    if (window.location.pathname === '/auth/login') {
      return;
    }

    const gatewaySessionExpiresAt = Cookie.getGatewaySessionExpiresAt();

    const modalInterval = setInterval(() => {
      if (typeof countDownTimer === 'number') {
        if (countDownTimer > 0) {
          const newCountDownTimer = countDownTimer - 1;
          setCountDownTimer(newCountDownTimer);
        } else {
          console.info('RefreshSessionPopup', countDownTimer, new Date(), new Date(String(gatewaySessionExpiresAt)));
          clearInterval(modalInterval);
          Cookie.setStatus(INVALID_SESSION);
          handleLogout();
        }
      }
    }, 1000);

    return () => clearInterval(modalInterval);
  }, [countDownTimer]);

  return (
    <>
      <Fade in={showSessionExpireDialog} timeout={{ enter: 300 }}>
        <Box sx={refreshSessionPopupStyles.modal}>
          <Box sx={refreshSessionPopupStyles.warningContainer}>
            <Typography sx={refreshSessionPopupStyles.modalHeading}>Session Expiration Warning</Typography>
            <Box sx={refreshSessionPopupStyles.contentWrapper}>
              <Typography sx={refreshSessionPopupStyles.warningText}>
                Your session will expire in {convertSecondsToText(countDownTimer)}. Do you want to extend the session?
              </Typography>
              <Box sx={refreshSessionPopupStyles.btnContainer}>
                <Button variant="outlined" onClick={handleKeepLogin} sx={refreshSessionPopupStyles.btnProceed}>
                  Accept
                </Button>
                <Button variant="contained" onClick={handleLogout} sx={refreshSessionPopupStyles.btnLogout}>
                  Log Out Now
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
};
