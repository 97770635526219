/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/normalize.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import React from 'react';
import Helmet from 'react-helmet';
import { UserProvider } from './src/context/UserContext';
import { AuthProvider } from './src/context/AuthContext';
import { Provider } from 'react-redux';
import store from './src/redux/index';

import Honeybadger from '@honeybadger-io/js';
import { SocketProvider } from './src/context/SocketContext';
// styles used to fix bug in FontIconPicker dropdown
import './app.styles.css';

const HONEYBADGER_API_KEY = process.env.GATSBY_HONEYBADGER_API_KEY || '';
const HONEYBADGER_ENV = process.env.GATSBY_HONEYBADGER_ENV || process.env.NODE_ENV;

Honeybadger.configure({
  apiKey: HONEYBADGER_API_KEY,
  environment: HONEYBADGER_ENV,
});
Honeybadger.setContext({
  tags: 'gateway-ui',
});

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = ['/insights', '/dashboard', '/tools', '/documents', '/settings/enable-modules'];
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.filter((el) => pathname.includes(el))) {
    document.getElementById('mirador-main-content')?.scroll(0, 0);
  }

  return false;
};

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <Helmet>
      <script async src="//cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script>
      <script src="//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"></script>
    </Helmet>
    <UserProvider>
      <SocketProvider>
        <Provider store={store}>{element}</Provider>
      </SocketProvider>
    </UserProvider>
  </AuthProvider>
);
