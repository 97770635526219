import React, { useContext, useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import { UserContext } from '../../context/UserContext';
import * as Utils from '../../utils';
import { getColors } from '../../utils/colors';
import { StyledConnectAdvisorModal } from './styles/connect-advisors-modal-styles';
import { Types } from '../../constants';

interface IConnectAdvisorModal {
  isOpen: boolean;
  isZoomModal?: boolean;
  closeModal: () => void;
}

export const ConnectAdvisorModal = ({ isOpen, isZoomModal = false, closeModal }: IConnectAdvisorModal) => {
  const userContext = useContext(UserContext);
  const advisorArr = userContext.advisors?.advisors || [];

  const [selectedAdvisorUrl, setSelectedAdvisorUrl] = useState('');

  useEffect(() => {
    setSelectedAdvisorUrl('');
  }, [isOpen]);

  const onNext = () => {
    window.open(selectedAdvisorUrl, '_blank');
    closeModal();
  };

  const classNameBuilder = (isZoom: boolean, adv: Partial<Types.Advisor>) => {
    let className = 'advisor-item ';
    if (isZoom) {
      className += selectedAdvisorUrl === adv.zoom_url ? 'chosen-advisor' : '';
    } else {
      className += selectedAdvisorUrl === adv.meeting_url ? 'chosen-advisor' : '';
    }
    return className;
  };

  const shallAdvisorBeDisplayed = (advisor: Partial<Types.Advisor>) => {
    const neededUrl = isZoomModal ? advisor?.zoom_url?.trim() : advisor?.meeting_url?.trim();
    const isUrlValid = Utils.validURL(String(neededUrl));

    return isUrlValid;
  };

  const advisorClickHandler = (advisor: Partial<Types.Advisor>) => {
    setSelectedAdvisorUrl(String(isZoomModal ? advisor.zoom_url : advisor.meeting_url));
  };

  return (
    <StyledConnectAdvisorModal open={isOpen} onClose={closeModal} colors={getColors()}>
      <Card className="root">
        <CardContent className="content">
          <div>
            <Typography className="title">{isZoomModal ? 'Video Call Your Advisor' : 'Book A Meeting'}</Typography>
            <IconButton className="close" onClick={closeModal}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </div>
          <div className="main-content">
            {advisorArr.filter(shallAdvisorBeDisplayed).map((advisor) => (
              <div
                className={classNameBuilder(isZoomModal, advisor)}
                onClick={() => advisorClickHandler(advisor)}
                key={`${advisor.name}-${advisor.email_address}`}
              >
                <div
                  className="photo-container"
                  style={{ backgroundImage: `url(${advisor.photo?.imgix_url || ''})` }}
                />
                <div>
                  <Typography className="advisor-name">{`${isZoomModal ? 'Video Call with ' : 'Meeting with '} ${
                    advisor.name || ''
                  }`}</Typography>
                  <Typography className="advisor-title">{advisor.title || ''}</Typography>
                </div>
              </div>
            ))}
          </div>
          <Button
            variant="contained"
            className="next-button"
            onClick={onNext}
            disabled={selectedAdvisorUrl === '' || !Utils.validURL(selectedAdvisorUrl)}
          >
            NEXT
          </Button>
        </CardContent>
      </Card>
    </StyledConnectAdvisorModal>
  );
};
