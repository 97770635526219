import React, { useState, useContext, useRef, useEffect } from 'react';
import _ from 'lodash';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Grow, Paper, Button, Tooltip, CircularProgress } from '@mui/material';
import alpha from 'color-alpha';

import { StyledFeedback, StyledPopper } from './styles/feedback-styles';
import { getColors } from '../../utils/colors';
import { requestStatusType, circularProgressStyles, min_feedback_length, max_feedback_length } from './utils';
import { UserContext } from '../../context/UserContext';
import {
  getInitData,
  cleanUp,
  handleSendClick,
  getBtnContent,
  isFeedbackBtnDisabled,
  getSendBtnTitle,
} from './utils/utility-funcs';
import { RectangleButton } from '../../components/ReusableComponents/RectangleButton';

export const FeedbackBtn = () => {
  const anchorRef = useRef(null);
  const userContext = useContext(UserContext);
  const emailsSentByUser = userContext.user?.sent_email_times_counter || 0;
  const themeColors = getColors();

  const [isPopperOpened, setPopperOpened] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackStatus, setFeedbackStatus] = useState<requestStatusType>('init');
  const [action, setAction] = useState('');
  const [emailRestrictionNumber, setEmailRestrictionNumber] = useState(0);
  const [isRefreshingFeedbackData, setRefreshingFeedbackData] = useState(false);

  useEffect(() => {
    getInitData(setAction, setEmailRestrictionNumber);
  }, []);

  useEffect(() => {
    if (!isPopperOpened) {
      cleanUp(setFeedbackText, setFeedbackStatus);
    }
  }, [isPopperOpened]);

  return (
    <StyledFeedback>
      <Tooltip
        arrow
        title={
          isFeedbackBtnDisabled(emailRestrictionNumber, emailsSentByUser) ? 'Feedbacks amount per day is exceeded' : ''
        }
      >
        <span>
          <Button
            className="feedback-btn"
            ref={anchorRef}
            onClick={() => setPopperOpened(true)}
            disabled={isFeedbackBtnDisabled(emailRestrictionNumber, emailsSentByUser) || isRefreshingFeedbackData}
          >
            {isRefreshingFeedbackData ? (
              <CircularProgress style={circularProgressStyles} />
            ) : (
              <ChatOutlinedIcon className="icon" />
            )}
            Feedback?
          </Button>
        </span>
      </Tooltip>
      <StyledPopper colors={themeColors} open={isPopperOpened} anchorEl={anchorRef.current} transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center bottom' }}>
            <Paper className="popper-main">
              <textarea
                placeholder="Please share your feedback or questions. Someone from our team will respond!"
                rows={7}
                cols={35}
                value={feedbackText}
                onChange={(e) =>
                  String(e.target.value).length <= max_feedback_length ? setFeedbackText(String(e.target.value)) : null
                }
              />
              <div className="btns-wrapper">
                <RectangleButton btnText="Cancel" disabled={false} onBtnClick={() => setPopperOpened(false)} />
                <Tooltip arrow title={getSendBtnTitle(feedbackText.length)}>
                  <RectangleButton
                    btnText={getBtnContent(feedbackStatus)}
                    disabled={feedbackText.length < min_feedback_length || feedbackText.length >= max_feedback_length}
                    onBtnClick={() =>
                      handleSendClick(
                        feedbackText,
                        action,
                        setFeedbackStatus,
                        userContext.setToastifySettings,
                        setPopperOpened,
                        userContext.refreshUser,
                        setRefreshingFeedbackData,
                      )
                    }
                    customSx={{
                      backgroundColor: themeColors.primary,
                      '&:hover': { backgroundColor: themeColors.primary },
                      '&:disabled': { backgroundColor: alpha(themeColors.primary, 0.5) },
                    }}
                  />
                </Tooltip>
              </div>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </StyledFeedback>
  );
};
