enum DataType2 {
  boolean = 'boolean',
  string = 'string',
  integer = 'integer',
  double = 'double',
  numeric = 'numeric',
  float = 'float',
  date = 'date',
  time = 'time',
  datetime = 'datetime',
  error = 'error',
}

const HelperText = {
  boolean: 'Valid values are true, false, 1 or 0',
  string: 'Valid values are strings',
  integer: 'Valid values are integers',
  numeric: 'Valid values are numbers',
  double: 'Valid values are numbers with decimals separated by a period',
  float: 'Valid values are numbers with decimals separated by a period',
  date: 'Valid values are YYYY-MM-DD, whith MM value from 01 to 12 and DD value form 01 to 31',
  time: 'Valid values are HH:MM:SS',
  datetime: 'Valid values are YYYY-MM-DDTHH:MM:SSZ',
  error: 'Invalid value provided',
};

export { DataType2, HelperText };
