import * as Types from './Types';
import { DataType2 } from '../components/Reli/constants';

const DummyFile: Types.Files = {
  Name: 'File Full Name',
  'odata.metadata': 'metadata',
  CreatorNameShort: 'FileShort',
  Id: '1sdfasdf234ASDF',
  Info: {
    IsAHomeFolder: true,
    'odata.metadata': 'home metadat',
  },
  Children: [
    {
      FileCount: 3,
      Name: 'Folder1',
      CreationDate: '2020/1/2',
      FileSizeBytes: 1234,
      CreatorNameShort: 'F1',
      'odata.metadata': 'foldermeta',
      Id: '1sdfasdf234ASDF1',
    },
    {
      FileCount: undefined,
      Name: 'Icon1',
      CreationDate: '2020/11/2',
      FileSizeBytes: 1234,
      CreatorNameShort: 'I1',
      'odata.metadata': 'iconmeta',
      Id: '1sdfasdf234ASDF2',
    },
  ],
};

export const dummyJobTableData = [
  {
    id: 'df121201-c0c3-46c6-aa4b-cd5f47b7ed18',
    name: '1.pdf',
    state: 'ingested',
    type: 'application/pdf',
    hash: '9545f96c8a25fe24283d15387fa09f46',
    fields: [
      { name: 'document_type', value: 'alkymi.capital-notice', error: false, type: DataType2.string },
      {
        name: 'investment_inside',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '12539.0',
        error: false,
        score: 0,
      },
      {
        name: 'file_date',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '1528848000',
        error: false,
        score: 0,
      },
      {
        name: 'call_inside',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '12539.0',
        error: false,
        score: 0,
      },
      {
        name: 'adp_contribution',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '12539.0',
        error: false,
        score: 0,
      },
      {
        name: 'document_date',
        status: 'correctly_predicted',
        type: DataType2.date,
        value: '2018-05-31',
        error: false,
        score: 0,
      },
      {
        name: 'document_value',
        status: 'correctly_predicted',
        type: DataType2.float,
        value: '12,538.68',
        error: false,
        score: 0,
      },
      {
        name: 'due_date',
        status: 'correctly_predicted',
        type: DataType2.date,
        value: '2018-06-13',
        error: false,
        score: 0,
      },
      {
        name: 'fund_currency',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'USD',
        error: false,
        score: 0,
      },
      {
        name: 'investment',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'Smith Street Real Estate Capital Fund IV, L.P.',
        error: false,
        score: 0,
      },
      {
        name: 'investor',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'Jane P Smith Trust',
        error: false,
        score: 0,
      },
      {
        name: 'txn_investment_inside',
        status: 'correctly_predicted',
        type: DataType2.float,
        value: '12,539',
        error: false,
        score: 0,
      },
    ],
    author: 'reli-ui',
  },
  {
    id: '1d36f8b9-203a-4ee3-8bf5-e9d5d2a5d1c8',
    name: 'J2021123164643 (another copy).pdf',
    state: 'ingested',
    type: 'application/pdf',
    hash: '4f55450c8b4e2487e67535e75bebe0c7',
    fields: [
      { name: 'document_type', value: 'alkymi.capital-notice', error: false, type: DataType2.string },
      {
        name: 'due_date',
        status: 'correctly_predicted',
        type: DataType2.date,
        value: '2024-02-15',
        error: false,
        score: 0,
      },
      {
        name: 'fund_currency',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'USD',
        error: false,
        score: 0,
      },
      {
        name: 'investment',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'Terion Global Private Investment Partners V Feeder, L.P.',
        error: false,
        score: 0,
      },
      {
        name: 'investor',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'Finance LX acting',
        error: false,
        score: 0,
      },
      {
        name: 'file_date',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '1707955200',
        error: false,
        score: 0,
      },
    ],
    author: 'reli-ui',
  },
  {
    id: '9ad35434-cf4f-4d9e-91f2-b2217d88a3ca',
    name: 'J20200331944.pdf',
    state: 'ingested',
    type: 'application/pdf',
    hash: '045ed1d927695ee14fd098be5b91aad5',
    fields: [
      { name: 'document_type', value: 'alkymi.capital-statement', error: false, type: DataType2.string },
      {
        name: 'file_date',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '1582934400',
        error: false,
        score: 0,
      },
      {
        name: 'balance_beginning',
        status: 'correctly_predicted',
        type: DataType2.float,
        value: '1,877,940',
        error: false,
        score: 0,
      },
      {
        name: 'balance_ending',
        status: 'correctly_predicted',
        type: DataType2.float,
        value: '1,813,180',
        error: false,
        score: 0,
      },
      {
        name: 'investment',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'The EM-LV Fund SPC Ltd.',
        error: false,
        score: 0,
      },
      {
        name: 'investor',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'Ban Pic  Fum SA',
        error: false,
        score: 0,
      },
      {
        name: 'period_end_date',
        status: 'correctly_predicted',
        type: DataType2.date,
        value: '2020-02-29',
        error: false,
        score: 0,
      },
      {
        name: 'period_start_date',
        status: 'correctly_predicted',
        type: DataType2.date,
        value: '2020-01-31',
        error: false,
        score: 0,
      },
    ],
    author: 'reli-ui',
  },
];

export const dummyJobTableDataK1 = [
  {
    id: '8e5268c7-9cdb-4817-96c5-9d088379da08',
    name: 'k1-file.pdf',
    state: 'normalized',
    type: 'application/pdf',
    hash: 'e61069380541ae5accbef4ca87223938',
    fields: [
      { name: 'document_type', value: 'nanonets.k1', error: false, type: 'string' },
      {
        name: 'investment',
        status: 'correctly_predicted',
        object_root:
          '{"id":"3a4bf896-c473-4c0e-8924-62a909146a68","label":"Partnership_name","label_id":"d1487832-e151-4d3c-a5f4-2514b6bcef89","ocr_text":"GT PARTNERS PRIVATE CREDIT FUND I, LP","page_no":1,"score":0.91746074,"status":"correctly_predicted","type":"field","xmax":1057,"xmin":155,"ymax":847,"ymin":806}',
        type: 'string',
        value: 'GT PARTNERS PRIVATE CREDIT FUND I, LP',
        error: false,
        score: '0.91746074',
      },
      {
        name: 'investment_taxid',
        status: 'correctly_predicted',
        object_root:
          '{"id":"3dd001fe-e8e6-4316-96da-9f118c949ef3","label":"Partnership_ID","label_id":"701188b7-b5d0-4caf-b6da-b58af6848461","ocr_text":"87-1285805","page_no":1,"score":0.9976335,"status":"correctly_predicted","type":"field","xmax":398,"xmin":155,"ymax":747,"ymin":706}',
        type: 'string',
        value: '87-1285805',
        error: false,
        score: '0.9976335',
      },
      {
        name: 'investor',
        status: 'correctly_predicted',
        object_root:
          '{"id":"4bbaf560-f8e8-4cd2-b82c-6c6fff1cad84","label":"Partner_name","label_id":"791d0409-79af-477d-a165-04101e40d729","ocr_text":"YOSSI COHEN","page_no":1,"score":0.9985388,"status":"correctly_predicted","type":"field","xmax":423,"xmin":155,"ymax":1397,"ymin":1356}',
        type: 'string',
        value: 'YOSSI COHEN',
        error: false,
        score: '0.9985388',
      },
      {
        name: 'k1_is_amended',
        status: 'correctly_predicted',
        object_root:
          '{"id":"bb722e53-d5c4-4d65-af74-e1d1c76a525e","label":"Amended_K1","label_id":"437c1ed7-a13c-4775-88b2-17248664af5d","ocr_text":"N","page_no":1,"score":0,"status":"correctly_predicted","type":"field","xmax":0,"xmin":0,"ymax":0,"ymin":0}',
        type: 'boolean',
        value: 'False',
        error: false,
        score: '0.0',
      },
      {
        name: 'k1_is_final',
        status: 'correctly_predicted',
        object_root:
          '{"id":"0dee3eb2-3f95-424c-97af-04b715233368","label":"Final_K1","label_id":"1c70f699-6138-4b06-8437-4c1a4d82ed90","ocr_text":"N","page_no":1,"score":0,"status":"correctly_predicted","type":"field","xmax":0,"xmin":0,"ymax":0,"ymin":0}',
        type: 'boolean',
        value: 'False',
        error: false,
        score: '0.0',
      },
      {
        name: 'k1_status_description',
        status: 'correctly_predicted',
        type: 'string',
        value: 'final',
        error: false,
        score: '0',
      },
      {
        name: 'tax_year',
        status: 'correctly_predicted',
        object_root:
          '{"id":"ac9454b6-824b-4dea-9e44-cf7b624f660d","label":"Tax_Year","label_id":"64d525a2-5f26-4f0a-aca3-528207ab3f4c","ocr_text":"2022","page_no":1,"score":0.9804328,"status":"correctly_predicted","type":"field","xmax":1091,"xmin":1020,"ymax":379,"ymin":351}',
        type: 'string',
        value: '2022',
        error: false,
        score: '0.9804328',
      },
    ],
    author: 'reli-ui',
  },
];

export const dummyJobTableDataWithRejectedFiles = [
  {
    id: 'df121201-c0c3-46c6-aa4b-cd5f47b7ed18',
    name: '1.pdf',
    state: 'ingested',
    type: 'application/pdf',
    hash: '9545f96c8a25fe24283d15387fa09f46',
    fields: [
      { name: 'document_type', value: 'alkymi.capital-notice', error: false, type: DataType2.string },
      {
        name: 'investment_inside',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '12539.0',
        error: false,
        score: 0,
      },
      {
        name: 'file_date',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '1528848000',
        error: false,
        score: 0,
      },
      {
        name: 'call_inside',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '12539.0',
        error: false,
        score: 0,
      },
      {
        name: 'adp_contribution',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '12539.0',
        error: false,
        score: 0,
      },
      {
        name: 'document_date',
        status: 'correctly_predicted',
        type: DataType2.date,
        value: '2018-05-31',
        error: false,
        score: 0,
      },
      {
        name: 'document_value',
        status: 'correctly_predicted',
        type: DataType2.float,
        value: '12,538.68',
        error: false,
        score: 0,
      },
      {
        name: 'due_date',
        status: 'correctly_predicted',
        type: DataType2.date,
        value: '2018-06-13',
        error: false,
        score: 0,
      },
      {
        name: 'fund_currency',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'USD',
        error: false,
        score: 0,
      },
      {
        name: 'investment',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'Smith Street Real Estate Capital Fund IV, L.P.',
        error: false,
        score: 0,
      },
      {
        name: 'investor',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'Jane P Smith Trust',
        error: false,
        score: 0,
      },
      {
        name: 'txn_investment_inside',
        status: 'correctly_predicted',
        type: DataType2.float,
        value: '12,539',
        error: false,
        score: 0,
      },
    ],
    author: 'reli-ui',
  },
  {
    id: '1d36f8b9-203a-4ee3-8bf5-e9d5d2a5d1c8',
    name: 'J2021123164643 (another copy).pdf',
    state: 'rejected',
    type: 'application/pdf',
    hash: '4f55450c8b4e2487e67535e75bebe0c7',
    fields: [
      { name: 'document_type', value: 'alkymi.capital-notice', error: false, type: DataType2.string },
      {
        name: 'due_date',
        status: '',
        type: DataType2.date,
        value: null,
        error: false,
        score: 0,
      },
      {
        name: 'fund_currency',
        status: '',
        type: DataType2.string,
        value: null,
        error: false,
        score: 0,
      },
      {
        name: 'investment',
        status: '',
        type: DataType2.string,
        value: null,
        error: false,
        score: 0,
      },
      {
        name: 'investor',
        status: '',
        type: DataType2.string,
        value: null,
        error: false,
        score: 0,
      },
      {
        name: 'file_date',
        status: '',
        type: DataType2.string,
        value: null,
        error: false,
        score: 0,
      },
    ],
    author: 'reli-ui',
  },
  {
    id: '9ad35434-cf4f-4d9e-91f2-b2217d88a3ca',
    name: 'J20200331944.pdf',
    state: 'ingested',
    type: 'application/pdf',
    hash: '045ed1d927695ee14fd098be5b91aad5',
    fields: [
      { name: 'document_type', value: 'alkymi.capital-statement', error: false, type: DataType2.string },
      {
        name: 'file_date',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: '1582934400',
        error: false,
        score: 0,
      },
      {
        name: 'balance_beginning',
        status: 'correctly_predicted',
        type: DataType2.float,
        value: '1,877,940',
        error: false,
        score: 0,
      },
      {
        name: 'balance_ending',
        status: 'correctly_predicted',
        type: DataType2.float,
        value: '1,813,180',
        error: false,
        score: 0,
      },
      {
        name: 'investment',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'The EM-LV Fund SPC Ltd.',
        error: false,
        score: 0,
      },
      {
        name: 'investor',
        status: 'correctly_predicted',
        type: DataType2.string,
        value: 'Ban Pic  Fum SA',
        error: false,
        score: 0,
      },
      {
        name: 'period_end_date',
        status: 'correctly_predicted',
        type: DataType2.date,
        value: '2020-02-29',
        error: false,
        score: 0,
      },
      {
        name: 'period_start_date',
        status: 'correctly_predicted',
        type: DataType2.date,
        value: '2020-01-31',
        error: false,
        score: 0,
      },
    ],
    author: 'reli-ui',
  },
];

export default DummyFile;
