import Honeybadger from '@honeybadger-io/js';

import { MfaMethodsEnum, MfaProvidersEnum } from '../constants/MfaConstants';

/**
 * Requirement: filter mfa for specific instance
 * It is temporary solution, must be removed in the future.
 */
enum CUSTOM_MFA_HANDLER_SCENARIOS {
  SMS_OFF = 'sms_off',
}

interface MFARequiredFields {
  factorType: MfaMethodsEnum;
  provider: MfaProvidersEnum;
}

export function filterSupportedMFAHandler<T extends MFARequiredFields>(mfaList: T[]): T[] | undefined {
  try {
    const supportedFactorTypes = Object.values(MfaMethodsEnum);

    const knownMFAList = mfaList.filter((mfa) => {
      const isMfaSupported = supportedFactorTypes.includes(mfa.factorType);

      switch (mfa.factorType) {
        case MfaMethodsEnum.token: {
          return isMfaSupported && mfa.provider === MfaProvidersEnum.google;
        }

        default: {
          return isMfaSupported;
        }
      }
    });

    const handlerScenario = process.env.GATSBY_MFA_HANDLER_SCENARIO;

    switch (handlerScenario) {
      case CUSTOM_MFA_HANDLER_SCENARIOS.SMS_OFF: {
        return knownMFAList.filter((mfa) => mfa.factorType !== MfaMethodsEnum.sms);
      }

      default: {
        return knownMFAList;
      }
    }
  } catch (error) {
    console.log(error);

    Honeybadger.notify('Custom MFA Handler Error', {
      name: 'Custom mfa handler error',
      tags: `gateway-ui, custom-mfa-handler-error`,
      context: { error },
    });
  }
}
