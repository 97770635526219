import { Row } from 'react-table';
import { navigate } from 'gatsby';
import Honeybadger from '@honeybadger-io/js';
import { AxiosResponse } from 'axios';

import {
  IJobFormattedData,
  IStructuredPdfField,
  ITableCellsStructuredData,
  ITableCellsStructuredData2,
  JobServerInfo,
} from '../../../constants/Types';
import { sortByDateTime, sortColumns2, moveElement } from './utils';
import {
  DataType2,
  DocumentModel,
  Headers,
  Regex,
  SEPARATOR_FOR_ROWS_COLS,
  TableColumns,
  Messages,
  Errors,
  ReliUrls,
  configEnvironment,
} from '../constants';
import { Types } from '../../../constants';
import { mapDocumentField2, mapJob } from './mappers';
import { Cookie } from '../../../services';
import { CircularDoubleLinkedList } from '../classes/CircularLinkedList';
import { VendorAdapter } from '../classes/VendorAdapter';

const getFieldBgColor = (score: number | null, className: string) => {
  if (className.includes('disabled')) return '#8e8e8e';
  if (score && score > 0 && score < 0.5) {
    return `rgba(254, ${(score * 2 * 254).toFixed()}, 1, 0.37)`;
  } else if (score && score >= 0.5) {
    return `rgba(${((1 - score) * 2 * 254).toFixed()}, 254, 1, 0.37)`;
  }
  return '';
};

const isNumericType = (type: string) => {
  return type === 'integer' || type === 'double' || type === 'float' || type === 'numeric';
};

const convertScientificNotationToNumber = (scientificNotation: string) => {
  const number = Number(scientificNotation);
  return isNaN(number) ? scientificNotation : Number(number).toLocaleString('en-US');
};

const handleParseNumericValue = (value: string) => {
  return value.replace(/,/g, '');
};

const getGenerateTableData = (
  defaultJobs: JobServerInfo[],
  userName: string,
  userLevel: boolean,
): IJobFormattedData[] => {
  return sortByDateTime(defaultJobs.map((job) => mapJob(job, userName, userLevel)));
};

const getJobColumns = (allColumns: string[]): string[] => {
  return sortColumns2(
    Array.from(new Set([...allColumns, TableColumns.SystemColumns.document_type])).filter(
      (col) => !TableColumns.HidedColumns.includes(col),
    ),
  );
};

const getDataFromStringColumnRowId = (selectedKeys: string[], files: ITableCellsStructuredData[]) => {
  const columnId = selectedKeys[0].split(SEPARATOR_FOR_ROWS_COLS)[0];
  const rowIdsArray = selectedKeys.map((key) => key.split(SEPARATOR_FOR_ROWS_COLS)[1]);
  const firstDoc = files.find((doc) => doc.id === rowIdsArray[0]);
  const newValue = firstDoc?.fields.find((f) => f.name === columnId)?.value || '';
  return { columnId, newValue, rowIdsArray };
};

const isDocumentEdited = (document: ITableCellsStructuredData, initialDocument: ITableCellsStructuredData): boolean => {
  if (document.fields && !DocumentModel.States.allSubmitted.includes(document.state)) {
    const res = document.fields.find((f) => {
      const initialValue = initialDocument.fields?.find((field) => field?.id === f?.id)?.value ?? '';
      const currentValue = f?.value ?? '';
      return initialValue !== currentValue;
    });
    return Boolean(res);
  }
  return false;
};

const isNameField = (column: string) => {
  const columnSplitArray = column?.split('_');
  if (!columnSplitArray) return false;
  return columnSplitArray[columnSplitArray.length - 1] === 'Name';
};

const getHeightTablePreviewBlock = (pdf: string, isPreviewVertical: boolean, hasPagination: boolean) => {
  const isPdf = Boolean(pdf && pdf.length > 0);

  const HEIGHT_OF_HEADER = 76;
  const HEIGHT_OF_SEARCHBAR = 100;
  const HEIGHT_OF_BUTTONS = isPdf && isPreviewVertical ? 5 : 60;
  const HEIGHT_OF_PADDING = 9;
  const HEIGHT_OF_PAGINATION = hasPagination ? 50 : 0;
  const totalHeight =
    HEIGHT_OF_HEADER + HEIGHT_OF_SEARCHBAR + HEIGHT_OF_BUTTONS + HEIGHT_OF_PADDING + HEIGHT_OF_PAGINATION;

  if (isPdf && isPreviewVertical) {
    return `calc((100vh - ${totalHeight}px) / 2)`;
  }
  return `calc(100vh - ${totalHeight}px)`;
};

const isReclassifyBtnEnabled = (changedDocumentTypes: { [p: string]: Types.ReliDocumentType }) => {
  return Object.keys(changedDocumentTypes).length > 0;
};

const isSaveBtnDisabled = ({
  rejectedDocs,
  approvedDocs,
  isJobEdited,
  jobHasErrors,
}: {
  rejectedDocs: string[];
  approvedDocs: string[];
  isJobEdited: boolean;
  jobHasErrors: boolean;
}) => {
  return jobHasErrors || (!isJobEdited && rejectedDocs.length === 0 && approvedDocs.length === 0);
};

function getNumberOfNormalizedDocs(rows: Row<ITableCellsStructuredData>[]): number {
  return rows.filter((row) => DocumentModel.States.allAccepted.includes(row.original?.state ?? '')).length;
}

const isAllDocsNormalized = (rows: Row<ITableCellsStructuredData>[]) => {
  const numberOfNormalizedDocs = getNumberOfNormalizedDocs(rows);
  return { numberOfNormalizedDocs, isJobNormalized: numberOfNormalizedDocs === rows.length };
};

const structureDocumentFields = (
  document: ITableCellsStructuredData2,
  keepEmptyValue?: boolean,
): ITableCellsStructuredData => {
  const fieldsArray = dataStructureConverter(document.fields);

  const structuredFields = fieldsArray
    .map((el, idx) => mapDocumentField2(el, idx, keepEmptyValue))
    .filter((x): x is IStructuredPdfField => x !== null);

  const typeField = structuredFields.find((f) => f.name === TableColumns.SystemColumns.document_type);

  if (!typeField) {
    structuredFields.unshift({
      name: TableColumns.SystemColumns.document_type,
      value: 'unknown',
      type: DataType2.string,
      error: false,
      id: 'document-type-unknown',
    });
  }

  return {
    ...document,
    fields: structuredFields,
  };
};

const validateFieldValue = (value: string, type: keyof typeof DataType2 | undefined): boolean => {
  switch (type) {
    case DataType2.date:
      return Boolean(value.match(Regex.DATE_REGEX));
    case DataType2.datetime:
      return Boolean(value.match(Regex.DATE_TIME_REGEX));
    case DataType2.integer:
      return Boolean(value.match(Regex.INTEGER_REGEX));
    case DataType2.double:
      return Boolean(value.match(Regex.FLOAT_REGEX));
    case DataType2.float:
      return Boolean(value.match(Regex.FLOAT_REGEX));
    case DataType2.boolean:
      return Boolean(value.match(Regex.BOOLEAN_REGEX));
    default:
      return true;
  }
};

const getDocumentSavingOptions = (
  document: Types.ITableCellsStructuredData,
  rejectedDocs: string[],
  approvedDocs: string[],
) => {
  const hasDocumentsErrors = document.fields.some((field) => field?.error);
  const isDocumentRejected = Boolean(rejectedDocs.find((docId) => docId === document.id));
  const isDocumentApproved = Boolean(approvedDocs.find((docId) => docId === document.id));
  return {
    isDocumentRejected,
    isDocumentApproved,
    hasDocumentsErrors,
  };
};

const sanitizeDocType = (docType: string): string => {
  const PREFIXES = ['nanonets', 'alkymi'];
  const CURRENT_PREFIX = PREFIXES.filter((el) => String(docType).includes(el))[0];

  if (docType === CURRENT_PREFIX) {
    return '';
  }
  return docType?.replace(`${CURRENT_PREFIX}.`, '');
};

const getIsSubmitted = <T extends { state: string }>(filesArray: T[]): boolean => {
  const numberOfDocs = filesArray.length;
  if (!numberOfDocs) return false;
  const numberOfApprovedRejectedDocs = filesArray.filter((value) =>
    DocumentModel.States.allSubmitted.includes(value.state),
  ).length;
  return numberOfApprovedRejectedDocs === numberOfDocs;
};

const getShallBeAdded = (
  jobId: string,
  exportableJobsArray: {
    name: string;
    id: string;
    isExportable: boolean;
  }[],
) => {
  const idxInExportableArray = exportableJobsArray.findIndex((el) => el.id === jobId);
  return idxInExportableArray === -1;
};

const getFilesBatchCount = (documentsPerPage: number) => {
  if (documentsPerPage <= 10) return 1;
  if (documentsPerPage <= 25) return 3;
  if (documentsPerPage <= 100) return 5;
  if (documentsPerPage > 100) return 10;
};

const getJobStatus = (
  exportableJobsArray: {
    name: string;
    id: string;
    isExportable: boolean;
  }[],
  currentJobId: string,
) => {
  const idx = exportableJobsArray.findIndex((el) => el.id === currentJobId);
  if (idx === -1) {
    return { isSubmitted: false, isExportable: false, idx };
  } else {
    const isExportable = exportableJobsArray[idx].isExportable;
    return { isSubmitted: true, isExportable, idx };
  }
};

const getDebugInfo = (res: AxiosResponse<any> | undefined): string => {
  const baseDebugText = Messages.Reli.DebugMessageBaseText;
  const { headers, status } = res || {};
  const uniqueSessionId = Cookie.getUniqueSessionId() ?? '';
  const headersArray = headers ? Object.entries(headers) : [];
  const debugHeadersArray = headersArray.filter(([key, value]: [string, any]) => {
    const isDebugHeader = Boolean(Object.values(Headers.DebugHeaders).find((header) => header === key));
    return isDebugHeader && value && value !== 'undefined';
  });
  if (Array.isArray(debugHeadersArray)) {
    debugHeadersArray.push([Headers.DebugHeaders.UniqueSessionId, uniqueSessionId]);
  }
  const debugHeadersText = debugHeadersArray.map(([key, value]: [string, any]) => `${key} = ${value};\n`).join('');
  return `${baseDebugText}${debugHeadersText}`;
};

const getAndLogAxiosError = (res: AxiosResponse<any> | undefined, baseErrorText: string): string => {
  const { data, headers, status } = res || {};
  let errorText = baseErrorText;

  const isNotAuthorized = status === 401 || status === 403;
  if (isNotAuthorized) {
    errorText = Errors.NOT_AUTHORIZED;
  } else if (headers && data) {
    const responseErrorMessage = data?.error ? data?.error.concat('.') : '';
    errorText = `${baseErrorText}. ${responseErrorMessage} ${getDebugInfo(res)}`;
  }
  if (Honeybadger.config.environment === configEnvironment.production) {
    Honeybadger.notify('[UI] Reli Request Error', {
      name: 'Reli get controller error',
      tags: `gateway-ui, reli-ui`,
      context: {
        url: res?.config?.url,
        error: errorText,
        [Headers.DebugHeaders.UniqueSessionId]: Cookie.getUniqueSessionId(),
        ...headers,
        status,
      },
    });
  }
  console.error(errorText);
  return errorText;
};

const validateAndAddNewField = (
  type: keyof typeof DataType2,
  value: string,
  column: string,
  subrowId?: string,
): Types.IStructuredPdfField => {
  const isNumeric = isNumericType(type);
  const isDate = type === DataType2.date;
  const isBoolean = type === DataType2.boolean;
  let updatedValue: string;
  let isFormatValid = true;
  if (isNumeric) {
    isFormatValid = !isNaN(Number(value));
    updatedValue = isFormatValid ? Number(value).toLocaleString() : '#UNPARSABLE';
  } else if (isDate) {
    isFormatValid = validateFieldValue(value, type);
    updatedValue = isFormatValid ? String(value) : '#UNPARSABLE';
  } else if (isBoolean) {
    isFormatValid = Boolean(value.match(Regex.BOOLEAN_REGEX));
    updatedValue = isFormatValid ? String(value) : '#UNPARSABLE';
  } else {
    updatedValue = value;
  }
  const newField: Types.IStructuredPdfField = subrowId
    ? {
        type,
        name: column,
        value: updatedValue,
        error: !isFormatValid,
        score: isFormatValid ? 1 : 0,
        id: subrowId,
        status: 'subrow',
      }
    : {
        type,
        name: column,
        value: updatedValue,
        error: !isFormatValid,
        score: isFormatValid ? 1 : 0,
      };

  return newField;
};

const navigateToMainPage = (): void => {
  navigate(ReliUrls.Main);
};

const getColumnsWithAdded = (columnsArr: string[], addedColumns: string[]): string[] => {
  return sortColumns2(Array.from(new Set([...columnsArr, ...addedColumns])));
};

const generateStylesForReordering = (columnIsDragging: boolean, columnId: string, formulaFields: string[]) => {
  const reorderingStyle = {
    flex: columnIsDragging ? 'none' : '200 0 auto',
    ...(formulaFields.includes(columnId) && { background: '#8e8e8e' }),
    ...(columnIsDragging && {
      width: '200px',
    }),
  };
  try {
    const columnsWidth = JSON.parse(localStorage.getItem('columnsWidth') as string);
    if (columnsWidth && columnsWidth[columnId]) {
      return {
        flex: 'initial',
        flexShrink: 0,
      };
    }
    if (TableColumns.defaultColumnsWidth[columnId as keyof typeof TableColumns.defaultColumnsWidth]) {
      return {
        flex: `${TableColumns.defaultColumnsWidth[columnId as keyof typeof TableColumns.defaultColumnsWidth]} 0 auto`,
        ...(columnIsDragging && {
          width: `${TableColumns.defaultColumnsWidth[columnId as keyof typeof TableColumns.defaultColumnsWidth]}px`,
        }),
      };
    }
    return reorderingStyle;
  } catch (e) {
    return reorderingStyle;
  }
};

const getRELISettings = () => {
  try {
    const RELISettings = localStorage.getItem('RELISettings');
    if (RELISettings) {
      return JSON.parse(RELISettings);
    }
    return undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const updateColumnWidth = (columnName: string, width: number) => {
  try {
    const locallyStoredColumnsWidth = localStorage.getItem('columnsWidth')
      ? JSON.parse(localStorage.getItem('columnsWidth') as string)
      : {};
    locallyStoredColumnsWidth[columnName] = width;
    localStorage.setItem('columnsWidth', JSON.stringify(locallyStoredColumnsWidth));
  } catch (e) {
    console.error(e);
  }
};

const getColumnWidth = (columnName: string) => {
  try {
    const columnsWidth = JSON.parse(localStorage.getItem('columnsWidth') as string);
    if (columnsWidth && columnsWidth[columnName]) {
      return columnsWidth[columnName];
    }
    if (TableColumns.defaultColumnsWidth[columnName as keyof typeof TableColumns.defaultColumnsWidth]) {
      return TableColumns.defaultColumnsWidth[columnName as keyof typeof TableColumns.defaultColumnsWidth];
    }
    return 200;
  } catch (e) {
    console.error(e);
    return 200;
  }
};

const customScrollIntoView = (columns: string[], targetColumn: string) => {
  const indexX = columns.findIndex((column) => column === targetColumn);
  let distanceFromLeft = 0;
  for (let i = 3; i < indexX; i++) {
    const columnName = columns[i];
    distanceFromLeft += getColumnWidth(columnName);
  }
  return distanceFromLeft;
};

const generateCircularLinkedList = (
  rows: {
    id: string;
    state: string;
  }[],
  cols: string[],
) => {
  const fieldsList = new CircularDoubleLinkedList();
  let i = 0;
  let j = 0;
  const normalizedRows = rows.filter((row: { id: string; state: string }) => row.state === 'normalized');

  while (i < normalizedRows.length) {
    while (j < cols.length) {
      const newNode = {
        ...normalizedRows[i],
        column: cols[j],
      };
      fieldsList.push(newNode);
      if (i > 0) {
        fieldsList.addNodeBelow((i - 1) * cols.length + j);
      }

      j++;
    }
    j = 0;
    i++;
  }
  return fieldsList;
};

const highlightFields = (
  initialFiles: Types.ITableCellsStructuredData,
  canvasPageRef: any,
  zoomLevel: { id: number; name: number },
  pageNumber: number,
  pageDivRef: any,
  highlightedField?: string | undefined,
  vendor?: VendorAdapter,
  newWindowDocument?: Document,
) => {
  if (!vendor) {
    return;
  }
  const highlightedAreas = [];

  for (const initField of initialFiles.fields) {
    if (initField.coordinates?.allCoordinatesArePresent) {
      const fieldCoordinates = vendor?.getCoordinates(initField.coordinates) as {
        height: number;
        left: number;
        page: number;
        top: number;
        width: number;
      };
      highlightedAreas.push({ ...fieldCoordinates, fieldName: initField?.name });
    }
  }

  const sortedAreas = highlightedAreas.sort((a, b) => a.page - b.page).filter((el) => el.page === pageNumber);

  const docPage = pageDivRef;
  const customDocument = newWindowDocument ? newWindowDocument : document;

  if (sortedAreas.length > 0) {
    for (const sortdArea of sortedAreas) {
      const highlightedArea = sortdArea;
      if (canvasPageRef) {
        const scale = zoomLevel.id / 100;

        const tooltipElement = customDocument.getElementById(`tooltip-${highlightedArea.fieldName}`);
        if (tooltipElement) {
          tooltipElement.remove();
        }
        const toolTipDiv = customDocument.createElement('div');
        toolTipDiv.style.position = 'absolute';
        toolTipDiv.style.zIndex = '8';
        toolTipDiv.style.display = 'block';
        toolTipDiv.style.top = `${scale * highlightedArea.top}px`;
        toolTipDiv.style.left = `${scale * highlightedArea.left}px`;
        toolTipDiv.style.width = `${scale * highlightedArea.width}px`;
        toolTipDiv.style.height = `${scale * highlightedArea.height}px`;
        toolTipDiv.style.cursor = 'pointer';
        toolTipDiv.style.border = '1px solid red';
        toolTipDiv.id = `tooltip-${highlightedArea.fieldName}`;

        if (highlightedArea.fieldName === highlightedField) {
          toolTipDiv.style.border = '1px solid rgba(144,238,144,0.5)';
          toolTipDiv.style.background = 'rgba(144,238,144,0.5)';
        }

        const toolTipLabelContainer = customDocument.createElement('div');
        toolTipLabelContainer.style.position = 'absolute';
        toolTipLabelContainer.style.display = 'none';
        toolTipLabelContainer.style.top = `-35px`;
        toolTipLabelContainer.style.left = `0px`;
        toolTipLabelContainer.style.padding = '3px';
        toolTipLabelContainer.style.zIndex = '9';
        toolTipLabelContainer.style.background = '#000';
        toolTipLabelContainer.style.color = '#fff';
        toolTipLabelContainer.style.textAlign = 'center';

        const toolTipLabel = customDocument.createElement('p');
        toolTipLabel.style.margin = '0';
        toolTipLabel.innerText = highlightedArea.fieldName;
        toolTipLabelContainer.append(toolTipLabel);

        toolTipDiv.append(toolTipLabelContainer);
        toolTipDiv.onmouseenter = () => {
          toolTipLabelContainer.style.display = 'flex';
        };
        toolTipDiv.onmouseleave = () => {
          toolTipLabelContainer.style.display = 'none';
        };

        docPage.append(toolTipDiv);
      }
    }
  }
};

const getUniqueColumnsFromFiles = (files: ITableCellsStructuredData[], midasFields: Types.IMidasFields2[]) => {
  const correctTypeColumns = midasFields.map((field) => field.id);
  const setOfColumns = Array.from(
    new Set((files.map((el) => el.fields?.map((elem) => elem!.name)) || []).flat()),
  ).filter((field) => correctTypeColumns.includes(field));
  return setOfColumns;
};

const updateColumnsOrder = (draggableId: string, destinationIndex: number, columnsOrder: string[]) => {
  try {
    const locallyStoredColumnsOrder = localStorage.getItem('columnsOrder');
    if (locallyStoredColumnsOrder) {
      let parsedLocallyStoredColumnsOrder: string[] = JSON.parse(locallyStoredColumnsOrder);
      parsedLocallyStoredColumnsOrder = moveElement(
        parsedLocallyStoredColumnsOrder,
        draggableId,
        columnsOrder[destinationIndex - 1],
      );
      localStorage.setItem('columnsOrder', JSON.stringify(parsedLocallyStoredColumnsOrder));
    }
  } catch (e) {
    console.error(e);
  }
};

const numericSort = (rowA: Row<{}>, rowB: Row<{}>, columnId: string, desc: boolean) => {
  let a = Number.parseFloat(rowA.values[columnId]);
  let b = Number.parseFloat(rowB.values[columnId]);
  if (Number.isNaN(a)) {
    // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const getMidasTypesForRequest = () => {
  try {
    let midasTypes = 'datum';
    const RELISettings = getRELISettings();
    if (RELISettings && RELISettings.midasTypesForRequest) {
      const parsedDefaultMidasType: string[] = RELISettings.midasTypesForRequest;
      parsedDefaultMidasType.map((type) => (midasTypes += `,${type}`));
    }
    return midasTypes;
  } catch (e) {
    console.error(e);
  }
};

const dataStructureConverter = (document: {
  [key: string]: {
    [key: string]: {
      midas_type: string;
      properties: Types.IPdfField2Property;
      value: string;
    };
  };
}) => {
  const newFields: Types.IPdfField2[] = [];

  for (const property of Object.keys(document)) {
    const newFieldKeys = Object.keys(document[property]);
    if (property !== 'document_type') {
      for (const fieldGuid of newFieldKeys) {
        let newField: any = {};
        newField.field_id = property;
        newField.guid = fieldGuid;
        newField = { ...newField, ...document[property][fieldGuid] };
        newField.value = String(newField.value);
        newFields.push(newField);
      }
    }
    if (property === 'document_type') {
      let newDocTypeField: any = {};
      newDocTypeField.field_id = property;
      newDocTypeField = { ...newDocTypeField, ...document[property] };
      newFields.push(newDocTypeField);
    }
  }

  return newFields;
};

const getRemovedFields = (initialFiles: Types.ITableCellsStructuredData[], doc: Types.ITableCellsStructuredData) => {
  const initialDoc = initialFiles.find((initialRow) => initialRow.id === doc.id);
  if (initialDoc) {
    const removedFields = initialDoc.fields
      .map((el) => (doc.fields.find((f) => f.id === el.id) ? null : el.guid))
      .filter((fld) => fld);
    return removedFields.length > 0;
  }
  return false;
};

export {
  isNumericType,
  getDocumentSavingOptions,
  convertScientificNotationToNumber,
  getFieldBgColor,
  handleParseNumericValue,
  getGenerateTableData,
  getJobColumns,
  getDataFromStringColumnRowId,
  isDocumentEdited,
  isNameField,
  getHeightTablePreviewBlock,
  isReclassifyBtnEnabled,
  isSaveBtnDisabled,
  isAllDocsNormalized,
  getNumberOfNormalizedDocs,
  structureDocumentFields,
  validateFieldValue,
  sanitizeDocType,
  getIsSubmitted,
  getJobStatus,
  getFilesBatchCount,
  getDebugInfo,
  getAndLogAxiosError,
  navigateToMainPage,
  getColumnsWithAdded,
  getShallBeAdded,
  validateAndAddNewField,
  generateStylesForReordering,
  getRELISettings,
  updateColumnWidth,
  getColumnWidth,
  customScrollIntoView,
  generateCircularLinkedList,
  highlightFields,
  getUniqueColumnsFromFiles,
  updateColumnsOrder,
  numericSort,
  getMidasTypesForRequest,
  dataStructureConverter,
  getRemovedFields,
};
