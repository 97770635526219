import { styled } from '@mui/material/styles';
import { Popper } from '@mui/material';

import { Types } from '../../../constants';

const baseGreyColor = '#777';
const baseFont = 'Proxima Nova, sans-serif';

export const StyledFeedback = styled('div')`
  font-family: ${baseFont};
  font-size: 15px;

  .feedback-btn {
    text-transform: none;
    position: relative;
    background-color: #f5f7f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    color: ${baseGreyColor};
    font-size: 15px;
    margin: 0 15px 0 0;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: normal;
    font-family: ${baseFont};

    :disabled {
      opacity: 0.7;
    }

    .icon {
      color: ${baseGreyColor};
      margin: 0 5px 0 0;
      width: 18px;
      height: 18px;
    }
  }
`;

export const StyledPopper = styled(Popper)<Types.IBaseColors>`
  font-family: ${baseFont};
  position: relative;
  z-index: 10000;

  .popper-main {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;

    textarea {
      font-family: ${baseFont};
      font-size: 15px;
      border: none;
      margin: 0 0 10px 0;
      min-height: 120px;
      min-width: 260px;
      max-height: 70vh;
      resize: vertical;

      :focus-visible,
      :hover,
      :active {
        outline: none;
      }
    }

    .btns-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
`;
