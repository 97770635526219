import { styled } from '@mui/material/styles';
import { Modal } from '@mui/material';

import { Types } from '../../../constants';

export const StyledConnectAdvisorModal = styled(Modal)<Types.IBaseColors>`
  display: flex;
  align-items: center;
  justify-content: center;
  .root {
    min-width: 590px;
    max-height: calc(100vh - 20px);
    position: relative;
    background-color: white;
    padding: 10px;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title {
        color: #0a354c;
        font-size: 22px;
        text-align: center;
        font-family: Neuzeit-Grotesk, sans-serif;
        font-weight: bold;
        justify-content: center;
        margin: 0 0 15px 0;
        width: 100%;
      }
      .advisor-item {
        display: flex;
        align-items: center;
        border: 1px solid rgb(112, 112, 112);
        border-radius: 5px;
        margin: 0 0 10px 0;
        padding: 7px;
        width: 100%;
        cursor: pointer;
        p {
          color: rgb(73, 62, 62);
        }
        .advisor-name {
          font-size: 16px;
        }
        .advisor-title {
          font-size: 14px;
          opacity: 0.5;
        }
        :hover {
          border: 1px solid ${(props) => props.colors.secondary};
          p {
            color: ${(props) => props.colors.secondary};
          }
        }
      }
      .chosen-advisor {
        border: 1px solid ${(props) => props.colors.secondary};
        p {
          color: ${(props) => props.colors.secondary};
        }
      }
      .main-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        .photo-container {
          height: 50px;
          width: 50px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 50%;
          margin: 0 15px;
        }
      }
      .close {
        right: 3px;
        top: 3px;
        position: absolute;
        padding: 10px;
        .close-icon {
          width: 20px;
          height: 20px;
        }
      }
      .next-button {
        background-color: ${(props) => props.colors.secondary};
        min-width: 120px;
        color: white;
      }
    }
  }
`;
