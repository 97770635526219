export class States {
  static readonly Uploaded = 'uploaded';
  static readonly Misrouted = 'misrouted';
  static readonly Routing = 'routing';
  static readonly Routed = 'routed';
  static readonly Scanning = 'scanning';
  static readonly Scanned = 'scanned';
  static readonly Normalizing = 'normalizing';
  static readonly Normalized = 'normalized';
  static readonly Approved = 'approved';
  static readonly Edited = 'edited';
  static readonly Rejected = 'rejected';
  static readonly Ingesting = 'ingesting';
  static readonly Ingested = 'ingested';
  static readonly Failed = 'failed';
  static readonly Cancelled = 'cancelled';

  static get allNotNormalized() {
    return [this.Uploaded, this.Misrouted, this.Routing, this.Routed, this.Scanning, this.Scanned, this.Normalizing];
  }

  static get allApproved() {
    return [this.Approved, this.Edited, this.Ingesting, this.Ingested];
  }

  static get allBroken() {
    return [this.Failed, this.Cancelled, this.Rejected];
  }

  static get allAllowedToUi() {
    return [
      this.Routing,
      this.Misrouted,
      this.Normalized,
      this.Approved,
      this.Rejected,
      this.Ingested,
      this.Failed,
      this.Cancelled,
    ];
  }

  static get allFailed() {
    return [this.Failed, this.Cancelled];
  }

  static get allSubmitted() {
    return [...this.allApproved, this.Failed, this.Rejected];
  }

  static get allAccepted() {
    return [...this.allSubmitted, this.Normalized];
  }
}
