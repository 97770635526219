import React, { useState } from 'react';
import { Tooltip, SxProps, Theme } from '@mui/material';

import { ContentCopy } from '../../icons';

const copyMessageButtonStyles: Record<string, SxProps<Theme> | undefined> = {
  copyButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
};

interface CopyMessageButtonProps {
  message: string;
}

export const CopyMessageButton = ({ message }: CopyMessageButtonProps) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <Tooltip arrow={true} title={copied ? 'Copied to clipboard' : 'Copy'}>
      <ContentCopy
        sx={copyMessageButtonStyles.copyButton}
        color={copied ? 'disabled' : 'inherit'}
        onClick={copyToClipboard}
      />
    </Tooltip>
  );
};
