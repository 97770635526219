import React, { useContext } from 'react';
import ReactDOM from 'react-dom';

import Config from '../../config';
import { UserContext } from '../../context/UserContext';
import { ModuleKeys } from '../../constants';

export const CognicorPopup = () => {
  const modalRoot = document.querySelector('body') as HTMLElement;
  const userContext = useContext(UserContext);

  const getIsCognicorAvailable = () => {
    const isRolePermissionsCheckboxActive = (userContext.role?.permissions || []).indexOf(ModuleKeys.cognicor) !== -1;
    const isNetlifyEnvIdAvailable = Config.GATSBY_COGNICOR_PROJECT_ID;
    const isNotReliPages = !window.location.href.includes('reli');

    return isRolePermissionsCheckboxActive && isNetlifyEnvIdAvailable && isNotReliPages;
  };

  return getIsCognicorAvailable()
    ? ReactDOM.createPortal(
        <cira-va project-id={Config.GATSBY_COGNICOR_PROJECT_ID} va-ui-settings="mirador" sso-login-type="iframe" />,
        modalRoot,
      )
    : null;
};
