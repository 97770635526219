import { navigate } from 'gatsby';
import { ModuleKeys, RoutesToNavigate, Types } from '../constants';

export const navigateToRoute = (role: Partial<Types.RoleType>, replace = false) => {
  const permission = role?.permissions || [];
  const isLandingRouteEnable = permission.indexOf(ModuleKeys.landingRoute) !== -1;
  const landingPageRoute = role?.landing_route;
  const localStorageSubFolder = localStorage.getItem('sharefile-subfolder');

  if (localStorageSubFolder) {
    localStorage.removeItem('sharefile-subfolder');
    navigate(`/documents/${localStorageSubFolder}`, {
      replace,
    });
  } else if (isLandingRouteEnable && landingPageRoute) {
    navigate(landingPageRoute, {
      replace,
    });
  } else {
    navigate(RoutesToNavigate.DASHBOARD, {
      replace,
    });
  }
};
