import axios from 'axios';

import { Cookie } from '..';
import { IUsersResponse, IUserData, ITeams } from '../../constants/UsersAdministrationConstants';
import { getFailedApiCallResponse } from '../utils';

export const getUsers = async (
  offset: number,
  limit: number,
  slug?: string,
): Promise<{
  success: boolean;
  data?: IUsersResponse;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get(
      `/gateway/users?offset=${slug ? 0 : offset}&limit=${limit}${
        slug ? '&email_address=' + slug + '&name=' + slug + '&meta_status=' + slug : ''
      }`,
      {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      },
    );
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getUserById = async (
  id: string,
): Promise<{
  success: boolean;
  data?: IUserData;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get(`/gateway/users/user/${id}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getTeams = async (): Promise<{
  success: boolean;
  data?: ITeams[];
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get('/gateway/teams', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateUserById = async (
  payload: IUserData,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.put(`/gateway/users/user/${payload.id}`, payload, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
