import { styled } from '@mui/material/styles';
import { ListItemButton, SxProps, Theme } from '@mui/material';

export const useSidebarStyles = (expandMenu: boolean, drawerWidth: number) => {
  const sidebarStyles: Record<string, SxProps<Theme> | undefined> = {
    root: (theme) => ({
      display: 'flex',
      width: '100%',
      height: '100%',
      '.content': {
        flexGrow: 1,
        marginTop: '4.75rem',
        display: 'flex',
        backgroundColor: 'white',
        flexDirection: 'column',
        overflow: 'auto',
      },
      '.headerRight': {
        display: 'flex',
        alignItems: 'center',
      },
      '.defaultLogo': {
        aspectRatio: 'initial',
        maxWidth: '170px',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '140px',
        },
      },
      '.headerDropdownIconContainer': {
        width: '35px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '.drawer': {
        [theme.breakpoints.up('md')]: {
          width: expandMenu ? drawerWidth : '3rem',
          flexShrink: 0,
        },
      },
      '.drawerArrowExpanded': {
        height: '25px',
        width: '25px',
        background: '#fff',
        position: 'fixed',
        top: '6rem',
        left: expandMenu ? '15.5rem' : '2.5rem',
        borderRadius: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid #000`,
      },
      '.logo': {
        cursor: 'pointer',
      },
    }),
    menuItemsList: (theme) => ({
      marginTop: '20px',
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
      overflowY: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }),
  };
  return sidebarStyles;
};

export const useDrawerStyles = (persistent: boolean, expandMenu: boolean, drawerWidth: number) => {
  const drawerStyles: Record<string, SxProps<Theme> | undefined> = {
    '& .MuiDrawer-paper': (theme) => ({
      width: !persistent || (persistent && expandMenu) ? drawerWidth : '48px',
      marginTop: persistent ? '4.75rem' : 0,
      background: theme.palette.primary.main,

      '.bottomPush': {
        flex: 1,
        alignItems: 'flex-end',
        display: 'flex',
        marginBottom: '7rem',
      },
      '.smBottomPush': {
        flex: 1,
        alignItems: 'flex-end',
        display: 'flex',
        marginBottom: '1rem',
        marginTop: '1rem',
      },
      '.listMainItem': {
        display: 'flex',
        alignItems: 'center',
      },
      '.menuItem': {
        color: '#fff',
        height: 35,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Proxima Nova, sans-serif',
        fontSize: '14px',
      },
      '.menuItemTitle': {
        width: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 0,
      },
      '.listIcon': {
        width: '45px',
        display: 'flex',
        justifyContent: 'center',
        color: '#fff',
        marginLeft: '-3px',
      },
      '.nested': {
        paddingLeft: expandMenu ? theme.spacing(6) : '',
        marginLeft: expandMenu ? 0 : '-0.2rem',
      },
    }),
  };
  return drawerStyles;
};

export const StyledListItem = styled(ListItemButton)`
  border-left-color: transparent !important;
  border-left-width: 3px !important;
  border-left-style: solid !important;
  padding-right: 0;
  :hover {
    background-color: rgba(0, 0, 0, 0.14) !important;
    border-left-color: white !important;
    font-weight: bold !important;
    .listIcon {
      opacity: 1;
    }
  }
  &.active {
    border-left-color: white !important;
    .listIcon {
      opacity: 1;
    }
  }
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  margin-top: 3px !important;
  .listIcon {
    opacity: 0.5;
  }
  .selectedMenuItem {
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    font-size: 14px;
    font-weight: bold;
    height: 35px;
    align-items: center;
    display: flex;
    font-family:
      Proxima Nova,
      sans-serif;
  }
`;

export const StyledListSubItem = styled(ListItemButton)`
  border-left-color: transparent !important;
  border-left-width: 3px !important;
  border-left-style: solid !important;
  :hover {
    background-color: transparent !important;
    font-weight: bold !important;
  }
`;

export const LogOutItem = styled(ListItemButton)`
  margin-left: 3px;
  :hover {
    background-color: rgba(0, 0, 0, 0.14) !important;
    font-weight: bold !important;
    .listIcon {
      opacity: 1;
    }
  }
  &.active {
    border-left-color: white !important;
    .listIcon {
      opacity: 1;
    }
  }
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  margin-top: 3px !important;
  .listIcon {
    opacity: 0.5;
  }
`;
