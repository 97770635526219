import { createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  isDocApprovedRejected: boolean;
  isDetailsTableEdited: boolean;
  isLoading: boolean;
}

const initialState: IInitialState = {
  isDocApprovedRejected: false,
  isDetailsTableEdited: false,
  isLoading: false,
};

export const relipdfviewerSlice = createSlice({
  name: 'reli-pdf-table',
  initialState,
  reducers: {
    setIsApprovedRejected(state, action) {
      state.isDocApprovedRejected = action.payload;
    },
    setIsTableEdited(state, action) {
      state.isDetailsTableEdited = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const relipdfTableActions = relipdfviewerSlice.actions;

export default relipdfviewerSlice.reducer;
