import axios from 'axios';
import _ from 'lodash';

import { Cookie } from '..';
import Config from '../../config';
import { StatusConstants } from '../../constants';
import { getFailedApiCallResponse } from '../utils';

const { GATEWAY_ID } = Config;

export const activateUser = async (email: string) => {
  try {
    const res = await axios.post(`/auth/activate`, { email }, { headers: { 'gateway-id': GATEWAY_ID } });
    if (_.get(res, 'data.data.stateToken')) {
      Cookie.setStateToken(_.get(res, 'data.data.stateToken', ''));
      Cookie.setStatus(_.get(res, 'data.data.status', ''));

      return { success: true };
    }
  } catch (e) {
    console.info('e activateUser', e);
  }
  return { success: false };
};

/**
 * [This activate the user given an activation token, provided by okta]
 *
 * @param   {string}  token  [Activation Token given by okta]
 *
 * @return  {[Object]}         [Return an object with a boolean success key]
 */
export const activateUserWithActivationToken = async (
  token: string,
): Promise<{
  success: boolean;
  data?: {
    isMFANeeded: boolean;
    isPwdNeeded: boolean;
    email: string;
  };
  error?: string;
}> => {
  try {
    const res = await axios.post('/auth/user/activate', { token }, { headers: { 'gateway-id': GATEWAY_ID } });

    if (res.status === 200) {
      const email = _.get(res, 'data.data.email', '');
      const stateToken = _.get(res, 'data.data.stateToken');
      const status = _.get(res, 'data.data.status', '');
      const recoveryType = _.get(res, 'data.data.recoveryType', '');

      const isMFANeeded = stateToken && status === 'MFA_ENROLL';
      const isPwdNeeded = stateToken && status === 'PASSWORD_RESET' && recoveryType === 'ACCOUNT_ACTIVATION';

      if (stateToken) {
        Cookie.setStatus(status);
        Cookie.setStateToken(stateToken);
      }
      return { success: true, data: { isMFANeeded, isPwdNeeded, email } };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export interface IFactors {
  id: string;
  factorType: string;
  provider: string;
  vendorName: string;
  profile: Profile;
  _links: Links;
}

export interface Links {
  verify: Verify;
}

export interface Verify {
  href: string;
  hints: Hints;
}

export interface Hints {
  allow: string[];
}

export interface Profile {
  phoneNumber: string;
}

export const setPassword = async (password: string) => {
  let success = false;
  let isMFANeeded = false;
  let isMFAVerifyNeeded = false;

  try {
    const token = Cookie.getStateToken();
    const data = { password, token };

    const res = await axios.post('/auth/reset-password', data, { headers: { 'gateway-id': GATEWAY_ID } });
    success = true;

    const stateToken = _.get(res, 'data.data.stateToken');
    const status = _.get(res, 'data.data.status', '');
    isMFANeeded = stateToken && status === 'MFA_ENROLL';
    isMFAVerifyNeeded = stateToken && status === 'MFA_REQUIRED';

    if (stateToken && (isMFANeeded || isMFAVerifyNeeded)) {
      Cookie.setStatus(status);
      Cookie.setStateToken(stateToken);

      if (isMFAVerifyNeeded) {
        const factors: IFactors[] = _.get(res, 'data.data._embedded.factors', []);
        const smsMFA = factors.find((mfa: IFactors) => mfa?.factorType === 'sms');
        Cookie.setFactorId(smsMFA?.id || '');
      }
    }
  } catch (e) {
    console.info('e setPassword', e);
  }

  return { success, isMFANeeded, isMFAVerifyNeeded };
};

export const setMFA = async (
  sms: string,
): Promise<{
  success: boolean;
  message?: string;
  error?: string;
}> => {
  try {
    const token = Cookie.getStateToken();
    const data = { sms, token };
    const res = await axios.post('/auth/activate/mfa', data, { headers: { 'gateway-id': GATEWAY_ID } });
    if (_.get(res, 'data.data.stateToken')) {
      Cookie.setStateToken(_.get(res, 'data.data.stateToken', ''));
      Cookie.setStatus(_.get(res, 'data.data.status', ''));
      Cookie.setFactorId(_.get(res, 'data.data._embedded.factor.id', ''));

      return { success: true, message: _.get(res, 'data.data._embedded.factor.profile.phoneNumber', '') };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const verifyMFA = async (
  code: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const token = Cookie.getStateToken();
    const factorId = Cookie.getFactorId();
    const data = { factorId, token, code };
    const res = await axios.post('/auth/activate/mfa/verify', data, { headers: { 'gateway-id': GATEWAY_ID } });

    if (res.status === 200) {
      if (_.get(res, 'data.data.sessionToken')) {
        Cookie.setStatus(_.get(res, 'data.data.status', ''));
        Cookie.setSessionToken(_.get(res, 'data.data.sessionToken', ''));
      }

      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const resendMFA = async (): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const token = Cookie.getStateToken();
    const factorId = Cookie.getFactorId();
    const data = { factorId, token };
    const res = await axios.post('/auth/activate/mfa/verify', data, { headers: { 'gateway-id': GATEWAY_ID } });
    if (_.get(res, 'data.data.stateToken')) {
      Cookie.setStateToken(_.get(res, 'data.data.stateToken', ''));
      Cookie.setStatus(_.get(res, 'data.data.status', ''));
      Cookie.setFactorId(_.get(res, 'data.data._embedded.factor.id', ''));

      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getFavIconUrl = async (): Promise<{
  success: boolean;
  data?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/utils/media/${GATEWAY_ID}?original_name=favicon`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (_.get(res, 'data.success')) {
      return {
        success: true,
        data: res.data.data.media.url || '',
      };
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const errorResponse = e?.response?.data?.error;
      const errorMessage = errorResponse ? errorResponse : e;
      console.info('e getFavIconUrl', errorMessage);

      return {
        success: false,
        error: String(errorMessage),
      };
    }
  }
  return { success: false };
};

export const getDownForMaintenanceState = async (): Promise<{
  success: boolean;
  technicalWorks?: boolean;
  oktaLink?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/utils/server-sync/${GATEWAY_ID}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (_.get(res, 'data.success')) {
      return {
        success: true,
        technicalWorks: res.data.data.technicalWorks,
        oktaLink: res.data.data.oktaLink || '',
      };
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const errorResponse = e?.response?.data?.data?.favicon;
      const errorMessage = errorResponse ? errorResponse : e;
      console.info('e getDownForMaintenanceState', errorMessage);

      return {
        success: false,
        technicalWorks: e?.response?.data?.data?.technicalWorks,
        oktaLink: e?.response?.data?.data?.oktaLink || '',
        error: String(errorMessage),
      };
    }
  }
  return { success: false };
};

export const getLogoAndBgColor = async (): Promise<{
  success: boolean;
  bgColor?: string;
  logoUrl?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/utils/server-design/${GATEWAY_ID}?logo_original_name=logo`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (_.get(res, 'data.success')) {
      return {
        success: true,
        bgColor: res.data.data.gateway_primary_color || '',
        logoUrl: res.data.data.logo.url || '',
      };
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const errorResponse = e?.response?.data?.error;
      const errorMessage = errorResponse ? errorResponse : e;
      return { success: false, error: String(errorMessage) };
    }
  }
  return { success: false };
};

export const getAuthData = async (): Promise<{
  success: boolean;
  bgColor?: string;
  logoUrl?: string;
  error?: string;
  client_login_types?: string[];
  employee_login_types?: string[];
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/utils/server-design/${GATEWAY_ID}?logo_original_name=logo`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        bgColor: res.data.data.gateway_primary_color || '',
        logoUrl: res.data.data.logo?.url || '',
        client_login_types: res.data.data.client_login_types || [],
        employee_login_types: res.data.data.employee_login_types || [],
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
