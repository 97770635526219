import _ from 'lodash';
import axios from 'axios';

import { Cookie } from '..';
import { IIntegrationData, INewIntegrationPayload } from '../../constants/ThirdPartyIntegrationsConstants';
import { getFailedApiCallResponse } from '../utils';

export const getThirdPartyIntegrations = async (): Promise<{
  success: boolean;
  data?: IIntegrationData[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/utils/third_party_integrations', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data?.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getThirdPartyIntegrationsServiceTypes = async (): Promise<{
  success: boolean;
  data?: string[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/utils/third_party_integrations/service_types', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: Object.keys(res.data.data),
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteIntegration = async (
  id: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.delete(`/gateway/utils/third_party_integrations/${id}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 204) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const saveIntegration = async (
  data: INewIntegrationPayload,
  id: string | null,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = id
      ? await axios.put(`/gateway/utils/third_party_integrations/${id}`, data, {
          headers: { 'X-ACCESS-TOKEN': accessToken },
        })
      : await axios.post('/gateway/utils/third_party_integrations', data, {
          headers: { 'X-ACCESS-TOKEN': accessToken },
        });

    if (res.status === 200) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
