import { IFolder, IMidasFields2, ISubFolder, ITableCellsStructuredData, IVariable } from '../../../constants/Types';
import { sortByName } from './utils';
import { DataType2, Errors, DocumentModel, MidasFieldCalcType } from '../constants';
import { Types } from '../../../constants';
import { postDocsToShareFile } from '../services';

const getSubTreeFromTreeByID = (tree: IFolder, id: string): IFolder | false | undefined => {
  let temp;
  return tree.Id === id ? tree : (tree.children || []).some((o: any) => (temp = getSubTreeFromTreeByID(o, id))) && temp;
};

const convertFileOrFolderNameToExpression = (namePattern: ISubFolder): string => {
  return namePattern.value;
};

const addFileNameExtension = (nameExpression: string) => {
  if (nameExpression.match(/\$FILE_NAME$/) !== null || nameExpression.match(/\.pdf$/) !== null) {
    return nameExpression;
  } else {
    return nameExpression.trim() + ' & ".pdf"';
  }
};

const wrapExpressionsToPath = (expressions: string[]): string => {
  return `path(${expressions.join(' , ')})`;
};
const getShareFilePathForSaving = (
  saveInCurrentFolder: boolean,
  filesNamePattern: ISubFolder,
  subFolders: ISubFolder[],
): string => {
  const fileNameWithOutExtension = convertFileOrFolderNameToExpression(filesNamePattern);
  const fileNameExpression = addFileNameExtension(fileNameWithOutExtension);

  if (saveInCurrentFolder) {
    return wrapExpressionsToPath([fileNameExpression]);
  }

  const subFoldersExpressions = subFolders.map((subFolder) => convertFileOrFolderNameToExpression(subFolder));
  return wrapExpressionsToPath([...subFoldersExpressions, fileNameExpression]);
};

const groupVariables = (variables: IVariable[]) => {
  const result = [];
  result.push({ name: 'Job Variables', value: 'job', id: 'job', type: 'header' });
  const jobVariables = sortByName(variables.filter((variable) => variable.value.includes('$')));
  result.push(...jobVariables);
  result.push({ name: 'Document Variables', value: 'document', id: 'document', type: 'header' });
  const documentVariables = sortByName(variables.filter((variable) => !variable.value.includes('$')));
  result.push(...documentVariables);
  return result;
};

const isDocValidAsShareFileVariable = (doc: ITableCellsStructuredData, column: string) => {
  if (doc.state === DocumentModel.States.Failed) {
    return true;
  }
  const docType = doc.fields.find((fld) => fld.name === 'document_type')?.value;
  const field = doc.fields.find((f) => f.name === column);
  if (column === 'period_end_date' || column === 'due_date') {
    if (docType === 'alkymi.capital-notice' && column === 'due_date') {
      return field && field?.value && field?.value !== '';
    }
    if (docType === 'alkymi.capital-statement' && column === 'period_end_date') {
      return field && field?.value && field?.value !== '';
    }
    return true;
  }

  return field && field?.value && field?.value !== '';
};

const generateDynamicVariables = (
  columns: string[],
  currentJobTableData: ITableCellsStructuredData[],
  midasFields: IMidasFields2[],
): IVariable[] => {
  const docTypes: string[] = [];
  const presentColumns: string[] = [];
  const onlyApprovedFiles: ITableCellsStructuredData[] = currentJobTableData.filter(
    (file) => !DocumentModel.States.allBroken.includes(file.state),
  );
  const formulaFields = midasFields.filter((fld) => fld.calcType === MidasFieldCalcType.FORMULA).map((el) => el.id);
  let allValues =
    columns
      .filter((column) => {
        return onlyApprovedFiles.every((row) => {
          const docType = row.fields.find((field) => field.name === 'document_type')?.value;
          if (docType && !docTypes.includes(docType === 'alkymi.capital-notice' ? 'due_date' : 'period_end_date')) {
            docTypes.push(docType === 'alkymi.capital-notice' ? 'due_date' : 'period_end_date');
          }
          return isDocValidAsShareFileVariable(row, column);
        });
      })
      // filter out formulaFields (i.e. file_date) received from BE and add it later programmatically if necessary
      .filter((c) => !c.includes('_Balance') && !c.includes('_Amount') && !formulaFields.includes(c))
      .map((column) => {
        const midField = midasFields.find((field) => field.id === column);
        const type = midField?.type || DataType2.string;
        const description = midField?.description || '';
        const columnName = column.toUpperCase();
        if (column === 'period_end_date' || column === 'due_date') {
          presentColumns.push(column);
        }
        return {
          name: `${columnName} (${description})`,
          value: columnName,
          id: columnName,
          type,
        };
      }) || [];

  const fileDateVar = {
    name: `FILE_DATE (Period ending date for Statements and due date for Notices)`,
    value: 'FILE_DATE',
    id: 'FILE_DATE',
    type: DataType2.date,
  };
  allValues = allValues.filter((value) => value.id !== 'PERIOD_END_DATE' && value.id !== 'DUE_DATE');

  if (docTypes.length === 2) {
    if (presentColumns.length === 2) {
      allValues.push(fileDateVar);
    }
  }
  if (docTypes.length === 1 && presentColumns.includes(docTypes[0])) {
    allValues.push(fileDateVar);
  }
  return allValues;
};

const isAllDoubleQuotesClosed = (text: string) => {
  const numberOfDoubleQuotes = (text.match(/"/g) || []).length;
  if (numberOfDoubleQuotes % 2 !== 0) {
    return false;
  }
  return true;
};

const debugParams = {
  directory_guid: 'allshared',
  debug: true,
  sharefile_apicp: '',
  sharefile_domain: '',
  access_token: '',
  expression: '',
};

const validateFileOrFolderExpression = async (
  fileOrFolder: Types.ISubFolder,
  jobId: string,
): Promise<Types.ISubFolder> => {
  const isDoubleQuotesClosed = isAllDoubleQuotesClosed(fileOrFolder.value);
  if (!isDoubleQuotesClosed) {
    return {
      ...fileOrFolder,
      error: true,
      helperText: Errors.ShareFile.WrongNumberOfDoubleQuotes,
    };
  }
  debugParams.expression = wrapExpressionsToPath([fileOrFolder.value]);
  try {
    await postDocsToShareFile(debugParams, jobId);
    return { ...fileOrFolder, error: false, helperText: '' };
  } catch (error) {
    console.error('error is ', error);
    return {
      ...fileOrFolder,
      error: true,
      helperText: Errors.ShareFile.UnexpextedToken,
    };
  }
};

export {
  getSubTreeFromTreeByID,
  convertFileOrFolderNameToExpression,
  addFileNameExtension,
  getShareFilePathForSaving,
  groupVariables,
  generateDynamicVariables,
  wrapExpressionsToPath,
  validateFileOrFolderExpression,
};
