import React, { useState, useEffect, createContext, PropsWithChildren } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { BeatLoader } from 'react-spinners';

import { EnrollmentAPI, GatewayManagementAPI } from '../services';
import { Types } from '../constants';
import defaultFavicon from '../images/gateway.png';
import defaultLogo from '../images/default-logo.png';

export const AuthContext = createContext<Types.IAuthContext>({
  customLogo: { success: false, logo: defaultLogo },
  customBgColor: { success: false, color: '#041f3f' },
  customFavicon: { success: false, favicon: defaultFavicon },
  enrollmentData: {},
  clientLoginTypes: { success: false, types: [] },
  employeeLoginTypes: { success: false, types: [] },
  refreshEnrollmentData: () => null,
  getCustomLogo: () => null,
  getCustomBgColor: () => null,
  getCustomFavicon: () => null,
});

const authContextStyles: Record<string, SxProps<Theme> | undefined> = {
  root: {
    '.container': {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      transition: 'opacity 0.3s linear',
      zIndex: 99999999,
    },
  },
};

interface FullscreenProps {
  customBgColor: string;
}

export const FullScreenLoader = ({ customBgColor }: FullscreenProps) => {
  const color = customBgColor === '' ? 'transparent' : customBgColor;
  return (
    <Box sx={authContextStyles.root}>
      <div className="container loading-spinner-container" style={{ backgroundColor: color }}>
        <BeatLoader color="#919BD1" size={20} margin={2} />
      </div>
    </Box>
  );
};

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [enrollmentData, setEnrollmentData] = useState<Partial<Types.EnrollmentData>>({ success: false });
  const [customLogo, setCustomLogo] = useState({ success: false, logo: defaultLogo });
  const [customBgColor, setCustomBgColor] = useState({ success: false, color: '#041f3f' });
  const [customFavicon, setCustomFavicon] = useState({ success: false, favicon: defaultFavicon });
  const [clientLoginTypes, setClientLoginTypes] = useState<Types.ILoginTypes>({ success: false, types: [] });
  const [employeeLoginTypes, setEmployeeLoginTypes] = useState<Types.ILoginTypes>({ success: false, types: [] });

  useEffect(() => {
    onInitLoad();
  }, []);

  const onInitLoad = async () => {
    await Promise.allSettled([
      EnrollmentAPI.getAuthData(),
      EnrollmentAPI.getFavIconUrl(),
      GatewayManagementAPI.getGatewayLogo(),
    ]).then((results) => {
      const [authDataRes, faviconRes, logoRes] = results;

      setClientLoginTypes({
        success: true,
        types:
          authDataRes.status === 'fulfilled' &&
          authDataRes.value.client_login_types &&
          Array.isArray(authDataRes.value.client_login_types) &&
          authDataRes.value.success
            ? authDataRes.value.client_login_types
            : [],
      });
      setEmployeeLoginTypes({
        success: true,
        types:
          authDataRes.status === 'fulfilled' &&
          authDataRes.value.client_login_types &&
          Array.isArray(authDataRes.value.employee_login_types) &&
          authDataRes.value.success
            ? authDataRes.value.employee_login_types
            : [],
      });
      setCustomBgColor({
        success: true,
        color:
          authDataRes.status === 'fulfilled' &&
          authDataRes.value.bgColor &&
          typeof authDataRes.value.bgColor === 'string' &&
          authDataRes.value.success
            ? authDataRes.value.bgColor
            : '#041f3f',
      });
      setCustomFavicon({
        success: true,
        favicon:
          faviconRes.status === 'fulfilled' &&
          faviconRes.value.data &&
          typeof faviconRes.value.data === 'string' &&
          faviconRes.value.success
            ? faviconRes.value.data
            : defaultFavicon,
      });
      setCustomLogo({
        success: true,
        logo: logoRes.status === 'fulfilled' && logoRes.value.success ? logoRes.value.data : defaultLogo,
      });
    });

    setLoading(false);
  };

  const getCustomLogo = async (refresh = false) => {
    if (refresh) {
      setCustomLogo({
        success: false,
        logo: '',
      });
    }

    const logoRes = await GatewayManagementAPI.getGatewayLogo();

    setCustomLogo({
      success: true,
      logo: logoRes.success && logoRes.data && typeof logoRes.data === 'string' ? logoRes.data : defaultLogo,
    });
  };

  const getCustomBgColor = async (refresh = false) => {
    if (refresh) {
      setCustomBgColor({
        success: false,
        color: '',
      });
    }

    const colorRes = await EnrollmentAPI.getAuthData();

    setCustomBgColor({
      success: true,
      color:
        colorRes.success && colorRes.bgColor && typeof colorRes.bgColor === 'string' ? colorRes.bgColor : '#041f3f',
    });
  };

  const getCustomFavicon = async (refresh = false) => {
    if (refresh) {
      setCustomFavicon({
        success: false,
        favicon: '',
      });
    }

    const faviconRes = await EnrollmentAPI.getFavIconUrl();

    setCustomFavicon({
      success: true,
      favicon:
        faviconRes.success && faviconRes.data && typeof faviconRes.data === 'string' ? faviconRes.data : defaultFavicon,
    });
  };

  const refreshEnrollmentData = async (enrData?: Partial<Types.EnrollmentData>) => {
    setEnrollmentData({ success: true, ...enrData });
  };

  return (
    <AuthContext.Provider
      value={{
        customLogo,
        customBgColor,
        customFavicon,
        enrollmentData,
        clientLoginTypes,
        employeeLoginTypes,
        refreshEnrollmentData,
        getCustomLogo,
        getCustomBgColor,
        getCustomFavicon,
      }}
    >
      {isLoading ? <FullScreenLoader customBgColor={customBgColor.color} /> : children}
    </AuthContext.Provider>
  );
};
