enum TimingStatsEvents {
  'CreateNewJob' = 'CreateNewJob',
  'OpenJob' = 'OpenJob',
  'ChangeNumberOfFilesPerPage' = 'ChangeNumberOfFilesPerPage',
  'ChangePage' = 'ChangePage',
  'SaveJob' = 'SaveJob',
  'SaveToShareFile' = 'SaveToShareFile',
  'ExportToExcel' = 'ExportToExcel',
}

export { TimingStatsEvents };
