import emergencyReserves from '../Dashboard/assets/emergency-reserves.png';
import protectionPlanning from '../Dashboard/assets/protection-planning.png';
import estatePlanning from '../Dashboard/assets/estate-planning.png';
import retirementPlanning from '../Dashboard/assets/retirement-planning.png';
import investmentPlanning from '../Dashboard/assets/investment-planning.png';
import taxPlanning from '../Dashboard/assets/tax-planning.png';
import philanthrophicPlanning from '../Dashboard/assets/philanthropic-planning.png';
import cashPlanning from '../Dashboard/assets/cashflow-planning.png';
import educationPlanning from '../Dashboard/assets/education-planning.png';
import { IKeyAreasOfPlanning } from '../../constants/Types';

export const areasOfPlanningTitles: IKeyAreasOfPlanning[] = [
  {
    title: 'emergency reserves',
    key: "emergencyReserves",
    logo: emergencyReserves,
    accountabilityHeading: 'Have 6 month worth of expenses in emergency reserves.',
    firstRow: '3-6 months reserved.',
    secondRow: '1-3 months reserved.',
    thirdRow: '<1 months reserved.',
  },
  {
    title: 'protection planning',
    key: "protectionPlanning",
    logo: protectionPlanning,
    accountabilityHeading: 'Coverage for any debt and underfunded goals.',
    firstRow: '100% of target met.',
    secondRow: '70-99% of target met.',
    thirdRow: '0-69% of target met.',
  },
  {
    title: 'estate planning',
    key: "estatePlanning",
    logo: estatePlanning,
    accountabilityHeading: 'have a will healthcare directive and power of attorney in place.',
    firstRow: 'In place and reviewed.',
    secondRow: 'In place but not reviewed.',
    thirdRow: 'Documents not in place.',
  },
  {
    title: 'retirement planning',
    key: "retirementPlanning",
    logo: retirementPlanning,
    accountabilityHeading: 'Achieve a 80% Monte Carlo probability of success:',
    firstRow: '100% of target met.',
    secondRow: '70-99% of target met.',
    thirdRow: '0-69% of target met.',
  },
  {
    title: 'investment planning',
    key: "investmentPlanning",
    logo: investmentPlanning,
    accountabilityHeading: 'Investment allocation in alignment with values, goals and risk tolerance.',
    firstRow: 'Aligned & reviewed.',
    secondRow: 'Risk or investment allocation unknown or misaligned.',
    thirdRow: 'Both risk and investment allocation unknown or misaligned.',
  },
  {
    title: 'tax planning',
    key: "taxPlanning",
    logo: taxPlanning,
    accountabilityHeading: 'Have annual tax opportunity checklist reviewed with the last 12 month.',
    firstRow: 'Reviewed and action taken.',
    secondRow: 'Reviewed but no action.',
    thirdRow: 'Checklist not yet reviewed.',
  },
  {
    title: 'philanthrophic planning',
    key: "philanthropicPlanning",
    logo: philanthrophicPlanning,
    accountabilityHeading: 'Positively impact a cause or organization in alignment with your passions.',
    firstRow: 'Identified & contributing.',
    secondRow: 'Identified but not yet contributing.',
    thirdRow: 'Cause/org not yet identified.',
  },
  {
    title: 'cash planning',
    key: "cashPlanning",
    logo: cashPlanning,
    accountabilityHeading: 'Dollars are allocated in alignment with mission and passions.',
    firstRow: 'Cashflow in alignment.',
    secondRow: 'Cashflow is not in alignment.',
    thirdRow: 'Mission & passions not yet known.',
  },
  {
    title: 'education planning',
    key: "educationPlanning",
    logo: educationPlanning,
    accountabilityHeading: 'Have formal funding in place and on track.',
    firstRow: '100% of target met.',
    secondRow: '70-99% of target met.',
    thirdRow: '0-69% of target met.',
  },
];
