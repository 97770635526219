import React, { useState, useContext, useRef, useEffect, useCallback, useMemo } from 'react';
import { Typography, Button, Fade } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';

import { UserContext } from '../context/UserContext';
import { StyledShadowUserOverlay } from './styles/index';
import { StyledModal } from './styles/styled-modal';
import { getColors } from '../utils/colors';
import { stickModalToBorders, windowResizeHandler } from './utility/index';
import { GatewayManagementAPI } from '../services/index';
import { Types } from '../constants/index';

interface IMouseCoordinates {
  x: number;
  y: number;
}

export const ShadowUserOverlay = () => {
  const userContext = useContext(UserContext);

  const topRef = useRef(null);
  const rightRef = useRef(null);
  const bottomRef = useRef(null);
  const leftRef = useRef(null);
  const infoModalRef = useRef(null);
  const dragIcon = useRef(null);
  const arrowIcon = useRef(null);

  const [toggleStarted, setToggleStarted] = useState<boolean>(false);
  const [mouseCoordinates, setMouseCoordinates] = useState<IMouseCoordinates>({ x: 0, y: 0 });
  const [mouseUp, setMouseUp] = useState<boolean>(false);
  const [rolesListVisible, setRolesListVisible] = useState<boolean>(false);
  const [allRoles, setAllRoles] = useState<Types.RoleType[]>([]);
  const [pickedRole, setPickedRole] = useState<string>('');
  const [isShadowRegime, setShadowRegime] = useState<boolean>(true);

  const borders = useMemo(() => {
    return [
      { class: 'top', ref: topRef },
      { class: 'right', ref: rightRef },
      { class: 'bottom', ref: bottomRef },
      { class: 'left', ref: leftRef },
    ];
  }, []);

  useEffect(() => {
    const arrowIconElem = arrowIcon.current as unknown as HTMLElement;
    const infoModalRefElem = infoModalRef.current as unknown as HTMLDivElement;
    if (arrowIconElem) {
      if (rolesListVisible && infoModalRefElem) {
        arrowIconElem.style.transform = 'rotate(180deg)';
      } else {
        arrowIconElem.style.transform = 'none';
      }
    }
  }, [rolesListVisible]);

  useEffect(() => {
    if (userContext.shadowedRole.isShadowed) {
      const topRefElem = topRef.current as unknown as HTMLDivElement;
      const rightRefElem = rightRef.current as unknown as HTMLDivElement;
      const bottomRefElem = bottomRef.current as unknown as HTMLDivElement;
      const leftRefElem = leftRef.current as unknown as HTMLDivElement;

      if (topRefElem && rightRefElem && bottomRefElem && leftRefElem) {
        setInterval(() => {
          colorBreatheEffect([topRefElem, rightRefElem, bottomRefElem, leftRefElem]);
        }, 3000);
      }
    }
  }, [userContext.shadowedRole.isShadowed]);

  useEffect(() => {
    if (toggleStarted) {
      const infoModalRefElem = infoModalRef.current as unknown as HTMLDivElement;
      if (infoModalRefElem) {
        const offset = infoModalRefElem.getBoundingClientRect();
        infoModalRefElem.style.top = `${mouseCoordinates.y - offset.height / 2}px`;
        infoModalRefElem.style.left = `${mouseCoordinates.x - 15}px`;
      }
    }
  }, [mouseCoordinates, toggleStarted]);

  useEffect(() => {
    if (toggleStarted) {
      const infoModalRefElem = infoModalRef.current as unknown as HTMLDivElement;
      const dragIconElem = dragIcon.current as unknown as HTMLDivElement;

      if (infoModalRefElem && dragIconElem && toggleStarted) {
        stickModalToBorders(infoModalRefElem);

        dragIconElem.classList.add('grab');
        dragIconElem.classList.remove('grabbing');

        infoModalRefElem.classList.add('hover');
      }
    }
    setToggleStarted(false);
    setMouseUp(false);
  }, [mouseUp]);

  useEffect(() => {
    getAllRoles();
    window.addEventListener('resize', resizeCb);
    document.addEventListener('mousemove', mousemoveCb);
    document.addEventListener('mouseup', mouseupCb);
    return cleanUp;
  }, []);

  function cleanUp() {
    window.removeEventListener('resize', resizeCb);
    document.removeEventListener('mouseup', mouseupCb);
    document.removeEventListener('mousemove', mousemoveCb);
  }

  const mouseupCb = useCallback((ev: any) => {
    if (ev?.button === 0) {
      setMouseUp(true);
    }
  }, []);

  const resizeCb = useCallback(
    _.throttle(() => {
      const infoModalRefElem = infoModalRef.current as unknown as HTMLDivElement;
      if (infoModalRefElem && userContext.shadowedRole?.isShadowed) {
        windowResizeHandler(infoModalRefElem);
      }
    }, 70),
    [],
  );

  const mousemoveCb = useCallback(
    _.throttle((ev) => {
      setMouseCoordinates({ x: ev.clientX, y: ev.clientY });
    }, 70),
    [],
  );

  const handleShadowBtnClick = () => {
    const shadowedRoleData = userContext.shadowedRole?.shadowedRole;
    if (shadowedRoleData) {
      setShadowRegime(false);
      userContext.activateShadowedRole(false, shadowedRoleData?.id || '');
    }
  };

  const onMouseDownHandler = (event?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (event?.button === 0) {
      const infoModalRefElem = infoModalRef.current as unknown as HTMLDivElement;
      const dragIconElem = dragIcon.current as unknown as HTMLDivElement;

      if (infoModalRefElem && dragIconElem) {
        infoModalRefElem.style.transition = 'none';
        infoModalRefElem.style.transformOrigin = 'center';

        setRolesListVisible(false);
        setToggleStarted(true);
        setMouseUp(false);

        dragIconElem.classList.remove('grab');
        dragIconElem.classList.add('grabbing');

        infoModalRefElem.classList.remove('hover');
        setTimeout(() => {
          infoModalRefElem.style.transition = 'all 0.1s';
        }, 10);
      }
    }
  };

  const colorBreatheEffect = (elArr: HTMLDivElement[]) => {
    if (userContext.shadowedRole?.isShadowed) {
      elArr?.map((el) => {
        el.classList.toggle('transparent');
      });
    }
  };

  const showRolesList = () => {
    setRolesListVisible((prevState) => !prevState);
  };

  const getAllRoles = async () => {
    const { id, label } = userContext.shadowedRole?.shadowedRole;
    if (id && label) {
      const res = await GatewayManagementAPI.getUserRoles();
      if (res.success && Array.isArray(res.roles)) {
        setAllRoles(res.roles);
      }
    }
  };

  const shadowNewGroup = (r: Types.RoleType) => {
    setPickedRole(r.id || '');
    userContext.activateShadowedRole(true, r.id || '');
  };

  return (
    <StyledShadowUserOverlay colors={getColors()}>
      {borders.map((b) => (
        <div className={`border ${b.class}`} ref={b.ref} />
      ))}
      <div className="shadow-modal-info hover" ref={infoModalRef}>
        <div className="drag-icon-wrapper">
          <DragIndicatorIcon className="drag-icon grab" onMouseDown={onMouseDownHandler} ref={dragIcon} />
        </div>
        <div className="row-wrapper">
          <div className="modal-row">
            <Typography className="text left-part">Shadowed mode:</Typography>
            <Typography className="text colored">On</Typography>
          </div>
          <div className="modal-row">
            <Typography className="text left-part">Shadowed role:</Typography>
            <div className="drop-down right-part" onClick={showRolesList}>
              <Typography className="text">{userContext.shadowedRole?.shadowedRole?.label || ''}</Typography>
              <KeyboardArrowDownIcon className="arrow-icon" ref={arrowIcon} />
            </div>
          </div>
          <div className="modal-row">
            <Typography className="text left-part">Assigned role:</Typography>
            <Typography className="text right-part">{userContext.shadowedRole?.assignedRole?.label || ''}</Typography>
          </div>
          <div className="btn-wrapper">
            <Button variant="outlined" onClick={handleShadowBtnClick} className="turn-off-btn">
              {isShadowRegime ? 'Turn Off' : <CircularProgress size={12} className="circular-progress" />}
            </Button>
          </div>
        </div>
      </div>
      <StyledModal colors={getColors()} open={rolesListVisible} onClose={() => setRolesListVisible(false)}>
        <div className="modal-wrapper">
          <Typography className="modal-heading">Pick user group to shadow</Typography>
          {allRoles?.map((r) => {
            const shadowedRoleData = userContext.shadowedRole;
            return r.id === shadowedRoleData?.shadowedRole.id || r.id === shadowedRoleData?.assignedRole?.id ? (
              <div className="list-element-wrapper disabled">
                <Typography className="list-element bold">{r.label}</Typography>
                <Typography className="list-element">
                  {r.id === shadowedRoleData?.shadowedRole.id
                    ? ' - currently shadowed user group'
                    : ' - your assigned user group'}
                </Typography>
              </div>
            ) : (
              <div className="list-element-wrapper space-between" onClick={() => shadowNewGroup(r)}>
                <Typography className="list-element bold">{r.label}</Typography>
                <Fade in={pickedRole === r.id} unmountOnExit>
                  <CircularProgress size={18} />
                </Fade>
              </div>
            );
          })}
        </div>
      </StyledModal>
    </StyledShadowUserOverlay>
  );
};
