import _ from 'lodash';

import { FileTypes, SERVER_TIME_TIMEZONE, TableColumns } from '../constants';
import { IStructuredPdfField } from '../../../constants/Types';
import { defaultColumnsOrder } from '../constants/TableColumns';

/**
 * Convert string date-time from server to Date object in local timezone
 * @param dateTimeStr e.g. "2022-10-03T13:10:12.123312 "
 * @returns Date object in local timezone
 */
const convertDateFormat = (dateTimeStr: string): string => {
  const utcDateTime = new Date(`${dateTimeStr}${SERVER_TIME_TIMEZONE}`);
  const userLocale =
    navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language || 'en-US';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return utcDateTime.toLocaleString(userLocale, {
    timeZone,
  });
};

/**
 *  Capitalize first letter of string
 * @param string
 */
const capitalizeFirstLetter = (string: string) => {
  return String(string)
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Sanitize string(escape special characters)
 * @param str
 */
const sanitize = (str: string) => str.replace(/[^A-Za-z0-9]/g, '_');

/**
 * Sort array of any objects by 'name' key
 * @param array
 */
const sortByName = <T extends { name: string }>(array: T[]): T[] => {
  return array.sort((a, b) => a.name.localeCompare(b.name));
};

const sortByDateTime = <T extends { dateTime: string }>(array: T[]): T[] => {
  return array.sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime());
};

const sortColumns2 = (columns: string[]) => {
  const columnsArr: string[] = [];
  try {
    let jobColumnsOrder = defaultColumnsOrder;
    const locallyStoredColumnsOrder = localStorage.getItem('columnsOrder');
    if (locallyStoredColumnsOrder) {
      jobColumnsOrder = JSON.parse(locallyStoredColumnsOrder);
    }

    for (const column of jobColumnsOrder) {
      if (columns.includes(column)) columnsArr.push(column);
    }
    const remainingFields = _.difference(columns, columnsArr);
    columnsArr.push(...remainingFields);
    if (locallyStoredColumnsOrder && remainingFields) {
      const parsedLocallyStoredColumnsOrder: string[] = JSON.parse(locallyStoredColumnsOrder);
      parsedLocallyStoredColumnsOrder.push(...remainingFields);
      localStorage.setItem('columnsOrder', JSON.stringify(parsedLocallyStoredColumnsOrder));
    }
    if (!locallyStoredColumnsOrder) {
      let newLocallyStoredColumnsOrder: string[] = [];
      newLocallyStoredColumnsOrder = columnsArr;
      localStorage.setItem('columnsOrder', JSON.stringify(newLocallyStoredColumnsOrder));
    }
    return columnsArr;
  } catch (e) {
    for (const column of defaultColumnsOrder) {
      if (columns.includes(column)) columnsArr.push(column);
    }
    const remainingFields = _.difference(columns, columnsArr);
    columnsArr.push(...remainingFields);
    return columnsArr;
  }
};

/**
 * Calculate percentage of number
 * @param value
 * @param total
 */
const calculatePercentage = (value: number, total: number): number => {
  return Math.round((value / total) * 100);
};

/**
 * Convert base64 string to blob
 * @param base64
 * @param contentType
 * @param sliceSize
 */
const convertBase64ToUrl = (base64: string, contentType = FileTypes.PDF, sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  const blobUrl = URL.createObjectURL(blob);

  return blobUrl;
};

const convertAllDateFormatsToIso = (dateString: string): string => {
  const dateStringWithUtc = `${dateString} UTC`;
  const date = new Date(dateStringWithUtc);
  const dateTimeIso = date.toISOString();
  const dateTimeIsoWithoutTime = dateTimeIso.split('T')[0];
  return dateTimeIsoWithoutTime;
};

const isSelectingAvailable = (isSubmitted: boolean, colId: string, isFormula: boolean): boolean => {
  const isSystemColumn = Object.values(TableColumns.SystemColumns).includes(colId as TableColumns.SystemColumns);
  return !isSubmitted && !isSystemColumn && !isFormula;
};

const getField2 = (fields: (IStructuredPdfField | null)[], column: string) => {
  return fields.find((f) => f?.name === column);
};

const isApproveRejectAvailable = (isSubmitted: boolean, columnId: string): boolean => {
  const isStatusColumn = columnId === 'status';
  return isStatusColumn && !isSubmitted;
};

const moveElement = (arr: string[], element: string, afterElement: string) => {
  const elementIndex = arr.findIndex((el) => el === element);
  const afterElementIndex = arr.findIndex((el) => el === afterElement);
  const updatedArray = [...arr];
  updatedArray.splice(elementIndex, 1);
  updatedArray.splice(afterElementIndex < elementIndex ? afterElementIndex + 1 : afterElementIndex, 0, element);
  return updatedArray;
};

const getColumnsWidth = () => {
  try {
    const locallyStoredColumnsWidth: { [key: string]: number } | undefined =
      localStorage.getItem('columnsWidth') && JSON.parse(localStorage.getItem('columnsWidth') as string);
    const arrayOfColumnsWidth = locallyStoredColumnsWidth ? Object.keys(locallyStoredColumnsWidth) : [];

    return { locallyStoredColumnsWidth, arrayOfColumnsWidth };
  } catch (e) {
    return { locallyStoredColumnsWidth: undefined, arrayOfColumnsWidth: [] };
  }
};

export {
  convertDateFormat,
  capitalizeFirstLetter,
  sanitize,
  sortByName,
  calculatePercentage,
  convertBase64ToUrl,
  convertAllDateFormatsToIso,
  sortByDateTime,
  sortColumns2,
  isSelectingAvailable,
  isApproveRejectAvailable,
  getField2,
  moveElement,
  getColumnsWidth,
};
