import { Types } from '../../../constants';

export function getStateTokenFromUrl() {
  const url = window.location.href;
  const userToken = url.split('#')?.[1];
  return userToken || '';
}

export const validatePassword = (p: string) => {
  const reMinChar = /.{8,}/;
  const reLowerCase = /[a-z]{1,}/;
  const reUpperCase = /[A-Z]{1,}/;
  const reNumbers = /[0-9]{1,}/;
  const reSpecChar = /\W{1,}/;
  const reSpaces = /  {1,}/;

  const passwordValid =
    Boolean(p.match(reMinChar)) &&
    Boolean(p.match(reLowerCase)) &&
    Boolean(p.match(reUpperCase)) &&
    Boolean(p.match(reNumbers)) &&
    Boolean(p.match(reSpecChar)) &&
    !Boolean(p.match(reSpaces));

  return passwordValid;
};

export const setFavicon = async (authContext: Types.IAuthContext) => {
  let favIconEl = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (authContext.customFavicon.favicon) {
    if (!favIconEl) {
      favIconEl = document.createElement('link');
      favIconEl.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(favIconEl);
    }
    favIconEl.href = authContext.customFavicon.favicon;
  }
};
