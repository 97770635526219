const PATH_NAME = /^([-A-Za-z0-9$()"%+{}:, &#!_/\\]*)$/i;

const INVALID_PATH_NAME = /[^-A-Za-z0-9$()"%+{}:, &#!_/\\]/i;

const SLASH_OR_BACKSLASH = /[/\\]/;

// const DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/;
const DATE_REGEX = /^(?:(?:19|20)\d\d)-(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-9]|3[0-1])$/;

const DATE_REGEX_ALT = /^(\d{4})\/(\d{2})\/(\d{2})$/;

// Long date format "Mar 25 2015"
const DATE_REGEX_LONG = /^([A-Za-z]{3})\s(\d{2})\s(\d{4})$/;

// Long date format "25 Mar 2015"
const DATE_REGEX_LONG_ALT = /^(\d{2})\s([A-Za-z]{3})\s(\d{4})$/;

// Long date format "Mar 25, 2015"
const DATE_REGEX_LONG_ALT2 = /^([A-Za-z]{3})\s(\d{2}),\s(\d{4})$/;

// Long date format "25 Mar, 2015"
const DATE_REGEX_LONG_ALT3 = /^(\d{2})\s([A-Za-z]{3}),\s(\d{4})$/;

// Long date format "September 25, 2015"
const DATE_REGEX_LONG_ALT4 = /^([A-Za-z]{3,})\s(\d{2}),\s(\d{4})$/;

// Long date format "25 September, 2015"
const DATE_REGEX_LONG_ALT5 = /^(\d{2})\s([A-Za-z]{3,}),\s(\d{4})$/;

// Long date format "September 25 2015"
const DATE_REGEX_LONG_ALT6 = /^([A-Za-z]{3,})\s(\d{2})\s(\d{4})$/;

// Long date format "25 September 2015"
const DATE_REGEX_LONG_ALT7 = /^(\d{2})\s([A-Za-z]{3,})\s(\d{4})$/;

const DATE_REGEX_ALL_FORMATS = new RegExp(
  [
    DATE_REGEX,
    DATE_REGEX_ALT,
    DATE_REGEX_LONG,
    DATE_REGEX_LONG_ALT,
    DATE_REGEX_LONG_ALT2,
    DATE_REGEX_LONG_ALT3,
    DATE_REGEX_LONG_ALT4,
    DATE_REGEX_LONG_ALT5,
    DATE_REGEX_LONG_ALT6,
    DATE_REGEX_LONG_ALT7,
  ]
    .map((r) => r.source)
    .join('|'),
);

const FLOAT_REGEX = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;

const INTEGER_REGEX = /^[-+]?[0-9]+$/;

const DATE_TIME_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|([+-])(\d{2}):(\d{2}))$/;

const BOOLEAN_REGEX = /^(true|false|0|1)$/i;

export {
  PATH_NAME,
  SLASH_OR_BACKSLASH,
  INVALID_PATH_NAME,
  DATE_REGEX,
  FLOAT_REGEX,
  INTEGER_REGEX,
  DATE_TIME_REGEX,
  BOOLEAN_REGEX,
  DATE_REGEX_ALL_FORMATS,
};
