import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { Button, Theme, Tooltip, Box, SxProps } from '@mui/material';

import { UserContext } from '../../context/UserContext';
import { ConnectAdvisorModal } from './ConnectAdvisorModal';
import { ModuleKeys, UserRoleTypes } from '../../constants';

const HeaderButtonsStyles: Record<string, SxProps<Theme> | undefined> = {
  button: (theme) => ({
    height: 35,
    borderRadius: '20px',
    fontSize: '12px',
    fontFamily: 'Proxima Nova, sans-serif',
    fontWeight: 'bold',
    width: '225px',
    margin: '0 15px 0 0',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    '&:disabled': {
      borderColor: '#D0D3D4',
      color: '#D0D3D4',
    },
    [theme.breakpoints.down(1140)]: {
      padding: '6px',
      fontSize: '11px',
      width: '180px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 0 15px 0',
      width: '180px',
    },
  }),
  bookBtn: (theme) => ({
    height: 35,
    borderRadius: '20px',
    fontSize: '12px',
    fontFamily: 'Proxima Nova, sans-serif',
    fontWeight: 'bold',
    width: '225px',
    margin: '0 15px 0 0',
    border: '1px solid transparent',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: '1px solid white',
    },
    '&:disabled': {
      backgroundColor: '#D0D3D4',
    },
    [theme.breakpoints.down(1140)]: {
      padding: '6px',
      fontSize: '11px',
      width: '130px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 0 15px 0',
      width: '180px',
    },
  }),
  btnsContainer: (theme) => ({
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
};

type btnVarTypes = 'text' | 'outlined' | 'contained';

enum btnVariantEnum {
  text = 'text',
  outlined = 'outlined',
  contained = 'contained',
}

const getHeaderAdvisorsBtn = (
  conditionToDisplayBtns: boolean,
  conditionToDisable: boolean,
  cssClasses: SxProps<Theme> | undefined,
  btnVariant: btnVarTypes,
  btnText: string,
  modalOpenFunc: (value: React.SetStateAction<boolean>) => void,
) => {
  return (
    conditionToDisplayBtns && (
      <Tooltip title="You have no advisors assigned." arrow disableHoverListener={!conditionToDisable}>
        <Button variant={btnVariant} disabled={conditionToDisable} sx={cssClasses} onClick={() => modalOpenFunc(true)}>
          {btnText}
        </Button>
      </Tooltip>
    )
  );
};

const HeaderButtons = () => {
  const userContext = useContext(UserContext);
  const advisorArr = userContext.advisors?.advisors || [];
  const areAdvisorsNotAssigned = advisorArr.length < 1;
  const isZoomAdvisorAllowed = Boolean(userContext.role.permissions?.includes(ModuleKeys.zoomAdvisor));
  const isBookAdvisorAllowed = Boolean(userContext.role.permissions?.includes(ModuleKeys.bookAdvisor));

  const [showBookModal, setBookModalVisible] = useState(false);
  const [showZoomModal, setZoomModalVisible] = useState(false);

  const isUserClientWithPermissions =
    userContext.role.role_type?.toLocaleLowerCase() !== UserRoleTypes.client.toLocaleLowerCase() ||
    (userContext.role.permissions?.indexOf(ModuleKeys.bookAdvisor) === -1 &&
      userContext.role.permissions?.indexOf(ModuleKeys.zoomAdvisor) === -1);

  return isUserClientWithPermissions ? null : (
    <Box sx={HeaderButtonsStyles.btnsContainer}>
      {getHeaderAdvisorsBtn(
        isZoomAdvisorAllowed,
        areAdvisorsNotAssigned,
        HeaderButtonsStyles.button,
        btnVariantEnum.outlined,
        'VIDEO CALL YOUR ADVISOR',
        setZoomModalVisible,
      )}
      {getHeaderAdvisorsBtn(
        isBookAdvisorAllowed,
        areAdvisorsNotAssigned,
        HeaderButtonsStyles.bookBtn,
        btnVariantEnum.contained,
        'BOOK A MEETING',
        setBookModalVisible,
      )}
      {showBookModal && <ConnectAdvisorModal isOpen={showBookModal} closeModal={() => setBookModalVisible(false)} />}
      {showZoomModal && (
        <ConnectAdvisorModal isOpen={showZoomModal} closeModal={() => setZoomModalVisible(false)} isZoomModal={true} />
      )}
    </Box>
  );
};

export default HeaderButtons;
