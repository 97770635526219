import axios from 'axios';
import _ from 'lodash';
import { uid } from 'uid';
import { navigate } from 'gatsby';

import { Cookie } from '..';
import { StatusConstants } from '../../constants';
import { onMfaEnrollRequired, onMfaVerification } from '../../utils';
import { LoginFlowEnum, LoginType, SecurityQuestionProps } from '../../constants/Types';
import Config from '../../config';
import { getFailedApiCallResponse } from '../utils';

const { GATEWAY_ID } = Config;

export const getCurrentUser = async () => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/auth/user', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (_.get(res, 'data.success')) {
      return { success: true, user: res.data.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const loginWithCredential = async (
  username: string,
  password: string,
  rememberDevice: boolean,
): Promise<LoginType> => {
  try {
    const deviceToken = Cookie.getDeviceToken();

    if (!rememberDevice) {
      Cookie.removeDeviceToken();
    }

    const res = await axios.post(
      `/auth`,
      { username, password, rememberDevice, deviceToken },
      { headers: { 'gateway-id': GATEWAY_ID } },
    );

    if (rememberDevice && _.get(res, 'data.deviceToken')) {
      Cookie.setDeviceToken(_.get(res, 'data.deviceToken'));
    }

    if (_.get(res, 'data.success') && _.get(res, 'data.status') === StatusConstants.MFA) {
      const smsFactor = _.find(_.get(res, 'data.factors', []), (element) => element.type === 'sms');

      Cookie.setStateToken(_.get(res, 'data.stateToken', ''));
      const id = _.get(smsFactor, 'id', '');
      Cookie.setFactorId(id);
      Cookie.setUserId(_.get(res, 'data.userId', ''));
      return { success: true, status: StatusConstants.MFA, message: _.get(smsFactor, 'message', '') };
    } else if (_.get(res, 'data.success', null)) {
      Cookie.setStateToken(_.get(res, 'data.stateToken', ''));
      Cookie.setStatus(_.get(res, 'data.status'));
      Cookie.setUserId(_.get(res, 'data.userId', ''));
      return {
        success: true,
        status: _.get(res, 'data.status', ''),
        redirectURL: _.get(res, 'data.redirect_url', null),
      };
    } else if (_.get(res, 'data.status') === StatusConstants.UNAUTHORIZED) {
      return { success: false, status: StatusConstants.UNAUTHORIZED };
    } else if (_.get(res, 'data.status') === StatusConstants.MFA_ENROLL) {
      // Let's check if it needs to MFA_ENROLL
      Cookie.setStatus(_.get(res, 'data.status'));
      Cookie.setStateToken(_.get(res, 'data.stateToken'));
      return { success: true, status: StatusConstants.MFA_ENROLL };
    }
    return { ...res.data };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
};

export const passwordlessLogin = async (): Promise<{
  success: boolean;
  redirect_url?: string;
}> => {
  try {
    const res = await axios.post('/auth?type=idp', {}, { headers: { 'gateway-id': GATEWAY_ID } });

    if (res.status) {
      return { success: true, redirect_url: res.data.redirect_url };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const validatePassword = async (password: string) => {
  const accessToken = Cookie.getAccessToken();
  const res = await axios.post(
    '/auth/verify-credentials',
    { password },
    { headers: { 'X-ACCESS-TOKEN': accessToken } },
  );
  if (res.status === 200) {
    return { success: true };
  }
  return { success: false };
};

export const sendMFA = async () => {
  try {
    const stateToken = Cookie.getStateToken();
    const id = Cookie.getFactorId();

    const res = await axios.post('/auth/mfa', { stateToken, id }, { headers: { 'gateway-id': GATEWAY_ID } });
    if (_.get(res, 'data.success', null)) {
      Cookie.setStateToken(_.get(res, 'data.stateToken', ''));
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const verifyMFA = async (code: string): Promise<LoginType> => {
  try {
    const stateToken = Cookie.getStateToken();
    const deviceToken = Cookie.getDeviceToken() ?? false;
    const id = Cookie.getFactorId();
    const data = { stateToken, id, code, rememberDevice: !!deviceToken };
    const res = await axios.post('/auth/mfa/verify', data, { headers: { 'gateway-id': GATEWAY_ID } });

    if (_.get(res, 'data.success', null)) {
      Cookie.setUserId(_.get(res, 'data.userId', ''));
      return {
        success: true,
        status: _.get(res, 'data.status', null),
        redirectURL: _.get(res, 'data.redirect_url', null),
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const resendMFA = async (): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const stateToken = Cookie.getStateToken();
    const id = Cookie.getFactorId();
    const data = { stateToken, id };
    const res = await axios.post('/auth/mfa/verify', data, { headers: { 'gateway-id': GATEWAY_ID } });

    if (_.get(res, 'data.success', null)) {
      Cookie.setStateToken(_.get(res, 'data.stateToken', ''));
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const redirectToken = async (code: string, codeVerifier: string | null) => {
  try {
    const loginFlow = sessionStorage.getItem(LoginFlowEnum.loginFlow);
    const payload = codeVerifier
      ? { code, code_verifier: codeVerifier, use_default: loginFlow === LoginFlowEnum.customOkta }
      : { code };

    const res = await axios.post('/auth/redirect', payload, { headers: { 'gateway-id': GATEWAY_ID } });
    if (_.get(res, 'data.success', null)) {
      Cookie.setAccessToken(_.get(res, 'data.accessToken', ''));
      Cookie.setOktaTokenExpiresAt(_.get(res, 'data.expiresAt', ''));
      Cookie.setOktaToken(_.get(res, 'data.oktaToken', ''));
      Cookie.setRefreshToken(_.get(res, 'data.refreshToken', ''));
      const uniqueSessionId = uid(32);
      Cookie.setUniqueSessionId(uniqueSessionId);
      return { success: true, status: res.data.status };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const refreshSession = async () => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      '/auth/refresh',
      {},
      { headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID } },
    );
    if (_.get(res, 'data.success', null)) {
      Cookie.setAccessToken(_.get(res, 'data.accessToken', ''));
      Cookie.setOktaToken(_.get(res, 'data.oktaToken', ''));
      Cookie.setOktaTokenExpiresAt(_.get(res, 'data.expiresAt', ''));
      Cookie.setRefreshToken(_.get(res, 'data.refreshToken', ''));
      return { success: true, status: res.data.status };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getSecurityQuestions = async (): Promise<{
  success: boolean;
  questions?: SecurityQuestionProps[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('gateway/users/security-questions', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return { success: true, questions: res.data.questions };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const setSecurityQuestions = async (
  question: string,
  answer: string,
  password: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const data = { question, answer, password };
    const res = await axios.post('/gateway/users/security-question/password', data, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const changeExpiredPassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<{
  success: boolean;
  error?: string;
  status?: string;
  redirectURL?: string;
  message?: string;
}> => {
  try {
    const userId = Cookie.getUserId();
    const data = { userId, oldPassword, newPassword };

    const { data: reqData } = await axios.post('/auth/change-password', data, {
      headers: { 'gateway-id': GATEWAY_ID },
    });

    const { success = null, status = '', factors = [], stateToken = '', redirect_url = null, error = '' } = reqData;

    if (success) {
      Cookie.setStateToken(stateToken);
      Cookie.setStatus(status);

      return {
        success: true,
        status,
        redirectURL: redirect_url,
      };
    } else {
      switch (status) {
        case StatusConstants.MFA: {
          const smsFactor = _.find(factors, (element) => element.type === 'sms');

          Cookie.setStateToken(stateToken);
          Cookie.setFactorId(_.get(smsFactor, 'id', ''));

          return { success: false, status: StatusConstants.MFA, message: _.get(smsFactor, 'message', '') };
        }
        case StatusConstants.UNAUTHORIZED: {
          return { success: false, status: StatusConstants.UNAUTHORIZED };
        }
        case StatusConstants.MFA_ENROLL: {
          // Let's check if it needs to MFA_ENROLL
          Cookie.setStatus(status);
          Cookie.setStateToken(stateToken);

          return { success: true, status: StatusConstants.MFA_ENROLL };
        }

        default:
          break;
      }
    }

    return {
      success: false,
      status,
      error,
    };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
};

export const logout = async () => {
  const hostUrl = window.location.href.split('/').slice(0, 3).join('/');
  try {
    const accessToken = Cookie.getAccessToken();
    const loginFlow = sessionStorage.getItem(LoginFlowEnum.loginFlow);
    if (accessToken) {
      // call our back-end to clean session cache and get appropriate next redirect URL (single-sign-out if necessary)
      const res = await axios.post(
        '/auth/logout',
        { use_default: loginFlow === LoginFlowEnum.customOkta },
        { headers: { 'X-ACCESS-TOKEN': accessToken } },
      ); // clean up front end cache      // clean up front end cache
      Cookie.removeAllCookie();
      if (res && res.data) {
        // redirect to whatever URL comes from the backend
        window.location.href = res.data.redirect_url;
        return;
      }
    } else {
      Cookie.removeAllCookie();
      window.location.href = hostUrl;
    }
  } catch (e) {
    Cookie.removeAllCookie();
  }
  window.location.href = hostUrl;
};

export const handleLoginResponse = async (loginRes: LoginType, onSetUpUser: () => void) => {
  switch (loginRes.status) {
    case StatusConstants.MFA: {
      await onMfaVerification(loginRes.message || '');
      break;
    }

    case StatusConstants.SET_SECURITY_QUESTION: {
      navigate('/auth/security-questions');
      break;
    }

    case StatusConstants.PENDING_REDIRECT: {
      window.location.href = loginRes.redirectURL || '';
      break;
    }

    case StatusConstants.LOGGED_IN: {
      onSetUpUser();
      break;
    }

    case StatusConstants.MFA_ENROLL: {
      onMfaEnrollRequired();
      break;
    }

    case StatusConstants.PASSWORD_EXPIRED: {
      navigate('/auth/reset-expired-password');
      break;
    }

    default:
      return;
  }
};

export const sendEmailToResetPassword = async (
  username: string,
): Promise<{
  success: boolean;
  res?: unknown;
  error?: string;
}> => {
  try {
    const res = await axios.post(
      '/auth/recovery-password',
      { username, factorType: 'EMAIL' },
      {
        headers: { 'gateway-id': GATEWAY_ID },
      },
    );
    if (res.status === 200) return { success: true, res: res.data };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const resetPassword = async (
  password: string,
  token: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const data = { password, token };
    const res = await axios.post('/auth/reset-password', data, { headers: { 'gateway-id': GATEWAY_ID } });
    if (res.data.errorSummary) {
      return { success: false, error: res.data.errorSummary };
    } else {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
};

export const getRecoveryQuestion = async (
  token: string,
): Promise<{
  success: boolean;
  error?: string;
  data?: { question: string; stateToken: string };
}> => {
  try {
    const res = await axios.get(`gateway/users/security-question?token=${token}`, {
      headers: { 'gateway-id': GATEWAY_ID },
    });
    if (res.status === 200) return { success: true, data: res.data };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const sendRecoveryChallengeAnswer = async (answer: string, token: string) => {
  try {
    const data = { answer, token };
    const res = await axios.post('/gateway/users/security-question/token', data, {
      headers: { 'gateway-id': GATEWAY_ID },
    });
    if (res.data.status === 'PASSWORD_RESET') return { success: true, res: res.data };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
