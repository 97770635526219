import _ from 'lodash';

import { FileTypes } from '../constants';

const getTotalFilesSize = (files: File[]): number => {
  return files.reduce((acc, file) => acc + file.size, 0);
};

const isFileSizeExceeded = (files: File[], maxSize: number): boolean => {
  const filesSize = getTotalFilesSize(files);
  return filesSize > maxSize;
};

// const readFileCheckEncryption = (file: File) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsArrayBuffer(file);
//     reader.onload = () => {
//       const doc = new Blob([reader.result as BlobPart], { type: 'application/pdf' });
//       doc.text().then((text) => {
//         if (
//           text.includes('Encrypt') ||
//           text.substring(text.lastIndexOf('<<') || text.lastIndexOf('>>')).includes('/Encrypt')
//         ) {
//           resolve({ isEncrypted: true, file: file });
//         }
//         if (
//           !text.includes('Encrypt') &&
//           !text.substring(text.lastIndexOf('<<') || text.lastIndexOf('>>')).includes('/Encrypt')
//         ) {
//           resolve({ isEncrypted: false, file: file });
//         }
//       });
//     };
//   });

// const isFilePasswordProtected = async (files: File[]): Promise<File[][]> => {
//   return await Promise.all(
//     files.map(async (file) => {
//       const result: any = await readFileCheckEncryption(file);
//       return result;
//     }),
//   ).then((results) => {
//     const unEncryptedFiles: File[] = [];
//     const encryptedFiles: File[] = [];

//     results.map((result) => {
//       if (result.isEncrypted) {
//         encryptedFiles.push(result.file);
//       }
//       if (!result.isEncrypted) {
//         unEncryptedFiles.push(result.file);
//       }
//     });
//     return [unEncryptedFiles, encryptedFiles];
//   });
// };

const getUniqueFileNames = (files: File[]): File[] => {
  return _.uniqBy(files, 'name');
};

const getFileDuplicatesNames = (oldFiles: File[], newFiles: File[]): string[] => {
  const oldFileNames = oldFiles.map((file) => file.name);
  const newFileNames = newFiles.map((file) => file.name);
  return oldFileNames.filter((name) => newFileNames.includes(name));
};

const downloadFile = (url: string, fileName: string, fileExtension = 'xlsx'): void => {
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.${fileExtension}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const openFileInNewWindow = (
  dataSrc: string,
  PDFWindow: Window | null,
  fileType = FileTypes.PDF,
  height = 600,
  width = 600,
): Window | null => {
  const pdfWindow = PDFWindow ? PDFWindow : window.open('', '_blank', `height=${height}px,width=${width}px`);

  if (PDFWindow) {
    const oldPdf = pdfWindow?.document.getElementById('pdf_object');
    oldPdf?.parentElement?.removeChild(oldPdf);
  }

  const object = document.createElement('object');
  const iframe = document.createElement('iframe');
  object.id = 'pdf_object';

  object.data = iframe.src = dataSrc;
  object.type = fileType;
  object.width = object.height = iframe.width = iframe.height = '100%';
  iframe.style.border = 'none';
  object.appendChild(iframe);

  if (pdfWindow) {
    pdfWindow.document.body.style.margin = '0';
    pdfWindow.document.body.appendChild(object);
    pdfWindow.focus();
  }

  return pdfWindow;
};

export {
  getTotalFilesSize,
  isFileSizeExceeded,
  getUniqueFileNames,
  getFileDuplicatesNames,
  // isFilePasswordProtected,
  downloadFile,
  openFileInNewWindow,
};
