import {
  faRocket,
  faLeaf,
  faChartArea,
  faAnchor,
  faArchive,
  faAtlas,
  faAtom,
  faAward,
  faHouse,
  faMagnifyingGlass,
  faUser,
  faStar,
  faLocation,
  faCloud,
  faComment,
  faLock,
  faPaperclip,
  faBolt,
  faGear,
  faPen,
  faShareFromSquare,
  faGlobe,
  faCompass,
  faPalette,
  faFolder,
  faChartSimple,
  faCarSide,
  faLaptop,
  faSitemap,
  faBrain,
} from '@fortawesome/free-solid-svg-icons';

export const icons = {
  rocket: faRocket,
  leaf: faLeaf,
  chartArea: faChartArea,
  anchor: faAnchor,
  archive: faArchive,
  atlas: faAtlas,
  atom: faAtom,
  award: faAward,
  house: faHouse,
  glass: faMagnifyingGlass,
  user: faUser,
  star: faStar,
  location: faLocation,
  cloud: faCloud,
  comment: faComment,
  lock: faLock,
  paperclip: faPaperclip,
  bolt: faBolt,
  gear: faGear,
  pen: faPen,
  share: faShareFromSquare,
  globe: faGlobe,
  compass: faCompass,
  palette: faPalette,
  folder: faFolder,
  chart: faChartSimple,
  car: faCarSide,
  laptop: faLaptop,
  sitemap: faSitemap,
  brain: faBrain,
};

export const iconNames = [
  'rocket',
  'leaf',
  'chartArea',
  'anchor',
  'archive',
  'atlas',
  'atom',
  'award',
  'house',
  'glass',
  'user',
  'star',
  'location',
  'cloud',
  'comment',
  'lock',
  'paperclip',
  'bolt',
  'gear',
  'pen',
  'share',
  'globe',
  'compass',
  'palette',
  'folder',
  'chart',
  'car',
  'laptop',
  'sitemap',
  'brain',
];
