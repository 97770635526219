import axios from 'axios';
import _ from 'lodash';

import { Cookie } from '..';
import { getFailedBoxApiCallResponse } from '../utils';
import { LinkCategoriesTypeEnum, ICommonLink } from '../../constants/CommonLinksConstants';

export const getCommonLinks = async (
  type: LinkCategoriesTypeEnum,
): Promise<{
  success: boolean;
  data?: ICommonLink[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get(`gateway/links/authenticated?type=${type}`, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const getCommonLinksByRole = async (
  type: LinkCategoriesTypeEnum,
  roleId: string,
): Promise<{
  success: boolean;
  data?: ICommonLink[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get(`gateway/links?type=${type}&role=${roleId}`, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const getAllLinks = async (): Promise<{
  success: boolean;
  data?: ICommonLink[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get(`gateway/links`, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const createNewCommonLinks = async (
  commonLink: Partial<ICommonLink>,
): Promise<{
  success: boolean;
  data?: any;
  error?: string;
}> => {
  if (commonLink.id === '') {
    delete commonLink.id;
  }

  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.post('gateway/links', commonLink, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 201) {
        return { success: true, data: res.data };
      }
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const updateCommonLink = async (
  commonLink: ICommonLink,
  linkCategoryId: string,
): Promise<{
  success: boolean;
  data?: any;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.patch(`gateway/links/${linkCategoryId}`, commonLink, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const readCommonLink = async (
  linkCategoryId: string,
): Promise<{
  success: boolean;
  data?: any;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get(`gateway/links/${linkCategoryId}`, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const deleteCommonLink = async (
  linkCategoryId: string,
): Promise<{
  success: boolean;
  data?: any;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.delete(`gateway/links/${linkCategoryId}`, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};
