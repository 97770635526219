import axios from 'axios';
import { uid } from 'uid';
import Honeybadger from '@honeybadger-io/js';

// libs
import Cookie from './Cookie';
import Config from '../config';
import * as Utils from '../utils';

// APIs
import * as SettingAPI from './Settings';
import * as CalendarAPI from './Calendar';
import * as EnrollmentAPI from './Enrollment';
import * as CommonLinksAPI from './CommonLinks';
import * as AuthenticationAPI from './Authentication';
import * as GatewayManagementAPI from './GatewayManagement';
import * as BoxAPI from './Box';
import * as BlogPostsAPI from './BlogPosts';
import * as ThirdPartyIntegrationsAPI from './ThirdPartyIntegrations';
import * as SalesForcePortfolioAPI from './SalesforcePortfolio';
import * as GatewayUsersAPI from './UsersAdministration';
import * as RecoveryQuestionAPI from './RecoveryQuestionManagement';
import * as QuickSightAPI from './QuickSight';
import * as AdvisoryTeamsAPI from './AdvisoryTeams';

// constants.ts
import { StatusConstants } from '../constants';

axios.defaults.baseURL = Config.API_BASE_URL;
axios.interceptors.request.use((config) => {
  config.headers['gateway-request-id'] = uid(32);
  Honeybadger.setContext({
    request_id: config.headers['gateway-request-id'],
  });
  return config;
});
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const oktaTokenExpiresAt = Cookie.getOktaTokenExpiresAt();
        const oktaSessionExpiresAt = Cookie.getOktaSessionExpirationTime();
        const gatewaySessionExpiresAt = Cookie.getGatewaySessionExpiresAt();
        const timeNow = new Date().getTime();

        Honeybadger.notify('401 Error', {
          name: '401 unauthorized error logout',
          tags: 'gateway-ui, 401, logout, sessions',
          context: {
            url: window.location.href,
            current_time: new Date().toString() + ', milliseconds: ' + timeNow,
            gateway_session_expires_at: String(gatewaySessionExpiresAt),
            okta_session_expires_at: String(oktaSessionExpiresAt),
            okta_token_session_expires_at: String(oktaTokenExpiresAt),
            minutes_prior_gateway_session_end: Number((Number(gatewaySessionExpiresAt) - timeNow) / 1000 / 60).toFixed(
              2,
            ),
            minutes_prior_okta_session_end: Number((Number(oktaSessionExpiresAt) - timeNow) / 1000 / 60).toFixed(2),
            minutes_prior_okta_token_session_end: Number((Number(oktaTokenExpiresAt) - timeNow) / 1000 / 60).toFixed(2),
          },
        });
        if (error.response?.config?.url === '/auth/logout') {
          return Promise.reject(error);
        }
        if (error.response.config.url === '/auth' && error.response.data.status === StatusConstants.MFA_ENROLL) {
          /** Resolve the promise since the user needs to MFA_ENROLL */
          return Promise.resolve(error.response);
        } else if (error.response.data.status === StatusConstants.INVALID_SESSION) {
          Cookie.setStatus(StatusConstants.INVALID_SESSION);
          console.info('401 unauthorized');
          return Utils.navigateToLogin();
        } else {
          if (document.location.pathname !== '/auth/login') {
            console.info('401 unauthorized');
            return Utils.navigateToLogin();
          }
        }
      }
    }
    // Do something with response error
    return Promise.reject(error);
  },
);

export {
  Cookie,
  EnrollmentAPI,
  AuthenticationAPI,
  GatewayManagementAPI,
  SettingAPI,
  CommonLinksAPI,
  CalendarAPI,
  BoxAPI,
  BlogPostsAPI,
  ThirdPartyIntegrationsAPI,
  SalesForcePortfolioAPI,
  GatewayUsersAPI,
  RecoveryQuestionAPI,
  QuickSightAPI,
  AdvisoryTeamsAPI,
};
