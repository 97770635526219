const HidedColumns = Object.freeze(['table', 'ocr_link']);

enum DocumentType {
  'alkymi.capital-statement' = 'Statement',
  'alkymi.capital-notice' = 'Notice',
  'nanonets.k1' = 'K1',
  'unknown' = 'unknown',
}

enum VendorsType {
  'alkymi' = 'alkymi',
  'nanonets' = 'nanonets',
  'unknown' = 'unknown',
}

enum AddJobDocumentTypes {
  'alkymi.auto' = 'auto',
  'alkymi.capital-statement' = 'alkymi.capital-statement',
  'alkymi.capital-notice' = 'alkymi.capital-notice',
  'nanonets.k1' = 'nanonets.k1',
}

const DocumentTypeObj = {
  'alkymi.capital-statement': 'Statement',
  'alkymi.capital-notice': 'Notice',
  'nanonets.k1': 'K1',
};

enum SystemColumns {
  document_type = 'document_type',
  documents = 'documents',
  status = 'status',
}

enum FieldTypes {
  subfield = 'subfield',
  newfield = 'new-field',
  correctlypredicted = 'correctly_predicted',
  fieldremoved = 'field-removed',
  formula = 'formula',
}

const requiredFields = {
  Statement: ['investment', 'investor', 'period_end_date'],
  Notice: ['investment', 'investor', 'due_date'],
  K1: ['investment', 'investor', 'tax_year'],
  all: ['investment', 'investor', 'period_end_date', 'due_date'],
};

// document_type shall always be on the 1st place, all the rest may vary

const defaultColumnsOrder = [
  'document_type',
  'account',
  'custodian',
  'investment',
  'document_date',
  'beginning_balance',
  'ending_balance',
  'due_date',
];

const defaultColumnsWidth = {
  documents: 200,
  status: 250,
  document_type: 160,
  account: 200,
  custodian: 200,
  investment: 200,
  document_date: 200,
  beginning_balance: 200,
  ending_balance: 200,
  due_date: 200,
};

enum DetailsRequestData {
  document = 'document',
  calculator = 'calculator',
}

export {
  HidedColumns,
  DocumentType,
  AddJobDocumentTypes,
  DocumentTypeObj,
  SystemColumns,
  FieldTypes,
  defaultColumnsOrder,
  defaultColumnsWidth,
  requiredFields,
  VendorsType,
  DetailsRequestData,
};
