import { styled } from '@mui/material/styles';
import alpha from 'color-alpha';

interface IParentDiv {
  colors: {
    readonly primary: string;
    readonly secondary: string;
  };
}

export const StyledShadowUserOverlay = styled('div')<IParentDiv>`
  .border {
    transition: all 3s;
    background-color: ${(props) => props.colors.primary};
    backdrop-filter: blur(10px);
    position: absolute;
    z-index: 10000;
  }
  .transparent {
    background-color: transparent;
  }
  .top {
    top: 0;
    width: 100%;
    height: 12px;
  }
  .right {
    top: 12px;
    right: 0;
    height: calc(100% - 24px);
    width: 12px;
  }
  .bottom {
    bottom: 0;
    width: 100%;
    height: 12px;
  }
  .left {
    bottom: 12px;
    left: 0;
    height: calc(100% - 24px);
    width: 12px;
  }
  .shadow-modal-info {
    will-change: transform, opacity, left, top, right, bottom;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    display: flex;
    font-size: 12px;
    z-index: 10001;
    position: fixed;
    right: 20px;
    bottom: 20px;
    border-radius: 7px;
    width: fit-content;
    height: fit-content;
    backdrop-filter: blur(12px);
    background-color: ${(props) => alpha(props.colors.primary, 0.5)};
    box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.1);
    transition: all 0.1s;
    padding: 10px 10px 10px 3px;
    transform-origin: bottom right;
    @media screen and (max-width: 400px) {
      font-size: 8px;
    }
    .drag-icon-wrapper {
      display: flex;
      align-items: center;
      margin: 0 3px 0 0;
      .drag-icon {
        color: ${(props) => props.colors.secondary};
        height: 70px;
        width: 25px;
      }
      .grab {
        &:hover {
          cursor: grab;
        }
      }
      .grabbing {
        &:hover {
          cursor: grabbing;
        }
      }
    }
    .row-wrapper {
      display: flex;
      flex-direction: column;
      .modal-row {
        display: flex;
        margin: 0px;
        .MuiSwitch-switchBase.Mui-checked {
          color: rgba(0, 50, 255, 0.7);
        }
        .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
          background-color: rgba(0, 50, 255, 0.35);
        }
        .left-part {
          width: 105px;
          margin-right: 5px;
          @media screen and (max-width: 400px) {
            width: 70px;
          }
        }
        .right-part {
          max-width: 170px;
        }
        .text {
          user-select: none;
          padding: 1px 3px;
          font-family: Neuzeit-Grotesk, sans-serif;
          font-weight: bold;
          font-size: 12px;
          color: white;
          line-height: 16px;
          @media screen and (max-width: 400px) {
            font-size: 8px;
          }
        }
        .drop-down {
          display: flex;
          align-items: center;
          border-radius: 5px;
          &:hover {
            cursor: pointer;
            background-color: ${(props) => alpha(props.colors.secondary, 0.3)};
          }
        }
        .arrow-icon {
          transition: all 0.2s;
          color: white;
          height: 18px;
        }
        .colored {
          color: ${(props) => props.colors.secondary};
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0 0 0;
        .turn-off-btn {
          transition: all 0.3s;
          font-size: 9px;
          color: white;
          font-family: Neuzeit-Grotesk, sans-serif;
          font-weight: bold;
          height: 20px;
          border-radius: 20px;
          border-color: ${(props) => props.colors.secondary};
          background-color: ${(props) => alpha(props.colors.secondary, 0.3)};
          &:hover {
            box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.5);
          }
          .circular-progress {
            color: white;
          }
        }
      }
    }
  }
  .hover {
    &:hover {
      transform: scale(1.25);
      background-color: ${(props) => props.colors.primary};
    }
  }
`;
