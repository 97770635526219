import axios, { AxiosError, AxiosInstance } from 'axios';
import _ from 'lodash';
import { encode } from 'base64-arraybuffer';

import { Cookie } from '../../../services';
import { convertBase64ToUrl, getAndLogAxiosError, getMidasTypesForRequest } from '../helpers';
import {
  IFolder,
  IJobInfo,
  ITableCellsStructuredData,
  IPutDeleteCellData,
  JobServerInfo,
  ReliDocumentType,
  ITableCellsData2,
  MappedReliErrorInterface,
  MessageTypes,
  IDispatchSetToastify,
  ISocketResponse,
  IGetJobResponseData,
  SFvalidationResponse,
} from '../../../constants/Types';
import {
  DataType2,
  Errors,
  FileTypes,
  Headers,
  ReliHostname,
  ReliMidasHostname,
  MidasFieldCalcType,
  BULK_REQUEST_TO_PROXY_CHUNK_LENGTH,
} from '../constants';
import { ITimingStats } from '../hooks/useTimingStats';
import { spliceArrayIntoChunks } from '../../../utils';
import config from '../../../config';

export const getAxiosInstance = () => {
  const oktaToken = Cookie.getOktaToken();
  const accessToken = Cookie.getAccessToken();
  if (!oktaToken || !accessToken) {
    throw new Error(Errors.NOT_AUTHORIZED);
  }
  return axios.create({
    ...(config.IS_MOCK_SERVER_ON === 'true' && {
      baseURL: config.MOCK_SERVER_URL,
    }),
    headers: {
      Accept: 'application/json',
      [Headers.AuthHeaders.OktaToken]: oktaToken,
      [Headers.AuthHeaders.AccessToken]: accessToken,
      [Headers.DebugHeaders.UniqueSessionId]: Cookie.getUniqueSessionId(),
      [Headers.ReliHeaders.Hostname]: ReliHostname.Prod,
      [Headers.ReliHeaders.MidasHostname]: ReliMidasHostname.Prod,
      [Headers.ReliHeaders.RELIApiVersion]: 'v4',
      ...(config.IS_MOCK_SERVER_ON === 'true' && {
        'x-api-key': config.MOCK_SERVER_API_KEY,
      }),
    },
    timeout: 900000,
  });
};

export const addNewJob = async (data: FormData): Promise<{ success: boolean; job?: string; error?: string }> => {
  try {
    const res = await getAxiosInstance().post('/reli/v4/jobs', data, {
      headers: { 'x-mirador-reli-convert-to-form-data': true },
    });
    return { success: true, job: res.data.data.job };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.AddNewJob)
        : String(Errors.Reli.AddNewJob),
    };
  }
};

export const addFileToJob = async (data: FormData, jobId: string): Promise<{ success: boolean; error?: string }> => {
  try {
    await getAxiosInstance().put(`/reli/v4/job/${jobId}`, data, {
      headers: { 'x-mirador-reli-convert-to-form-data': true },
    });
    return { success: true };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.AddFileToJob)
        : String(Errors.Reli.AddFileToJob),
    };
  }
};

export const getJobs = async (
  isWorkForUserLevel: boolean,
): Promise<{ success: boolean; data?: JobServerInfo[]; error?: string }> => {
  try {
    const res = await getAxiosInstance().get(`/reli/v4/jobs?delegates=${isWorkForUserLevel}`);
    return { success: true, data: res.data.data.jobs ? res.data.data.jobs : [] };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, String(Errors.Reli.GetJobs))
        : String(Errors.Reli.GetJobs),
    };
  }
};

export const getJob = async (jobId: string): Promise<{ success: boolean; data?: IJobInfo; error?: string }> => {
  try {
    const res = await getAxiosInstance().get(`/reli/v4/job/${jobId}`);
    const jobData = res.data.data as IGetJobResponseData;
    const jobDataFilesArray = Array.isArray(jobData.files) ? jobData.files : [jobData.files];
    const jobDataReversedFiles = _.cloneDeep(jobDataFilesArray).reverse();

    return {
      success: true,
      data: {
        id: jobId,
        client: jobData.client,
        jobName: jobData.description,
        files: { file: jobDataReversedFiles },
      },
    };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e) ? getAndLogAxiosError(e?.response, Errors.Reli.GetJob) : String(Errors.Reli.GetJob),
    };
  }
};

interface IReliError extends AxiosError {
  error: MappedReliErrorInterface;
}

export const bulkRequestToProxy = async (
  ids: string[],
  fromCache: boolean,
  setToastifySettings: IDispatchSetToastify,
  currentJobId: string,
  filesBatchCount?: number,
): Promise<{
  success: boolean;
  cachedFiles?: ISocketResponse[];
  notCachedIds?: string[];
  numberOfFailedDocs?: number;
}> => {
  let numberOfFailedDocs = 0;

  if (fromCache) {
    try {
      const res: {
        status: number;
        data: {
          fromCache: boolean;
          cachedFiles: ISocketResponse[];
          notCachedIds: string[];
          success: boolean;
        };
      } = await getAxiosInstance().post(
        `/reli/socket/file/bulk?fromCache=${true}`,
        { ids, jobId: currentJobId },
        {
          headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
        },
      );

      return { success: true, notCachedIds: res.data.notCachedIds, cachedFiles: res.data.cachedFiles };
    } catch (error) {}
  } else if (filesBatchCount) {
    const idsChunks: string[][] = spliceArrayIntoChunks(ids, BULK_REQUEST_TO_PROXY_CHUNK_LENGTH);
    const types = getMidasTypesForRequest();
    for await (const chunk of idsChunks) {
      await getAxiosInstance()
        .post(
          `/reli/socket/file/bulk?view_type=document&field_type=${types}`,
          { ids: chunk, filesBatchCount, jobId: currentJobId },
          {
            headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
          },
        )
        .catch((e: IReliError) => {
          const errorStatus = (e as any).response?.data?.errors[0].error.error;
          const errorsArray: string[] = (e as any).response?.data?.errors.map((el: { fileId: string }) => el.fileId);
          const errorMsg = errorsArray?.join(', ');
          numberOfFailedDocs += (e as any).response?.data?.errors.length;
          setToastifySettings({
            type: MessageTypes.Error,
            msg: `${errorStatus} for ${errorsArray?.length} documents in your job. Documents ids are: ${errorMsg}`,
          });
        });
    }
  }

  return { success: false };
};

export const getDocumentData2 = async (
  doc: ITableCellsData2 | ITableCellsStructuredData,
): Promise<{ success: boolean; data?: ITableCellsData2; error?: string }> => {
  try {
    const res = await getAxiosInstance().get(`/reli/v4/file/${doc.id}/normalized/data?field_type=datum,formula`);
    const ui_fields = res.data.data.normalized_data;

    return {
      success: true,
      data: {
        id: doc.id,
        name: doc.name,
        state: doc.state,
        type: doc.type,
        hash: doc.hash,
        fields: ui_fields.data ? ui_fields.data : [],
      },
    };
  } catch (e) {
    const errorMessage = `An error was encountered while retrieving ${doc.name} data. Document id: ${doc.id}`;
    throw {
      error: axios.isAxiosError(e) ? getAndLogAxiosError(e?.response, errorMessage) : errorMessage,
    };
  }
};

export const postApprovedDoc2 = async (
  documentId: string,
  docName: string,
): Promise<{ success: boolean; error?: string }> => {
  const data = {
    config: {
      manual: {},
    },
  };
  try {
    await getAxiosInstance().put(`/reli/v4/file/${documentId}/status`, data);
    return { success: true };
  } catch (e) {
    const errorMessage = `${Errors.Reli.ApproveDocument}: ${docName}, with id: ${documentId}`;
    throw {
      error: axios.isAxiosError(e) ? getAndLogAxiosError(e?.response, errorMessage) : errorMessage,
    };
  }
};

export const postRejectedDoc2 = async (
  documentId: string,
  docName: string,
): Promise<{ success: boolean; error?: string }> => {
  try {
    await getAxiosInstance().delete(`/reli/v4/file/${documentId}/status`);
    return { success: true };
  } catch (e) {
    const errormessage = `${Errors.Reli.RejectDocument}: ${docName}, with id: ${documentId}`;
    throw {
      error: axios.isAxiosError(e) ? getAndLogAxiosError(e?.response, errormessage) : errormessage,
    };
  }
};

export const postPartialSavedDoc2 = async (
  documentId: string,
  data: IPutDeleteCellData,
): Promise<{ success: boolean; error?: string; data?: IPutDeleteCellData[] }> => {
  try {
    const res = await getAxiosInstance().put(`/reli/v4/file/${documentId}/normalized/data`, data, {
      headers: {
        [Headers.ReliHeaders.ConvertToXml]: 'false',
      },
    });

    const resData: IPutDeleteCellData[] = res.data.data.normalized_data.data;
    const resArray = Object.entries(resData).filter((el) => el[0] !== 'author') as unknown as IPutDeleteCellData[];
    const objectArray = resArray.reduce((acc, cur) => {
      const newEl = { [cur[0] as unknown as string]: cur[1] };
      return [...acc, newEl];
    }, [] as IPutDeleteCellData[]);

    return {
      success: true,
      data: objectArray,
    };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.SaveDocument)
        : String(Errors.Reli.SaveDocument),
    };
  }
};

export const postRerouteDocument = async (
  documentId: string,
  data: {
    config: ReliDocumentType;
  },
): Promise<{ success: boolean; error?: string }> => {
  try {
    const res = await getAxiosInstance().put(`/reli/v4/file/${documentId}/scan/route`, data);

    return { success: true };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.RerouteDocument)
        : String(Errors.Reli.RerouteDocument),
    };
  }
};

export const getPdfFile = async (
  documentId: string,
  abortSignal?: AbortSignal,
): Promise<{ success: boolean; data?: string; error?: string; isCanceled?: boolean }> => {
  try {
    const res = await getAxiosInstance().get(`/reli/v4/file/${documentId}`, {
      ...(abortSignal && { signal: abortSignal }),
      responseType: 'json',
      headers: {
        [Headers.ReliHeaders.ResponseType]: 'arraybuffer',
      },
    });
    const arrayOfBytes = res.data.data.data ?? [];
    const base64 = encode(arrayOfBytes);
    const base64String = convertBase64ToUrl(base64);
    return { success: true, data: base64String };
  } catch (e) {
    if (axios.isCancel(e)) {
      return { success: false, isCanceled: true };
    }
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.GetPdfFile)
        : String(Errors.Reli.GetPdfFile),
    };
  }
};

export const getExcelFile = async (
  jobId: string,
  type: string,
): Promise<{ success: boolean; data?: string; error?: string }> => {
  try {
    const res = await getAxiosInstance().get(`/reli/v4/job/${jobId}/export/xl?type=${type}`, {
      responseType: 'json',
      headers: {
        Accept: 'text/plain',
        [Headers.ReliHeaders.ResponseType]: 'arraybuffer',
      },
    });
    const arrayOfBytes = res.data.data.data ?? [];
    const base64 = encode(arrayOfBytes);
    const downloadUrl = convertBase64ToUrl(base64, FileTypes.EXCEL);
    return { success: true, data: downloadUrl };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.GetExcelFile)
        : String(Errors.Reli.GetExcelFile),
    };
  }
};

export const getSharefileFunctions = async (): Promise<{ success: boolean; data?: any; error?: string }> => {
  try {
    const res = await getAxiosInstance().get('/reli/v4/sharefile/functions');

    return { success: true };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.GetSharefileFunctions)
        : Errors.Reli.GetSharefileFunctions,
    };
  }
};

export const getSubFolders = async (
  sharefileAxios: AxiosInstance,
  folder: string,
  refreshToken: () => void,
): Promise<IFolder[] | null> => {
  try {
    const sfResponse = await sharefileAxios?.get(
      `/Items(${folder})/Children?$select=Id,Name,Info,Info/IsAHomeFolder,Info/CanAddFolder&$expand=Info`,
      {
        validateStatus: () => true,
      },
    );
    if (sfResponse?.status === 401 && refreshToken) {
      await refreshToken();
      return getSubFolders(sharefileAxios, folder, refreshToken);
    }
    if (sfResponse && sfResponse.data && sfResponse.data.value) {
      return (sfResponse.data.value as IFolder[])
        .filter((item) => Boolean(item?.Info?.CanAddFolder))
        .sort((firstItem, secondItem) => firstItem.Name.localeCompare(secondItem.Name));
    }
    return null;
  } catch (e) {
    console.info('getSubfolders', e);
    return null;
  }
};

export const postDocsToShareFile = async (
  params: {
    directory_guid: string;
    expression: string;
    debug: boolean;
    sharefile_apicp: string;
    sharefile_domain: string;
    access_token: string;
  },
  jobId: string,
): Promise<SFvalidationResponse> => {
  const baseError = params.debug ? Errors.ShareFile.ValidatePathError : Errors.Reli.SaveToShareFile;
  try {
    const res = await getAxiosInstance().post(`/reli/v4/job/${jobId}/sharefile`, params);
    return { success: true, data: res.data?.data };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e) ? getAndLogAxiosError(e?.response, baseError) : baseError,
    };
  }
};

export const getAllFieldsFromMidas = async (): Promise<{
  success: boolean;
  data?: { id: string; 'field-type': keyof typeof DataType2; 'calc-type': MidasFieldCalcType; description: string }[];
  error?: string;
}> => {
  try {
    const types = getMidasTypesForRequest();
    const res = await getAxiosInstance().get(`/reli/v4/midas/definitions?field_type=${types}`);
    return { success: true, data: res.data.data.definitions };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.GetAllFieldsFromMidas)
        : String(Errors.Reli.GetAllFieldsFromMidas),
    };
  }
};

export const postTimingStats = async (stats: ITimingStats): Promise<{ success: boolean; error?: string }> => {
  try {
    await getAxiosInstance().post(`/reli/ui/logs`, stats);
    return { success: true };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e),
    };
  }
};

export const postBugReport = async (data: FormData): Promise<{ success: boolean; error?: string }> => {
  try {
    await getAxiosInstance().post('/reli/v4/bug?dataType=bugreport', data, {
      headers: { 'x-mirador-reli-convert-to-form-data': true },
    });
    return { success: true };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.SendBugReport)
        : String(Errors.Reli.SendBugReport),
    };
  }
};

export const getFieldsDetails = async (
  documentId: string,
  queryParams?: string,
  fieldTypeQueryParams?: boolean,
): Promise<{ success: boolean; data: any; error?: string }> => {
  try {
    const types = getMidasTypesForRequest();
    const res = await getAxiosInstance().get(
      fieldTypeQueryParams
        ? `/reli/v4/file/${documentId}/detail?field_type=${types}`
        : `/reli/v4/file/${documentId}/detail${queryParams ? `?view_type=${queryParams}` : ''}`,
    );
    return { success: true, data: res.data.data.detail };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.GetDocumentDetails)
        : String(Errors.Reli.GetDocumentDetails),
    };
  }
};

export const editFields = async (
  documentId: string,
  data: any,
  queryParams?: string,
): Promise<{ success: boolean; error?: string }> => {
  try {
    await getAxiosInstance().patch(
      `/reli/v4/file/${documentId}/detail${queryParams ? `?view_type=${queryParams}` : ''}`,
      data,
    );
    return { success: true };
  } catch (e) {
    throw {
      error: axios.isAxiosError(e)
        ? getAndLogAxiosError(e?.response, Errors.Reli.EditDocumentFields)
        : String(Errors.Reli.EditDocumentFields),
    };
  }
};
