export enum ServiceRequestsEnum {
  GenericServiceRequest = 'Generic Service Request',
}

export enum UserRolesEnum {
  GatewayAdmin = 'Gateway Admin',
  MiradorDeveloper = 'Mirador Developer',
}

export enum CardDisplayModeEnum {
  grid = 'grid',
  details = 'details',
}

export enum InstanceTypeEnum {
  dev = 'dev',
  qa = 'qa',
  prod_internal = 'prod_internal',
  prod_client = 'prod_client',
}

export enum SettingsModuleTypeEnum {
  currentDashboardModule = 'currentDashboardModule',
  currentMenuItemsModule = 'currentMenuItemsModule',
  currentRequestsModule = 'currentRequestsModule',
  currentQuicksightsModule = 'currentQuicksightsModule',
  currentFooterModule = 'currentFooterModule',
}

export enum RoutesEnum {
  dashboard = '/dashboard',
  insights = '/insights',
  documents = '/documents',
  tools = '/tools',
  toolsApps = '/tools#apps',
  toolsLinks = '/tools#links',
  spaces = '/spaces',
  request = '/request',
  calendar = '/calendar',
  team = '/team',
  reli = '/reli',
  altsDashboard = '/alts-dashboard',
}
