type Params = [key: string, value: unknown][];

export const buildQueryParams = (params: Params): URLSearchParams => {
  const pars = new URLSearchParams();

  params.forEach(([key, val]) => {
    pars.append(key, String(val));
  });

  return pars;
};
