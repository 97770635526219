import { FieldNode } from './FieldNode';
import { FieldNodeElement } from '../constants';
import { DoubleLinkedList } from './DoubleLinkedList';

export class CircularDoubleLinkedList extends DoubleLinkedList {
  push(element: any) {
    const node = this.createNode(element);

    if (this.head === null) {
      this.head = node;
    } else {
      const current = this.getNodeAt(this.size - 1) as FieldNode<FieldNodeElement>;
      current.next = node;
      node.prev = current;
    }

    this.tail = node;
    this.head.prev = this.tail;
    this.tail.next = this.head;

    this.size += 1;
    return this.size;
  }

  addNodeBelow(nodeIndex: number) {
    const node = this.getNodeAt(nodeIndex) as FieldNode<FieldNodeElement>;
    node.below = this.tail;
  }
}
