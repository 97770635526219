import { FieldNode } from './FieldNode';
import { FieldNodeElement } from '../constants';
import { LinkedList } from './LinkedList';

export class DoubleLinkedList extends LinkedList {
  tail: any = null;

  createNode(element: FieldNodeElement) {
    return new FieldNode({ ...element });
  }
}
