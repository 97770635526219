export const excelOptions = Object.freeze([
  {
    name: 'Statement',
    value: 'addepar.cas',
  },
  {
    name: 'Notice',
    value: 'addepar.cdn',
  },
]);
