import axios from 'axios';

export const getFailedApiCallResponse = (e: any) => {
  if (axios.isAxiosError(e)) {
    const errorResponse = e?.response?.data?.status || e?.response?.data?.message;
    const errorMessage = errorResponse ? errorResponse : e;
    return { success: false, error: String(errorMessage) };
  }
  return { success: false, error: String(e) };
};

export const isUrlException = (url: string) => {
  const exceptionsArr = ['/gateway/box/'];

  return Boolean(exceptionsArr.filter((el) => url.includes(el)).length);
};

export const getFailedBoxApiCallResponse = (
  e: any,
): {
  success: boolean;
  error: string;
  isEmailConfirmNeeded?: boolean;
} => {
  if (axios.isAxiosError(e)) {
    const errorResponse = e?.response?.data?.message;
    const errorMessage = errorResponse ? errorResponse : e;
    const isEmailConfirmNeeded = Boolean(e.response?.data?.message?.includes('email_confirmation_needed'));

    return { success: false, error: String(errorMessage), isEmailConfirmNeeded };
  }
  return { success: false, error: String(e) };
};
