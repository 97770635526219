enum ShareFile {
  FilesSaved = 'Files saved successfully',
  SelectFolderAlert = 'Please select a folder to save (it can not be root folder) and enter all required fields!',
  TooMuchSubFolders = 'You can not add more than 5 sub folders',
}

enum Reli {
  SavingDocumentsWithError = 'Please note that documents with errors could not be approved or rejected. ',
  SuccessfullySaved = 'Documents saved',
  SuccessfullyReclassified = 'Documents reclassified successfully',
  DataResetOnPageChange = 'You have unsaved changes. Are you sure you want to leave this page? All unsaved changes will be lost.',
  FilesSuccessfullyUploaded = 'Files were successfully uploaded',
  FilesSizeExceeded = 'Total size of files must be less than',
  FilesSuccessfullyAdded = 'Files were successfully added.',
  DuplicatesWereIgnored = 'Duplicate files were ignored: ',
  EncryptedWereIgnored = 'Encrypted files were ignored: ',
  DragAndDropFiles = 'Drag and Drop pdf files here',
  JobSuccessfullyCreated = 'Job was successfully created',
  DebugMessageBaseText = 'Your support organization will find this information useful. Please forward it to them: ',
  UploadingFiles = 'Uploading files...',
  EmptyValue = 'Value cannot be empty',
  ValueNotChanged = 'Value has not been changed',
  FetchingData = 'Fetching data...',
}

export { ShareFile, Reli };
