import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { TimingStatsEvents } from '../components/Reli/constants/TimingStats';
import { reliCurrentJobActions } from './reli-current-job-slice';

interface IInitialState {
  isLoading: boolean;
  isEditedJobSaved: boolean;
  isDocsHydratationCompleted: boolean;
  dialogText: string;
  statsAction: TimingStatsEvents;
  exportableJobsArray: { name: string; id: string; isExportable: boolean }[];
}

const initialState: IInitialState = {
  isLoading: false,
  isEditedJobSaved: false,
  isDocsHydratationCompleted: false,
  dialogText: '',
  statsAction: TimingStatsEvents.OpenJob,
  exportableJobsArray: [],
};

export const setEditedJobSavedAsync = createAsyncThunk('counter/incrementAsync', async () => {
  return new Promise<{ data: boolean }>((resolve) => {
    setTimeout(() => {
      resolve({ data: false });
    }, 100);
  });
});

export const auxiliaryFlagsSlice = createSlice({
  name: 'reli-auxiliary-flags',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDialogText(state, action) {
      state.dialogText = action.payload;
    },
    setIsDocsHydratationCompleted(state, action) {
      state.isDocsHydratationCompleted = action.payload;
    },
    setEditedJobSaved(state, action) {
      state.isEditedJobSaved = action.payload;
    },
    setStatsAction(state, action) {
      state.statsAction = action.payload;
    },
    setExportableArray(state, action) {
      state.exportableJobsArray = action.payload;
    },
    addToExportableArray(state, action) {
      const idx = state.exportableJobsArray.findIndex((job) => job.id === action.payload.id);
      if (idx === -1) {
        state.exportableJobsArray.push(action.payload);
      } else {
        state.exportableJobsArray.splice(idx, 1, { ...state.exportableJobsArray[idx], ...action.payload });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reliCurrentJobActions.resetTableData, (state) => {
      state.isEditedJobSaved = true;
    });
    builder.addCase(setEditedJobSavedAsync.fulfilled, (state, action) => {
      state.isEditedJobSaved = action.payload.data;
    });
  },
});

export const reliAuxiliaryFlagsActions = auxiliaryFlagsSlice.actions;

export default auxiliaryFlagsSlice.reducer;
