export enum MfaTableKeysEnum {
  number = 'number',
  mfaMethods = 'mfa_methods',
  details = 'Details',
  edit = 'edit',
  delete = 'delete',
  provider = 'provider',
  status = 'status',
  save = 'save',
}

interface IMfaLink {
  hints: {
    allow: string[];
  };
  href: string;
}

export enum MfaStatusEnum {
  active = 'ACTIVE',
  pendingActivation = 'PENDING_ACTIVATION',
  notSetup = 'NOT_SETUP',
}

export enum MfaOperationsEnum {
  update = 'UPDATE',
  saveNew = 'SAVE',
  pendingActivation = 'PENDING_ACTIVATION',
  notAllowed = 'NOT ALLOWED',
}

export enum MfaButtonTextEnum {
  apply = 'apply',
  update = 'update',
  activate = 'activate',
}

export enum MfaMethodsEnum {
  call = 'call',
  sms = 'sms',
  email = 'email',
  push = 'push',
  token = 'token:software:totp',
}

export enum MfaProvidersEnum {
  google = 'GOOGLE',
  okta = 'OKTA',
}

export enum MfaAuthApps {
  googleAuthenticator = 'Google Authenticator',
  oktaVerify = 'Okta Verify',
}

export enum MfaEnrollmentEnum {
  required = 'REQUIRED',
  optional = 'OPTIONAL',
}

export interface IAssignedMfas {
  created: string;
  factorType: MfaMethodsEnum;
  id: string;
  lastUpdated: string;
  profile: Record<string, unknown>;
  provider: MfaProvidersEnum;
  status: MfaStatusEnum;
  vendorName: string;
  _links: { self: IMfaLink; user: IMfaLink; verify: IMfaLink };
}

export interface IMfasState {
  factorType: MfaMethodsEnum | '';
  provider: string;
  profile: Record<string, unknown>;
  isUnderEdit: boolean;
  isNew: boolean;
  status: MfaStatusEnum;
  id: string;
}

export interface IMfaTableColumns {
  title: string;
  key: MfaTableKeysEnum;
  isSmallCell: boolean;
}

export interface ISupportedMfaMethods {
  enrollment: MfaEnrollmentEnum;
  factorType: MfaMethodsEnum;
  provider: MfaProvidersEnum;
  status: string;
  vendorName: string;
  _links: { enroll: IMfaLink };
}

type ApiCallMethods = 'POST' | 'GET' | 'PATCH' | 'DELETE' | 'OPTIONS';

export interface ISaveMfaOktaResponse {
  factorType: MfaMethodsEnum;
  id: string;
  profile: Record<string, unknown>;
  provider: string;
  status: MfaStatusEnum;
  success: true;
  vendorName: string;
  _embedded?: {
    activation: {
      expiresAt: string;
      factorResult: string;
      _links: {
        qrcode: {
          href: string;
          type: string;
        };
        send: {
          hints: { allow: ApiCallMethods[] };
          href: string;
          name: string; // 'email' || 'sms'
        }[];
      };
    };
  };
}

export interface ISaveMfaPayload {
  factorType: MfaMethodsEnum | '';
  provider: string;
  profile?: Record<string, unknown>;
}

export interface IVerifyCodePayload {
  factorId: string;
  passCode: string;
}
