import axios from 'axios';
import _ from 'lodash';

import { Cookie } from '..';
import Config from '../../config';
import { getFailedApiCallResponse } from '../utils';

const { GATEWAY_ID } = Config;

export const changePassword = async (oldPassword: string, newPassword: string, userId: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const data = { oldPassword, newPassword, userId };
    const res = await axios.post('/auth/change-password', data, {
      headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID },
    });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    console.info('e changePassword', e);
  }
  return { success: false };
};
