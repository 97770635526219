import React, { useEffect, PropsWithChildren } from 'react';
import { io, Socket } from 'socket.io-client';
import Config from '../config';
import { Cookie } from '../services';

export type SocketContextType = {
  socket: Socket | null;
};

export const SocketContext = React.createContext<SocketContextType>({
  socket: null,
});

export enum SocketEvents {
  ReliFileRefresh = 'reli-file-refresh',
  ReliGetFileData = 'reli-file-information',
  OwnRoleRefresh = 'own-role-refresh',
}

export enum SocketResponseMode {
  fetchBD = 'fetch from DB',
  fetchCash = 'fetch from cash',
}

export enum SocketSessionStorageKeys {
  ReliGetDataSocket = 'isSubscribedToGetSocketEvent',
  ReliRefreshDataSocket = 'isSubscribedToRefreshSocketEvent',
}

class GlobalSocketIo {
  private static instance: GlobalSocketIo;
  private socket: Socket | null = null;
  static getInstance() {
    if (!GlobalSocketIo.instance) {
      GlobalSocketIo.instance = new GlobalSocketIo();
    }
    return GlobalSocketIo.instance;
  }
  getSocket() {
    return this.socket;
  }
  connectSocket() {
    const { GATSBY_API_BASE_URL } = Config;
    const accessToken = Cookie.getAccessToken();
    if (!this.socket && GATSBY_API_BASE_URL && accessToken) {
      this.socket = io(GATSBY_API_BASE_URL, {
        transports: ['websocket'],
        path: '/socket.io/',
        auth: {
          'x-access-token': accessToken,
        },
      });
    }
    return this.socket;
  }
}

export const globalSocketIo = GlobalSocketIo.getInstance();

export const SocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [socket, setSocket] = React.useState<Socket | null>(null);

  useEffect(() => {
    globalSocketIo.connectSocket();
    const socketIo = globalSocketIo.getSocket();
    setSocket(socketIo);
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
