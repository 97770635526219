import { FieldNode } from './FieldNode';
import { FieldNodeElement } from '../constants';

export class LinkedList {
  size = 0;
  head: FieldNode<FieldNodeElement> | null = null;

  get isEmpty() {
    return this.size === 0;
  }

  createNode(element: { column: string; id: string; state: string }) {
    return new FieldNode({ ...element });
  }

  getNodeAt(index: number) {
    if (index < 0 || index >= this.size) return null;

    if (index === 0) return this.head;

    let current = this.head;

    for (let i = 0; i < index; i++) {
      if (current) {
        current = current.next;
      }
    }

    return current;
  }

  get(index: number) {
    const node = this.getNodeAt(index);

    return node ? node.element : null;
  }

  indexOf(element: any) {
    let current = this.head;

    if (!current || !element) {
      return -1;
    }

    if (current.element?.id === element?.id) return 0;

    for (let i = 0; i < this.size; i++) {
      if (current?.element?.id === element?.id) return i;
      if (current) {
        current = current.next;
      }
    }

    return -1;
  }

  print() {
    const arr = [];

    if (this.size) {
      let current = this.head;

      for (let i = 0; i < this.size; i++) {
        arr.push(current);
        if (current) {
          current = current.next;
        }
      }
    }

    console.log(arr);
  }
}
