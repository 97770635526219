import Cookies from 'js-cookie';
import { CookieConstants } from '../../constants';

class Cookie {
  setStateToken = (stateToken: string) => {
    Cookies.set(CookieConstants.StateToken, stateToken, { expires: 7, sameSite: 'Strict' });
  };

  setOktaToken = (oktaToken: string) => {
    Cookies.set(CookieConstants.OktaToken, oktaToken, { expires: 7, sameSite: 'Strict' });
  };

  setOktaTokenExpiresAt = (expiresAt: string) => {
    Cookies.set(CookieConstants.OktaTokenExpiresAt, expiresAt, { expires: 7, sameSite: 'Strict' });
  };

  setStatus = (status: string) => {
    Cookies.set(CookieConstants.Status, status, { expires: 7, sameSite: 'Strict' });
  };

  setFactorId = (factorId: string) => {
    Cookies.set(CookieConstants.FactorId, factorId, { expires: 7, sameSite: 'Strict' });
  };

  setSessionToken = (sessionToken: string) => {
    Cookies.set(CookieConstants.SessionToken, sessionToken, { expires: 7, sameSite: 'Strict' });
  };

  setAccessToken = (accessToken: string) => {
    Cookies.set(CookieConstants.AccessToken, accessToken, { expires: 7, sameSite: 'Strict' });
  };

  setTempMsg = (message: string) => {
    Cookies.set(CookieConstants.TempMessage, message, { sameSite: 'Strict' });
  };

  setUserId = (userId: string) => {
    Cookies.set(CookieConstants.UserId, userId, { sameSite: 'Strict' });
  };

  setRoleIDs = (ids: string[]) => {
    Cookies.set(CookieConstants.RoleIDs, JSON.stringify(ids), { sameSite: 'Strict' });
  };

  setDeviceToken = (deviceToken: string) => {
    Cookies.set(CookieConstants.DeviceToken, deviceToken, { expires: 7, sameSite: 'Strict' });
  };

  setAuthorizationCode = (authorizationCode: string) => {
    Cookies.set(CookieConstants.AuthorizationCode, authorizationCode, { expires: 7, sameSite: 'Strict' });
  };

  setEmail = (email: string) => {
    Cookies.set(CookieConstants.Email, email, { sameSite: 'Strict' });
  };

  removeDeviceToken = () => {
    Cookies.remove(CookieConstants.DeviceToken, { expires: 7 });
  };

  setRememberMe = (rememberMe: string) => {
    Cookies.set(CookieConstants.RememberMe, rememberMe, { expires: 7, sameSite: 'Strict' });
  };

  setUniqueSessionId = (uniqueSessionId: string) => {
    Cookies.set(CookieConstants.UniqueSessionId, uniqueSessionId, { sameSite: 'Strict' });
  };

  removeRememberMe = () => {
    Cookies.remove(CookieConstants.RememberMe, { expires: 7 });
  };

  getStateToken = () => Cookies.get(CookieConstants.StateToken);

  getOktaTokenExpiresAt = () => Cookies.get(CookieConstants.OktaTokenExpiresAt);

  getStatus = () => Cookies.get(CookieConstants.Status);

  getMessageID = () => Cookies.get(CookieConstants.MessageID);

  getEmail = () => Cookies.get(CookieConstants.Email);

  getFactorId = () => Cookies.get(CookieConstants.FactorId);

  getSessionToken = () => Cookies.get(CookieConstants.SessionToken);

  getAccessToken = () => Cookies.get(CookieConstants.AccessToken);

  getOktaToken = () => Cookies.get(CookieConstants.OktaToken);

  getAuthorizationCode = () => Cookies.get(CookieConstants.AuthorizationCode);

  getUserId = () => Cookies.get(CookieConstants.UserId);

  removeAccessToken = () => Cookies.remove(CookieConstants.AccessToken, { expires: 7 });

  getTempMsg = () => Cookies.get(CookieConstants.TempMessage);

  getRoleIDs = () => {
    const ids = Cookies.get(CookieConstants.RoleIDs);
    return JSON.parse(ids || '[]');
  };

  getDeviceToken = () => Cookies.get(CookieConstants.DeviceToken);

  getRememberMe = () => Cookies.get(CookieConstants.RememberMe);

  getOktaSessionExpirationTime = () => Cookies.get(CookieConstants.OktaSessionTimeout);

  getGatewaySessionExpiresAt = () => Cookies.get(CookieConstants.GatewaySessionExpiresAt);

  getSessionNotificationsCounter = () => Cookies.get(CookieConstants.SessionNotificationsCounter);

  removePass = () => Cookies.remove(CookieConstants.Pass);

  removeStatus = () => Cookies.remove(CookieConstants.Status);

  removeMsgID = () => Cookies.remove(CookieConstants.MessageID);

  getUniqueSessionId = () => Cookies.get(CookieConstants.UniqueSessionId);

  setRefreshToken = (refreshToken: string) => {
    Cookies.set(CookieConstants.RefreshToken, refreshToken, { sameSite: 'Strict' });
  };

  setOktaSessionExpirationTime = (time: string) => {
    Cookies.set(CookieConstants.OktaSessionTimeout, time, { sameSite: 'Strict' });
  };

  setGatewaySessionExpiresAt = (time: string) => {
    Cookies.set(CookieConstants.GatewaySessionExpiresAt, time, { sameSite: 'Strict' });
  };

  setSessionNotificationsCounter = (time: string) =>
    Cookies.set(CookieConstants.SessionNotificationsCounter, time, { sameSite: 'Strict' });

  setMessageID = (id: string) => Cookies.set(CookieConstants.MessageID, id, { sameSite: 'Strict' });

  getRefreshToken = () => Cookies.get(CookieConstants.RefreshToken);

  bypassRemoveAll = [
    CookieConstants.DeviceToken,
    CookieConstants.RememberMe,
    CookieConstants.Status,
    CookieConstants.MessageID,
    CookieConstants.OktaRememberUserLogin,
  ];

  removeAllCookie = () => {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      if (this.bypassRemoveAll.indexOf(cookieName) !== -1) return;
      const neededAttributes = { expires: 7 };
      Cookies.remove(cookieName, neededAttributes);
    });
  };
}

export default new Cookie();
