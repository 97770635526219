import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { CircularProgress } from '@mui/material';

import { requestStatusType, reqStatusEnum, iconStyles } from './';
import { getEmailActions, sendSupportEmail, getGlobalCosmicConfigPublic } from '../../../services/GatewayManagement';

import { Types, RoutesToNavigate } from '../../../constants';
import { menuItemsIcons } from '../icons';
import { IDefaultMenuItem, IReferenceMenuItem, MenuItemsEnum, menuItemsIconsEnum } from '../../Types';

export const getFormattedMenuItems = (
  allMenuItems: (IDefaultMenuItem | IReferenceMenuItem)[],
  styledIcon = '',
): { formattedItems: Types.MenuItem[]; roleQuicksights?: IReferenceMenuItem[] } => {
  const getMenuItemIcon = (key: menuItemsIconsEnum) => {
    const icon = menuItemsIcons(styledIcon)[key];
    return icon ? icon : menuItemsIcons(styledIcon)[menuItemsIconsEnum.unknownItemIcon];
  };

  const defaultMenuItems = [
    {
      title: 'Dashboard',
      link: { to: RoutesToNavigate.DASHBOARD },
      icon: getMenuItemIcon(menuItemsIconsEnum.dashboardIcon),
      selected: false,
      id: '65ddc88f395cb8759d147622',
    },
    {
      title: 'Document Library',
      link: { to: RoutesToNavigate.DOCUMENTS },
      icon: getMenuItemIcon(menuItemsIconsEnum.documentLibraryIcon),
      selected: false,
      id: '65ddc88f395cb8759d147623',
    },
    {
      title: 'Tools',
      subItems: [
        { title: 'Apps', link: { to: RoutesToNavigate.TOOLS } },
        { title: 'Common Links', link: { to: RoutesToNavigate.TOOLS } },
      ],
      link: { to: RoutesToNavigate.TOOLS },
      icon: getMenuItemIcon(menuItemsIconsEnum.toolsIcon),
      selected: false,
      id: '65ddc88f395cb8759d147624',
    },
  ];

  const getMenuItems = (): { formattedItems: Types.MenuItem[]; roleQuicksights?: IReferenceMenuItem[] } => {
    const menuItems = allMenuItems;

    if (!menuItems.length) {
      return { formattedItems: defaultMenuItems };
    }

    const _menuItems: Types.MenuItem[] = [];
    const _quicksights: IReferenceMenuItem[] = [];

    menuItems.forEach((itm) => {
      if (itm?.config?.type === MenuItemsEnum.default) {
        const index = itm.config.order ? itm.config.order : -1;

        const icon = getMenuItemIcon(itm.config.icon_key);
        const rawSubItemsArray = itm.config.sub_items;
        const _subItems: Types.MenuItemSubitems[] = [];
        if (rawSubItemsArray.length) {
          rawSubItemsArray.forEach((i) => {
            _subItems.push({ title: i.title, link: { to: i.redirect_url } });
          });
        }

        _menuItems[index] = {
          title: itm.config.title,
          link: { to: itm.config.redirect_url },
          icon,
          selected: false,
          subItems: _subItems.length ? _subItems : null,
          id: itm.menu_item_id,
        };
      } else if (itm?.config?.type === MenuItemsEnum.reference) {
        const rItm = itm as IReferenceMenuItem;
        _quicksights.push(rItm);

        const index = rItm.config.order ? rItm.config.order : -1;

        const baseRefItemConfig: Types.MenuItem = {
          title: rItm.config.title,
          link: { to: RoutesToNavigate.ALTS_DASHBOARD },
          icon: getMenuItemIcon(rItm.config.icon_key),
          selected: false,
          subItems: [],
          id: rItm.menu_item_id,
        };

        if (rItm.item.metadata.quicksights.length) {
          if (rItm.item.metadata.quicksights.length === 1) {
            baseRefItemConfig.link.iframeId = rItm.item.metadata.quicksights[0].id;
            baseRefItemConfig.subItems = null;
          } else {
            const subCatsConfig = rItm.item.metadata.quicksights.map((el) => ({
              title: el.name,
              link: { to: RoutesToNavigate.ALTS_DASHBOARD, iframeId: el.id },
            }));
            baseRefItemConfig.subItems = subCatsConfig;
          }
        } else {
          return;
        }

        _menuItems[index] = baseRefItemConfig;
      }
    });

    const filteredItems = _menuItems.filter((el) => !!el);

    return { formattedItems: filteredItems, roleQuicksights: _quicksights };
  };

  return getMenuItems();
};

export const cleanUp = (
  setFeedbackText: React.Dispatch<React.SetStateAction<string>>,
  setFeedbackStatus: React.Dispatch<React.SetStateAction<requestStatusType>>,
) => {
  setFeedbackText('');
  setFeedbackStatus('init');
};

export async function getInitData(
  setAction: React.Dispatch<React.SetStateAction<string>>,
  setEmailRestrictionNumber: React.Dispatch<React.SetStateAction<number>>,
) {
  await Promise.allSettled([getGlobalCosmicConfigPublic(), getEmailActions()]).then((results) => {
    const [globalConfigRes, emailActionsRes] = results;

    if (
      globalConfigRes.status === 'fulfilled' &&
      globalConfigRes.value.success &&
      typeof globalConfigRes.value.data?.email_count_restriction_from_user_per_day === 'number'
    ) {
      setEmailRestrictionNumber(globalConfigRes.value.data?.email_count_restriction_from_user_per_day);
    }

    if (emailActionsRes.status === 'fulfilled' && emailActionsRes.value.success && emailActionsRes.value.data) {
      setAction(emailActionsRes.value.data.SendFeedbackMessage);
    }
  });
}

export const handleSuccessCase = (
  setFeedbackStatus: React.Dispatch<React.SetStateAction<requestStatusType>>,
  setPopperOpened: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setFeedbackStatus(reqStatusEnum.success);
  setTimeout(() => {
    setPopperOpened(false);
  }, 1000);
};

export const handleSendClick = async (
  feedbackText: string,
  action: string,
  setFeedbackStatus: React.Dispatch<React.SetStateAction<requestStatusType>>,
  setToastifySettings: React.Dispatch<
    React.SetStateAction<{
      type: string;
      msg: string;
      options?: { autoClose?: number; closeOnClick?: boolean; icon?: JSX.Element };
    }>
  >,
  setPopperOpened: React.Dispatch<React.SetStateAction<boolean>>,
  refreshUser: (showLoading?: boolean | undefined) => void,
  setRefreshingFeedbackData: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setFeedbackStatus(reqStatusEnum.loading);
  setRefreshingFeedbackData(true);

  setTimeout(() => {
    setToastifySettings({ type: 'success', msg: 'Feedback sent successfully' });
    handleSuccessCase(setFeedbackStatus, setPopperOpened);
  }, 500);

  const formData = new FormData();
  formData.append('subject', 'fast feedback');
  formData.append('message', `${feedbackText}. The api call was made from ${window.location.href}`);
  formData.append('category', 'request');

  const res = await sendSupportEmail(action, formData);

  if (res.success) {
    await refreshUser();
  }

  setRefreshingFeedbackData(false);
};

export const getBtnContent = (status: requestStatusType) => {
  switch (status) {
    case reqStatusEnum.error:
      return 'Error';
    case reqStatusEnum.success:
      return <DoneIcon style={iconStyles} />;
    case reqStatusEnum.loading:
      return <CircularProgress style={iconStyles} />;
    case reqStatusEnum.init:
      return 'Send Feedback';
    default:
      return 'Send Feedback';
  }
};

export const isFeedbackBtnDisabled = (emailRestrictionNumber: number, emailsSentByUser: number) => {
  return !(emailRestrictionNumber - emailsSentByUser > 0);
};

export const getSendBtnTitle = (textLength: number) => {
  if (textLength < 5) {
    return 'feedback length shall be at least 5 characters long';
  }
  if (textLength >= 1000) {
    return 'feedback length shall be limited by 1000 characters ';
  }
  return '';
};
