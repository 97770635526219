import { createSlice } from '@reduxjs/toolkit';
import { Types } from '../constants';
import { IQuicksiteItem, IReferenceMenuItem, RoleMenuItem } from '../Layouts/Types';

interface IInitialState {
  menuItemsArray: Types.MenuItem[];
  defaultMenuItems: RoleMenuItem[];
  roleQuicksights: IReferenceMenuItem[];
  allQuicksights: IQuicksiteItem[];
}

const initialState: IInitialState = {
  menuItemsArray: [],
  defaultMenuItems: [],
  roleQuicksights: [],
  allQuicksights: [],
};

export const menuItemsSlice = createSlice({
  name: 'gtw-menu-items',
  initialState,
  reducers: {
    setMenuItems(state, action) {
      state.menuItemsArray = action.payload;
    },
    setDefaultMenuItems(state, action) {
      state.defaultMenuItems = action.payload;
    },
    setRoleQuicksights(state, action) {
      state.roleQuicksights = action.payload;
    },
    setAllQuicksights(state, action) {
      state.allQuicksights = action.payload;
    },
  },
});

export const selectMenuItemName = (state: IInitialState, itemUrl: string) => {
  const name = state.menuItemsArray.find((item) => item.link.to.includes(itemUrl))?.title;
  return name;
};

export const menuItemsSliceActions = menuItemsSlice.actions;

export default menuItemsSlice.reducer;
