enum Reli {
  AddNewJob = 'An error was encountered while adding a new job',
  AddFileToJob = 'An error was encountered while uploading a file',
  GetJobs = 'An error was encountered while retrieving jobs',
  GetJob = 'An error was encountered while retrieving the job',
  GetDocument = 'An error was encountered while retrieving document data',
  Rejected = 'An error was encountered while rejecting the job',
  ApproveDocument = 'An error was encountered while approving document',
  RejectDocument = 'An error was encountered while rejecting document',
  SaveDocument = 'An error was encountered while saving document data',
  RerouteDocument = 'An error was encountered while rerouting document',
  GetPdfFile = 'An error was encountered while retrieving pdf file',
  GetExcelFile = 'An error was encountered while retrieving excel file',
  ExcelTryAgain = 'The Excel file is not ready yet. Please try again in a few minutes.',
  SaveToShareFile = 'An error was encountered while saving files to Sharefile',
  GetDocumentStatus = 'An error was encountered while retrieving document status',
  GetAllFieldsFromMidas = 'An error was encountered while retrieving all fields from Midas',
  GetDocumentType = 'An error was encountered while retrieving document type',
  GetDocumentDetails = 'An error was encountered while retrieving document details',
  EditDocumentFields = 'An error was encountered while editing document fields',
  GetSharefileFunctions = 'An error was encountered while retrieving sharefile functions',
  DeleteDocumentFields = 'An error was encountered while deleting document fields',
  DownloadDocument = 'An error was encountered while downloading document',
  EndlessDefinitionsLoading = 'No response from the "/definitions" endpoint. This message will be helpful for the technical support team.',
  SendBugReport = 'An error was encountered while sending the report',
}

enum ShareFile {
  GetFoldersError = 'Something went wrong while getting folders',
  InvalidInput = "Invalid input. This character isn't allowed",
  InvalidInputSlash = 'Use button on the right to add subfolder to the path.',
  ValidatePathError = 'An error was encountered while validating ShareFile path expression. Please check your path expression and try again.',
  SavingFileError = 'Error saving files',
  WrongNumberOfDoubleQuotes = 'Number of double quotes in path expression is not even. Please check your path expression and try again.',
  UnexpextedToken = 'Unexpected token in path expression. Please check your path expression and try again.',
}

enum PDFViewerErrors {
  DocIsPasswordProtected = 'Unfortunately, we cannot load this document as it is password protected',
}

const DEFAULT =
  'An error was encountered while processing the request. If the problem persists to contact your administrator.';

const NOT_AUTHORIZED = 'You are not authorized to perform this action. Please refresh the page and login again.';

const UNEXPECTED = 'An unexpected error occurred';

export { Reli, DEFAULT, ShareFile, PDFViewerErrors, NOT_AUTHORIZED, UNEXPECTED };
