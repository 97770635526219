export * from './InfoLinks';
export * from './ExcelFile';
export * as DocumentModel from './DocumentModel';
export * as TableColumns from './TableColumns';
export * as ShareFileConstants from './ShareFile';
export * as Errors from './Errors';
export * as Headers from './Headers';
export * as Regex from './Regex';
export * as Messages from './Messages';
export * from './NotificationOptions';
export * from './Midas';
export * from './TimingStats';
export * from './Types';

const TOTAL_MAX_FILE_SIZE = 150000000; // 150 MB
const TOTAL_MAX_FILE_SIZE_DESCRIPTION = 150; // 150 MB

const SEPARATOR_FOR_ROWS_COLS = '_col_row_';

const SERVER_TIME_TIMEZONE = '+00:00'; // UTC

const MAX_FILE_NUMBER_PER_REQUEST = 1;

const MAX_REQUEST_GROUP_SIZE = 10;

const DEFAULT_DOCUMENTS_PER_PAGE = 10;

const MAX_SNAPSHOTS_NUMBER = 11;

const BASE_CANVAS_WIDTH = 612;

const BULK_REQUEST_TO_PROXY_CHUNK_LENGTH = 20;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

enum ReliUrls {
  Main = '/reli',
  CreateJob = '/reli/add-job',
  CurrentJob = '/reli/job/:id',
  FilePreview = '/reli/job/file-preview/:jobid/:docid',
}

const defaultDocsPerPageOptions = [
  { id: 10, name: '10' },
  { id: 25, name: '25' },
  { id: 50, name: '50' },
  { id: 1000, name: 'All' },
];

enum configEnvironment {
  production = 'production',
  development = 'development',
  qa = 'qa',
}

export {
  ReliUrls,
  SEPARATOR_FOR_ROWS_COLS,
  SERVER_TIME_TIMEZONE,
  TOTAL_MAX_FILE_SIZE,
  TOTAL_MAX_FILE_SIZE_DESCRIPTION,
  MAX_FILE_NUMBER_PER_REQUEST,
  MAX_REQUEST_GROUP_SIZE,
  DEFAULT_DOCUMENTS_PER_PAGE,
  defaultDocsPerPageOptions,
  configEnvironment,
  months,
  MAX_SNAPSHOTS_NUMBER,
  BASE_CANVAS_WIDTH,
  BULK_REQUEST_TO_PROXY_CHUNK_LENGTH,
};
