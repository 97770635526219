import _ from 'lodash';

import { Types } from '../constants';

export const updateMultipleDocsData = (
  prevJobDataInitialFiles: Types.ITableCellsStructuredData[],
  prevJobDataFiles: Types.ITableCellsStructuredData[],
  data: Types.ITableCellsStructuredData[],
  payloadIds: string[],
) => {
  const updatedInitialFiles = [...prevJobDataInitialFiles];
  const updatedFiles = [...prevJobDataFiles];
  payloadIds.map((el) => {
    const file = prevJobDataInitialFiles.find((doc) => doc.id === el);
    const fileIndex = prevJobDataInitialFiles.findIndex((doc) => doc.id === el);
    const idx = data.findIndex((elem) => elem.id === el);

    if (file && fileIndex !== -1 && idx !== -1) {
      const updatedFile = { ...file, fields: data[idx].fields };
      updatedInitialFiles[fileIndex] = { ...updatedFile };
      updatedFiles[fileIndex] = { ...updatedFile };
    }
  });
  return {
    initialFiles: updatedInitialFiles,
    files: updatedFiles,
  };
};

export const updateRowJobDataFiles = (
  prevRowDataFiles: Types.IJobFileData[],
  updatedData: Partial<Types.IJobFileData>,
) => {
  const currentFiles = _.cloneDeep(prevRowDataFiles);

  if (Array.isArray(currentFiles)) {
    const docToUpdateIdx = currentFiles.findIndex((el) => el.guid === updatedData.guid);
    currentFiles.splice(docToUpdateIdx, 1, {
      ...currentFiles[docToUpdateIdx],
      state: String(updatedData.state),
    });
  }

  return currentFiles;
};
