export const iconStyles = {
  color: 'white',
  width: '20px',
  height: '20px',
};

export const circularProgressStyles = {
  color: 'grey',
  width: '15px',
  height: '15px',
  margin: '0 5px 0 0',
};

export type requestStatusType = 'init' | 'loading' | 'success' | 'error';

export enum reqStatusEnum {
  init = 'init',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export const min_feedback_length = 5;
export const max_feedback_length = 1000;
