import _ from 'lodash';
import axios from 'axios';
import { DateTime } from 'luxon';

import { Cookie } from '..';
import { Types } from '../../constants';
import { getFailedApiCallResponse } from '../utils';

export const getCurrentPosts = async () => {
  const accessToken = Cookie.getAccessToken();
  try {
    if (accessToken) {
      const res = await axios.get('/gateway/posts', { headers: { 'X-ACCESS-TOKEN': accessToken } });
      if (_.get(res, 'data.success') && res.data?.posts.length) {
        return {
          success: true,
          posts: res.data?.posts?.map((p: any) => ({ ...p, date: DateTime.fromISO(p.date), dateString: p.date })),
        };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getContentVisibilityGroups = async (): Promise<{
  success: boolean;
  groups?: { id: string; name: string }[];
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    if (accessToken) {
      const res = await axios.get('/gateway/posts/blog-content-groups', { headers: { 'X-ACCESS-TOKEN': accessToken } });
      if (res.status === 200) {
        return {
          success: true,
          groups: res?.data?.groups || [],
        };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const editPost = async (
  id: string,
  data: Types.PostPayload,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.patch(`/gateway/posts/${id}`, { ...data }, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const createNewPost = async (data: Types.INewPostData) => {
  const accessToken = Cookie.getAccessToken();
  try {
    if (accessToken) {
      const res = await axios.post('/gateway/posts', data, { headers: { 'X-ACCESS-TOKEN': accessToken } });

      if (_.get(res, 'data.success')) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          error: res.data?.error,
        };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getAllAvailablePosts = async (): Promise<{
  success: boolean;
  posts?: Types.Posts;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get('/gateway/posts', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200 && res.data.success) {
      return {
        success: true,
        posts: res.data.posts.map((o: any) => ({
          ...o,
          date: DateTime.fromISO(o.date),
          dateString: o.date,
        })),
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteBlogPost = async (
  id: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.delete(`/gateway/posts/${id}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 204) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
