import { useContext } from 'react';
import _ from 'lodash';
import { UserContext } from '../context/UserContext';

export const getColors = () => {
  const userContext = useContext(UserContext);
  return {
    primary: _.get(userContext, 'role.colors[0].color', '#0A354C'),
    secondary: _.get(userContext, 'role.colors[1].color', '#E50F45'),
  };
};
