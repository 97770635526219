import React from 'react';
import { SxProps, Theme } from '@mui/material';
import alpha from 'color-alpha';
import LoadingButton from '@mui/lab/LoadingButton';

const buttonStyles: Record<string, SxProps<Theme> | undefined> = {
  saveButton: (theme) => ({
    height: '35px',
    minWidth: '100px',
    borderRadius: '5px',
    fontFamily: 'Proxima Nova, sans-serif',
    fontWeight: 'bold',
    padding: '20px',
    alignSelf: 'flex-end',
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
    },
    '&:disabled': {
      color: 'white',
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
    },
  }),
};

interface IRectangleButton {
  btnText: string | React.JSX.Element;
  btnType: 'submit' | 'reset' | 'button';
  disabled: boolean;
  loading: boolean;
  loadingPosition: 'center' | 'end' | 'start' | undefined;
  onBtnClick: (e: any) => void;
  customSx?: SxProps<Theme>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const RectangleButton = ({
  btnText,
  btnType,
  disabled,
  onBtnClick,
  customSx,
  startIcon,
  endIcon,
  loading,
  loadingPosition,
}: IRectangleButton) => (
  <LoadingButton
    type={btnType}
    variant="contained"
    sx={[buttonStyles.saveButton, ...(Array.isArray(customSx) ? customSx : [customSx])]}
    disabled={disabled}
    startIcon={startIcon}
    endIcon={endIcon}
    loading={loading}
    loadingPosition={loadingPosition}
    onClick={onBtnClick}
  >
    {btnText}
  </LoadingButton>
);

RectangleButton.defaultProps = { loading: false, loadingPosition: 'center', btnType: 'button' };
