import { Theme, SxProps } from '@mui/material';

export const oktaSessionModalStyles: Record<string, SxProps<Theme> | undefined> = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    padding: '15px',
    outline: 'none',
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '520px',
  },
  modalText: {
    fontFamily: 'Proxima Nova,sans-serif',
    fontSize: '16px',
    color: 'black',
    margin: '0 0 20px 0',
    textAlign: 'justify',
  },
  title: {
    fontFamily: 'Neuzeit-Grotesk, sans-serif',
    color: '#0b354c',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 0 15px 0',
  },
  tiemoutWrapper: {
    display: 'inline-block',
    width: '15px',
    textAlign: 'center',
  },
  btns: (theme) => ({
    height: '45px',
    width: '80%',
    fontFamily: 'Proxima Nova,sans-serif',
    fontWeight: 'bold',
    padding: '0px',
    alignSelf: 'center',
    color: 'white',
    margin: '0 0 15px 0',
    background: `linear-gradient(to right, ${theme.palette.secondary.main} 50%, ${theme.palette.primary.main} 50%)`,
    backgroundSize: '200% 100%',
    backgroundPosition: 'left',
    '&:last-child': {
      marginBottom: '0px',
    },
    '&:hover': {
      border: '1px solid white',
    },
  }),
  terminationCode: {
    fontFamily: 'Proxima Nova,sans-serif',
    fontSize: '12px',
    color: 'black',
    opacity: 0.5,
    position: 'absolute',
    left: '-8px',
    bottom: '-12px',
  },
};
