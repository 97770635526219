import { styled } from '@mui/material/styles';
import { Modal } from '@mui/material';

interface IParentDiv {
  colors: {
    readonly primary: string;
    readonly secondary: string;
  };
}

const lightGrey = '#dbdbdb';
const baseBlueColor = '#0b354c';

export const StyledModal = styled(Modal)<IParentDiv>`
  .modal-wrapper {
    position: absolute;
    padding: 0px 25px 10px 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    min-height: 300px;
    max-height: 500px;
    border: none;
    outline: none;
    color: black;
    background-color: white;
    overflow-y: auto;
    box-shadow: 2px 2px 5px 0px #707070;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    @media screen and (max-width: 900px) {
      width: 400px;
      padding: 0px 15px 10px 15px;
    }
    @media screen and (max-width: 500px) {
      width: 300px;
      padding: 0px 15px 10px 15px;
    }
    @media screen and (max-width: 350px) {
      width: 250px;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .modal-heading {
    font-weight: bold;
    font-size: 20px;
    color: ${baseBlueColor};
    text-align: center;
    padding: 15px;
    @media screen and (max-width: 500px) {
      font-size: 17px;
    }
  }
  .list-element-wrapper {
    padding: 5px;
    display: flex;
    border-bottom: 1px solid ${lightGrey};
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: ${lightGrey};
      cursor: pointer;
    }
    .list-element {
      line-height: 19px;
      color: ${baseBlueColor};
      font-size: 13px;
      font-family: Neuzeit-Grotesk, sans-serif;
      @media screen and (max-width: 500px) {
        font-size: 11px;
      }
    }
    .bold {
      max-width: 300px;
      font-size: 17px;
      font-weight: bold;
      margin: 0 5px 0 0;
      @media screen and (max-width: 900px) {
        max-width: 180px;
      }
      @media screen and (max-width: 500px) {
        font-size: 15px;
      }
    }
  }
  .space-between {
    justify-content: space-between;
  }
  .disabled {
    &:hover {
      cursor: default;
    }
  }
`;
