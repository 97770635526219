import axios from 'axios';

import { Cookie } from '..';
import { getFailedApiCallResponse } from '../utils';
import { ISampleQuestions } from '../../constants/RecoveryQuestionConstants';
import Config from '../../config';

const { GATEWAY_ID } = Config;

export const getSampleRecoveryQuestions = async (): Promise<{
  success: boolean;
  data?: ISampleQuestions[];
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get('/gateway/users/security-questions', {
      headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data.questions,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getCurrentRecoveryQuestion = async (): Promise<{
  success: boolean;
  data?: string;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get('/gateway/users/security-question', {
      headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data.question,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const saveNewRecoveryQuestion = async (
  question: string,
  answer: string,
  password: string,
): Promise<{
  success: boolean;
  data?: string;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.post(
      '/gateway/users/security-question/password',
      { question, answer, password },
      {
        headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID },
      },
    );
    if (res.status === 200) {
      return {
        success: true,
        data: res.data.question,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
