import { createSlice, PayloadAction, current, createAsyncThunk } from '@reduxjs/toolkit';
import Honeybadger from '@honeybadger-io/js';

import { Types } from '../constants';
import { DocumentAction } from './../components/Reli/constants/Types';
import { updateMultipleDocsData, updateRowJobDataFiles } from './reli-utils';
import { DocumentModel, MAX_SNAPSHOTS_NUMBER, TableColumns } from '../components/Reli/constants';
import { RootState } from '.';
import { sortColumns2, getUniqueColumnsFromFiles } from '../components/Reli/helpers';

interface IInitialState {
  currentJobData: Types.IJobDocumentsIds;
  currentJobId: string;
  rowJobData: Types.IJobInfo;
  changedDocumentTypes: { [keys: string]: Types.ReliDocumentType };
  reliJobColumns: string[];
  approvedJobDocs: string[];
  rejectedJobDocs: string[];
  tableState: { sortBy: { id: string; desc: boolean }[] };
  snapshots: {
    stateSnapshot: Omit<IInitialState, 'snapshots' | 'snapshotPosition'>;
    locallyStoredJobColumns: string[];
  }[];
  snapshotPosition: number;
  isJobEdited: boolean;
  isJobLoading: boolean;
  isButtonsCombinationDisabled: boolean;
  docsToRefetch: Types.ITableCellsStructuredData[];
}

const initialState: IInitialState = {
  currentJobData: { id: '', client: '', jobName: '', files: [], initialFiles: [] },
  currentJobId: '',
  rowJobData: {
    id: '',
    client: '',
    jobName: '',
    files: {
      file: [],
    },
  },
  changedDocumentTypes: {},
  reliJobColumns: [],
  approvedJobDocs: [],
  rejectedJobDocs: [],
  tableState: { sortBy: [] },
  snapshots: [],
  snapshotPosition: 0,
  isJobEdited: false,
  isJobLoading: false,
  isButtonsCombinationDisabled: false,
  docsToRefetch: [],
};

const handleSnapshotsQueue = (
  queueObject: IInitialState,
  snapshotPosition = 0,
  rebuildSnapshotObject?: { idx: number; updatedData: Types.ITableCellsStructuredData },
) => {
  let locallyStoredJobColumns: string[] = [];
  const { snapshots, snapshotPosition: queueSnapshotPosition, ...stateWithoutSnapshots } = queueObject;

  try {
    const locallyStoredColumnsOrder: string[] =
      localStorage.getItem('columnsOrder') && JSON.parse(localStorage.getItem('columnsOrder') as string);
    locallyStoredJobColumns = locallyStoredColumnsOrder;
  } catch (e) {
    console.error(e);
  }

  let updatedSnapshots = snapshots;

  if (snapshots.length - 1 >= snapshotPosition && snapshotPosition !== MAX_SNAPSHOTS_NUMBER - 1) {
    updatedSnapshots = [...updatedSnapshots.slice(0, snapshotPosition)];
  }

  updatedSnapshots = updatedSnapshots.concat({
    stateSnapshot: stateWithoutSnapshots,
    locallyStoredJobColumns,
  });

  if (updatedSnapshots.length > MAX_SNAPSHOTS_NUMBER) {
    updatedSnapshots = [...updatedSnapshots.slice(1, updatedSnapshots.length)];
  }

  if (rebuildSnapshotObject) {
    for (const snapshot of updatedSnapshots) {
      snapshot.stateSnapshot.currentJobData.initialFiles[rebuildSnapshotObject.idx] = {
        ...(rebuildSnapshotObject.updatedData as Types.ITableCellsStructuredData),
      };
      snapshot.stateSnapshot.currentJobData.files[rebuildSnapshotObject.idx] = {
        ...(rebuildSnapshotObject.updatedData as Types.ITableCellsStructuredData),
      };
    }
  }

  return updatedSnapshots;
};

const resetSnapshotsQueue = (queueObject: IInitialState) => {
  let locallyStoredJobColumns: string[] = [];
  const { snapshots, snapshotPosition, ...stateWithoutSnapshots } = queueObject;

  try {
    const locallyStoredColumnsOrder: string[] =
      localStorage.getItem('columnsOrder') && JSON.parse(localStorage.getItem('columnsOrder') as string);
    locallyStoredJobColumns = locallyStoredColumnsOrder;
  } catch (e) {
    console.error(e);
  }

  const initialSnapshot = [
    {
      stateSnapshot: stateWithoutSnapshots,
      locallyStoredJobColumns,
    },
  ];
  return initialSnapshot;
};

type DocumentTypeKeys = keyof typeof TableColumns.DocumentType;

const getDocTypeObject = (docType: DocumentTypeKeys) => ({
  manual: {
    vendor: docType.split('.')[0],
    class: docType.split('.')[1],
    train: false,
  },
});

const handleUpdateDocumentTypes = (
  files: Types.ITableCellsStructuredData[],
  initialFiles: Types.ITableCellsStructuredData[],
) => {
  const docTypesObject: { [keys: string]: Types.ReliDocumentType } = {};
  const filesDocumentTypes: { [keys: string]: string | null | undefined } = {};
  const initialFilesDocumentTypes: { [keys: string]: string | null | undefined } = {};
  files.map((el) => (filesDocumentTypes[el.id] = el.fields.find((fieldEl) => fieldEl.name === 'document_type')?.value));
  initialFiles.map(
    (el) => (initialFilesDocumentTypes[el.id] = el.fields.find((fieldEl) => fieldEl.name === 'document_type')?.value),
  );
  for (const id in filesDocumentTypes) {
    if (filesDocumentTypes[id] !== initialFilesDocumentTypes[id]) {
      docTypesObject[id] = getDocTypeObject(filesDocumentTypes[id] as DocumentTypeKeys);
    }
  }
  return docTypesObject;
};

export const handleSocketBulkUpdateAsync = createAsyncThunk(
  'files/socketBulkUpdateAsync',
  async (newDocs: Types.ITableCellsStructuredData[], thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const curState = state.reliCurJobSliceData;
    const midasFields = state.reliTableEditingSliceData.midasFields;
    try {
      const payloadIds = newDocs.map((doc) => doc.id);
      const updatedFiles = updateMultipleDocsData(
        curState.currentJobData.initialFiles,
        curState.currentJobData.files,
        newDocs,
        payloadIds,
      );

      return { ...updatedFiles, midasFields };
    } catch (error) {
      if (Honeybadger.config.environment === 'production') {
        Honeybadger.notify('[UI] Error in updateJobWithEditedOrUploadedFile', {
          name: 'Reli refresh socket error',
          tags: 'gateway-ui, reli-ui',
          context: {
            error,
            error_text: String(error),
            state_files: current(curState.currentJobData.files),
            action_payload: newDocs,
          },
        });
      }
    }
  },
);

export const currentJobSlice = createSlice({
  name: 'reli-current-job-data',
  initialState,
  reducers: {
    reset: () => initialState,
    setCurrentJobData(state, action) {
      state.currentJobData = action.payload;
      state.currentJobId = action.payload.id;
      state.snapshotPosition = 0;
      state.snapshots = resetSnapshotsQueue(state);
    },
    setJobIsLoading(state, action) {
      state.isJobLoading = action.payload;
    },
    updateAllJobFiles(state, action) {
      state.currentJobData.files = action.payload;
      state.snapshotPosition =
        state.snapshotPosition + 1 < MAX_SNAPSHOTS_NUMBER ? state.snapshotPosition + 1 : state.snapshotPosition;
      state.snapshots = handleSnapshotsQueue(state, state.snapshotPosition);
    },
    handleNotNormalizedDocs(state, action) {
      state.currentJobData.files = state.currentJobData.files.map((el) => {
        return action.payload.includes(el.id) ? { ...el, fields: [], author: '' } : el;
      });
    },
    updateJobWithEditedOrUploadedFile(
      state,
      action: PayloadAction<{
        data: Partial<Types.ITableCellsStructuredData>;
        updateInitial: boolean;
        updateDocumentTypes?: boolean;
        mode?: Types.fieldEnteringModeEnum | null;
        postFinalStatus?: boolean;
        rebuildAllSnapshots?: boolean;
      }>,
    ) {
      try {
        const docToUpdateIdx = state.currentJobData.files.findIndex((el) => el.id === action.payload.data.id);
        const initDocToUpdateIdx = state.currentJobData.initialFiles.findIndex(
          (el) => el.id === action.payload.data.id,
        );
        if (docToUpdateIdx !== -1 && initDocToUpdateIdx !== -1) {
          const docToUpdate = state.currentJobData.files[docToUpdateIdx];
          const rowFiles = Array.isArray(state.rowJobData.files.file)
            ? state.rowJobData.files.file
            : [state.rowJobData.files.file];
          const rowDocIdx = rowFiles.findIndex((el) => el.guid === action.payload.data.id);
          if (rowDocIdx !== -1) {
            const rowDocData = rowFiles[rowDocIdx];

            const updatedData = action.payload.data;

            if (docToUpdate && updatedData && rowDocData) {
              const isDocInFinalState =
                DocumentModel.States.allSubmitted.includes(rowDocData.state) ||
                DocumentModel.States.allSubmitted.includes(docToUpdate.state);

              if (isDocInFinalState && docToUpdate.fields.length && !Boolean(action.payload.postFinalStatus)) return;

              if (
                isDocInFinalState &&
                !Boolean(action.payload.postFinalStatus) &&
                updatedData.state !== DocumentModel.States.Failed
              ) {
                delete updatedData.state;
              }

              if (docToUpdateIdx !== -1) {
                state.currentJobData.files[docToUpdateIdx] = {
                  ...(updatedData as Types.ITableCellsStructuredData),
                };
                state.rejectedJobDocs = state.rejectedJobDocs.filter((id) => id !== docToUpdate.id);
                state.approvedJobDocs = state.approvedJobDocs.filter((id) => id !== docToUpdate.id);

                if (action.payload.updateDocumentTypes) {
                  state.changedDocumentTypes = handleUpdateDocumentTypes(
                    state.currentJobData.files,
                    state.currentJobData.initialFiles,
                  );
                }

                state.snapshotPosition =
                  state.snapshotPosition + 1 < MAX_SNAPSHOTS_NUMBER
                    ? state.snapshotPosition + 1
                    : state.snapshotPosition;
                state.snapshots = handleSnapshotsQueue(
                  state,
                  state.snapshotPosition,
                  action.payload.rebuildAllSnapshots
                    ? { idx: initDocToUpdateIdx, updatedData: updatedData as Types.ITableCellsStructuredData }
                    : undefined,
                );
              }

              if (action.payload.updateInitial && initDocToUpdateIdx !== -1) {
                state.currentJobData.initialFiles[initDocToUpdateIdx] = {
                  ...(updatedData as Types.ITableCellsStructuredData),
                };
              }
            }
          }
        }
      } catch (e) {
        if (Honeybadger.config.environment === 'production') {
          Honeybadger.notify('[UI] Error in updateJobWithEditedOrUploadedFile', {
            name: 'Reli refresh socket error',
            tags: 'gateway-ui, reli-ui',
            context: {
              error: e,
              error_text: String(e),
              state: current(state.currentJobData.files),
              action_payload: action.payload,
            },
          });
        }
      }
    },
    setRowJobData(state, action: PayloadAction<Types.IJobInfo>) {
      state.rowJobData = action.payload;
    },
    updateRowJobData(state, action) {
      state.rowJobData.files.file = updateRowJobDataFiles(state.rowJobData.files.file, action.payload);
    },
    handleUndoChanges(state) {
      if (state.snapshotPosition - 1 >= 0) {
        const updatedSnapshotPosition = state.snapshotPosition - 1;
        const previousState = state.snapshots[updatedSnapshotPosition]?.stateSnapshot;

        if (
          JSON.stringify(state.snapshots[updatedSnapshotPosition].stateSnapshot.reliJobColumns) !==
          JSON.stringify(state.snapshots[updatedSnapshotPosition + 1].stateSnapshot.reliJobColumns)
        ) {
          localStorage.setItem(
            'columnsOrder',
            JSON.stringify(state.snapshots[updatedSnapshotPosition].stateSnapshot.reliJobColumns),
          );
        }
        return { ...previousState, snapshots: state.snapshots, snapshotPosition: updatedSnapshotPosition };
      }
    },
    handleRedoChanges(state) {
      if (state.snapshots[state.snapshotPosition + 1]) {
        const updatedSnapshotPosition = state.snapshotPosition + 1;
        const updatedState = state.snapshots[updatedSnapshotPosition]?.stateSnapshot;

        if (
          JSON.stringify(state.snapshots[updatedSnapshotPosition].stateSnapshot.reliJobColumns) !==
          JSON.stringify(state.snapshots[updatedSnapshotPosition - 1].stateSnapshot.reliJobColumns)
        ) {
          localStorage.setItem(
            'columnsOrder',
            JSON.stringify(state.snapshots[updatedSnapshotPosition].stateSnapshot.reliJobColumns),
          );
        }
        return { ...updatedState, snapshots: state.snapshots, snapshotPosition: updatedSnapshotPosition };
      }
    },
    resetChangedDocumentsTypes(state) {
      state.changedDocumentTypes = {};
      state.snapshots = [];
      state.snapshotPosition = 0;
    },
    setReliJobColumns(state, action) {
      state.reliJobColumns = action.payload.sortedColumns;
      if (action.payload.addedColumns || action.payload.columnsReordered) {
        state.approvedJobDocs = [];
        state.rejectedJobDocs = [];
        state.snapshotPosition =
          state.snapshotPosition + 1 < MAX_SNAPSHOTS_NUMBER ? state.snapshotPosition + 1 : state.snapshotPosition;
        state.snapshots = handleSnapshotsQueue(state, state.snapshotPosition);
      }
    },
    rejectAllDocs(state, action) {
      state.rejectedJobDocs = action.payload;
      state.approvedJobDocs = [];
      state.snapshotPosition =
        state.snapshotPosition + 1 < MAX_SNAPSHOTS_NUMBER ? state.snapshotPosition + 1 : state.snapshotPosition;
      state.snapshots = handleSnapshotsQueue(state, state.snapshotPosition);
    },
    approveAllDocs(state, action) {
      state.approvedJobDocs = action.payload;
      state.rejectedJobDocs = [];
      state.snapshotPosition =
        state.snapshotPosition + 1 < MAX_SNAPSHOTS_NUMBER ? state.snapshotPosition + 1 : state.snapshotPosition;
      state.snapshots = handleSnapshotsQueue(state, state.snapshotPosition);
    },
    resetTableData(
      state,
      action: PayloadAction<
        | {
            resetSnapshots?: boolean;
            midasFields: Types.IMidasFields2[];
          }
        | undefined
      >,
    ) {
      const initialColumns = getUniqueColumnsFromFiles(
        state.currentJobData.initialFiles,
        action.payload?.midasFields || [],
      );
      const sortedInitialColumns = sortColumns2(initialColumns);
      state.currentJobData.files = state.currentJobData.initialFiles;
      state.changedDocumentTypes = {};
      state.approvedJobDocs = [];
      state.rejectedJobDocs = [];
      state.reliJobColumns = sortedInitialColumns;
      if (action.payload?.resetSnapshots) {
        state.snapshotPosition = 0;
        state.snapshots = [];
      }
      if (state.currentJobData.id) {
        state.snapshotPosition =
          state.snapshotPosition + 1 < MAX_SNAPSHOTS_NUMBER ? state.snapshotPosition + 1 : state.snapshotPosition;
        if (action.payload?.resetSnapshots) {
          state.snapshotPosition = 0;
        }
        state.snapshots = handleSnapshotsQueue(state, state.snapshotPosition);
      }
    },
    setDocAction(state, action) {
      const documentId = action.payload.id;
      const newAction = action.payload.action;
      let prevAction: DocumentAction | null = null;
      const filterFunction = (dId: string, currentAction: DocumentAction) => {
        if (dId === documentId) {
          prevAction = currentAction;
        }
        return dId !== documentId;
      };
      state.rejectedJobDocs = state.rejectedJobDocs.filter((dId) => filterFunction(dId, DocumentAction.Reject));
      state.approvedJobDocs = state.approvedJobDocs.filter((dId) => filterFunction(dId, DocumentAction.Approve));
      if (!prevAction || (prevAction && newAction !== prevAction)) {
        if (newAction === DocumentAction.Approve) state.approvedJobDocs.push(documentId);
        if (newAction === DocumentAction.Reject) state.rejectedJobDocs.push(documentId);
      }
      state.snapshotPosition =
        state.snapshotPosition + 1 < MAX_SNAPSHOTS_NUMBER ? state.snapshotPosition + 1 : state.snapshotPosition;
      state.snapshots = handleSnapshotsQueue(state, state.snapshotPosition);
    },
    setTableSort(state, action) {
      state.tableState.sortBy = action.payload;
      state.snapshotPosition =
        state.snapshotPosition + 1 < MAX_SNAPSHOTS_NUMBER ? state.snapshotPosition + 1 : state.snapshotPosition;
      state.snapshots = handleSnapshotsQueue(state, state.snapshotPosition);
    },
    setIsJobEdited(state, action) {
      state.isJobEdited = action.payload;
    },
    setIsButtonsCombinationDisabled(state, action) {
      state.isButtonsCombinationDisabled = action.payload;
    },
    setDocsToRefetch(state, action) {
      state.docsToRefetch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleSocketBulkUpdateAsync.fulfilled, (state, action) => {
      if (action.payload) {
        state.currentJobData.files = action.payload.files;
        state.currentJobData.initialFiles = action.payload.initialFiles;
        const initialColumns = getUniqueColumnsFromFiles(state.currentJobData.initialFiles, action.payload.midasFields);
        state.reliJobColumns = sortColumns2(initialColumns);

        state.snapshotPosition = 0;
        state.snapshots = resetSnapshotsQueue(state);
      }
    });
  },
});

export const reliCurrentJobActions = currentJobSlice.actions;

export default currentJobSlice.reducer;
