import { ISubFolder, ModalDialogProps } from '../../../constants/Types';
import { DataType2 } from './Midas';

const initialFoldersOptionsVariables = Object.freeze([
  {
    name: '$CLIENT_NAME (Job Client Name)',
    value: '$CLIENT_NAME',
    id: '$CLIENT_NAME',
    type: DataType2.string as string,
  },
  {
    name: '$DESCRIPTION (Job Name)',
    value: '$DESCRIPTION',
    id: '$DESCRIPTION',
    type: DataType2.string as string,
  },
  {
    name: '$UPLOAD_USER (User who uploaded the document)',
    value: '$UPLOAD_USER',
    id: '$UPLOAD_USER',
    type: DataType2.string as string,
  },
  {
    name: '$FILE_NAME (Original file name)',
    value: '$FILE_NAME',
    id: '$FILE_NAME',
    type: DataType2.string as string,
  },
]);

const initialFoldersOptionsFunctions = Object.freeze([
  {
    id: 'date',
    name: 'date',
    type: DataType2.date,
    value: 'date',
    numberOfArguments: 2,
    inputs: [{ label: 'Enter date format', placeholder: '%Y_%m_%d', pattern: '^([ yYmMdD%_.-]*)$' }],
  },
  {
    id: 'upper',
    name: 'upper',
    type: DataType2.string,
    value: 'upper',
    numberOfArguments: 1,
    inputs: [],
  },
  {
    id: 'lower',
    name: 'lower',
    type: DataType2.string,
    value: 'lower',
    numberOfArguments: 1,
    inputs: [],
  },
  {
    id: 'length',
    name: 'length',
    type: DataType2.string,
    value: 'length',
    numberOfArguments: 1,
    inputs: [],
  },
  {
    id: 'trim',
    name: 'trim',
    type: DataType2.string,
    value: 'trim',
    numberOfArguments: 1,
    inputs: [],
  },
  {
    id: 'today',
    name: 'today',
    type: DataType2.date,
    value: 'today',
    numberOfArguments: 0,
    inputs: [],
  },
  {
    id: 'replace',
    name: 'replace',
    type: DataType2.string,
    value: 'replace',
    numberOfArguments: 3,
    inputs: [
      { label: 'Enter substring to replace', placeholder: 'substring to replace', pattern: '[.]*' },
      { label: 'Enter new substring', placeholder: 'new substring', pattern: '^[^"]*$' },
    ],
  },
]);

const initialFileNameOptions: ISubFolder = Object.freeze({
  id: 'fileName',
  value: '',
  placeholder: 'Enter File name pattern',
  error: false,
});

const initialFolderNameOptions: ISubFolder = Object.freeze({
  ...initialFileNameOptions,
  id: 'subfolder_0',
  placeholder: 'Enter Sub Folder name pattern',
});

const initialFunctionWindowOptions: ModalDialogProps = Object.freeze({
  title: '',
  type: '',
  id: '',
  isOpen: false,
  inputs: [],
  variable: '',
  folderId: '',
});

const INPUT_STRING_TIPS = Object.freeze([
  '- use ampersand (&) to concatenate different expressions;',
  '- wrap the text in quotes ("") to use it as a string;',
]);

const ROOT_FOLDER = 'allshared';

export {
  initialFoldersOptionsVariables,
  initialFoldersOptionsFunctions,
  initialFileNameOptions,
  initialFolderNameOptions,
  initialFunctionWindowOptions,
  INPUT_STRING_TIPS,
  ROOT_FOLDER,
};
