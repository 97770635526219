import { Types } from '../../../constants';
import { sanitizeDocType, isNumericType, convertScientificNotationToNumber, validateFieldValue } from './helpers';
import { convertDateFormat, convertAllDateFormatsToIso } from './utils';
import { DocumentTypeObj, FieldTypes } from '../constants/TableColumns';
import { DataType2, MidasFieldCalcType } from '../constants';

const mapFieldsToSave = (fields: Types.IStructuredPdfField[], removedFields: (string | undefined | null)[]) => {
  const editFieldsBody: any = {};
  if (removedFields.length > 0) {
    editFieldsBody.delete = removedFields;
  }
  if (fields && fields.map((field) => (field.guid ? true : false)).includes(true)) {
    const updatedFields = fields
      .map((field) =>
        field.guid
          ? {
              field_guid: field.guid,
              value: isNumericType(field.type) ? Number(field.value?.replace(/,/g, '')) : field.value,
              midas_type: field.type,
              properties: { score: 1 },
            }
          : null,
      )
      .filter((el) => el);
    if (updatedFields.length > 0) {
      editFieldsBody.update = updatedFields;
    }
  }
  if (fields && fields.map((field) => (field.guid ? false : true)).includes(true)) {
    const newFields = fields
      .map((field) =>
        !field.guid
          ? {
              field_name: field.name,
              value: isNumericType(field.type) ? Number(field.value?.replace(/,/g, '')) : field.value,
              midas_type: field.type,
              properties: { score: 1 },
            }
          : null,
      )
      .filter((el) => el);
    editFieldsBody.insert = newFields;
  }

  return editFieldsBody;
};

const mapDocumentField2 = (
  field: Types.IPdfField2,
  fieldIndex: number,
  keepEmptyValue?: boolean,
): Types.IStructuredPdfField | null => {
  try {
    const structureField: any = {};
    const name = field.field_id;

    if (!name) {
      return null;
    }

    if (field.guid) {
      structureField.guid = field.guid;
    }

    structureField.name = name;
    structureField.id = `${field.field_id}-${fieldIndex}`;

    if (name === 'document_model' || name === 'document_type_internal' || name === 'document_url') {
      return null;
    }

    if (name === 'document_type') {
      const isKnownType = Object.keys(DocumentTypeObj).join().includes(field.value);

      structureField.value = 'unknown';
      if (isKnownType) {
        structureField.value = field.value;
      }

      structureField.error = false;
      structureField.type = 'string';
      return structureField;
    }

    structureField.status = FieldTypes.correctlypredicted;

    let normalData = field.value;
    if (field.properties) {
      const fieldX0 = field.properties.x0;
      const fieldY0 = field.properties.y0;
      const fieldX1 = field.properties.x1;
      const fieldY1 = field.properties.y1;
      const fieldHeight = field.properties.height;
      const fieldWidth = field.properties.width;
      const page = field.properties.page;

      structureField.coordinates = {
        x0: fieldX0,
        y0: fieldY0,
        x1: fieldX1,
        y1: fieldY1,
        height: fieldHeight,
        width: fieldWidth,
        page,
        object_root: field.properties.__object_root,
        allCoordinatesArePresent:
          Boolean(fieldX0 && fieldY0 && fieldHeight && fieldWidth) || Boolean(fieldX0 && fieldY0 && fieldX1 && fieldY1),
      };
    }

    if (
      (!keepEmptyValue && !normalData) ||
      (normalData === '0' && !field.properties?.score) ||
      (normalData === '0.0' && !field.properties?.score)
    ) {
      return null;
    }

    structureField.type = field.midas_type;

    if (field.midas_type === DataType2.date) {
      const isValid = validateFieldValue(field.value, field.midas_type);
      if (!isValid && typeof Number(field.value) === 'number') {
        // this conversion necessary for cases when we receive Unix epoch timestamp
        const correctDate = new Date(Number(field.value) * 1000).toUTCString();

        normalData = convertAllDateFormatsToIso(correctDate);
      }
    }

    const isFieldOfNumericType = isNumericType(structureField.type);

    if (isFieldOfNumericType) {
      normalData = convertScientificNotationToNumber(normalData);
    }

    structureField.value = normalData;

    const hasError = field.properties?.error_text;

    structureField.error = hasError;
    if (hasError) {
      structureField.score = null;
      structureField.errorText = field.properties?.error_text;

      structureField.value = '';
      return structureField;
    }

    structureField.score = field.properties?.score;

    return structureField;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const mapDocument = (document: Types.IJobFileData): Types.ITableCellsData2 => {
  const { guid: id, name: name, state: state, route: type, hash: hash } = document;
  return {
    id,
    name,
    state,
    hash,
    type: sanitizeDocType(type),
    fields: [],
  };
};

const mapJob = (job: Types.JobServerInfo, userName: string, userLevel: boolean): Types.IJobFormattedData => {
  const { 'create-time': dateTime, client, job_id: id, description: name, status } = job;
  const resolvedUserName = userLevel ? (job.upload_user ? job.upload_user : 'Team Member') : userName;
  return {
    id,
    client,
    name,
    status,
    userName: resolvedUserName,
    dateTime: convertDateFormat(dateTime),
  };
};

const mapMidasField2 = (field: {
  id: string;
  'field-type': string;
  'calc-type': MidasFieldCalcType;
  description: string;
}) => {
  const { 'field-type': type, 'calc-type': calcType, id, description } = field;

  return { id, type, calcType, description };
};

export { mapFieldsToSave, mapDocumentField2, mapDocument, mapJob, mapMidasField2 };
