export enum DocumentAction {
  Approve = 'approved',
  Reject = 'rejected',
}

export enum PaginationAction {
  ChangePage = 'change-page',
  ChangeRowsPerPage = 'change-rows-per-page',
}

export enum FileTypes {
  PDF = 'application/pdf',
  XML = 'application/xml',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum ReliHostname {
  Dev = 'RELI_HOSTNAME_DEV',
  Prod = 'RELI_HOSTNAME_PROD',
  QA = 'RELI_HOSTNAME_QA',
}

export enum ReliMidasHostname {
  Dev = 'RELI_MIDAS_DEV',
  Prod = 'RELI_MIDAS_PROD',
  QA = 'RELI_MIDAS_QA',
}

export enum MidasFieldCalcType {
  DATUM = 'datum',
  AGGREGATOR = 'aggregator',
  FORMULA = 'formula',
}

export type FieldNodeElement = {
  column: string;
  id: string;
  state: string;
};

export type Vendors = 'alkymi' | 'nanonets' | 'unknown';
