import { Cookie } from '../../services';

export const stickModalToBorders = (infoModalRefElem: HTMLDivElement) => {
  const offset = infoModalRefElem.getBoundingClientRect();

  if (offset.left < 20 && offset.top < 20) {
    infoModalRefElem.style.top = '20px';
    infoModalRefElem.style.left = '20px';
    infoModalRefElem.style.transformOrigin = 'top left';
  } else if (offset.right > window.innerWidth - 20 && offset.top < 20) {
    infoModalRefElem.style.top = '20px';
    infoModalRefElem.style.left = 'auto';
    infoModalRefElem.style.right = '20px';
    infoModalRefElem.style.transformOrigin = 'top right';
  } else if (offset.right > window.innerWidth - 20 && offset.bottom > window.innerHeight - 20) {
    infoModalRefElem.style.top = 'auto';
    infoModalRefElem.style.bottom = '20px';
    infoModalRefElem.style.left = 'auto';
    infoModalRefElem.style.right = '20px';
    infoModalRefElem.style.transformOrigin = 'bottom right';
  } else if (offset.left < 20 && offset.bottom > window.innerHeight - 20) {
    infoModalRefElem.style.top = 'auto';
    infoModalRefElem.style.bottom = '20px';
    infoModalRefElem.style.left = '20px';
    infoModalRefElem.style.transformOrigin = 'bottom left';
  } else {
    const closestXBorder =
      window.innerWidth - offset.right > offset.left
        ? { border: 'left', value: offset.left }
        : { border: 'right', value: window.innerWidth - offset.right };
    const closestYBorder =
      window.innerHeight - offset.bottom > offset.top
        ? { border: 'top', value: offset.top }
        : { border: 'bottom', value: window.innerHeight - offset.bottom };
    if (closestXBorder.value > closestYBorder.value) {
      if (closestYBorder.border === 'top') {
        infoModalRefElem.style.transformOrigin = 'top';
        infoModalRefElem.style.top = '20px';
      } else {
        infoModalRefElem.style.transformOrigin = 'bottom';
        infoModalRefElem.style.top = `${window.innerHeight - offset.height - 20}px`;
      }
    } else {
      if (closestXBorder.border === 'left') {
        infoModalRefElem.style.transformOrigin = 'left';
        infoModalRefElem.style.left = '20px';
      } else {
        infoModalRefElem.style.transformOrigin = 'right';
        infoModalRefElem.style.left = `${window.innerWidth - offset.width - 20}px`;
      }
    }
  }
};

export const windowResizeHandler = (infoModalRefElem: HTMLDivElement) => {
  if (infoModalRefElem) {
    const offset = infoModalRefElem.getBoundingClientRect();
    if (offset.right + 20 > window.innerWidth) {
      infoModalRefElem.style.left = 'auto';
      infoModalRefElem.style.right = '20px';
      if (offset.top === 20) {
        infoModalRefElem.style.transformOrigin = 'top right';
      } else if (offset.bottom + 20 === window.innerHeight) {
        infoModalRefElem.style.transformOrigin = 'bottom right';
      } else {
        infoModalRefElem.style.transformOrigin = 'right';
      }
    } else if (offset.bottom + 20 > window.innerHeight) {
      infoModalRefElem.style.top = 'auto';
      infoModalRefElem.style.bottom = '20px';
      if (offset.right + 20 === window.innerWidth) {
        infoModalRefElem.style.transformOrigin = 'bottom right';
      } else if (offset.left === 20) {
        infoModalRefElem.style.transformOrigin = 'bottom left';
      } else {
        infoModalRefElem.style.transformOrigin = 'bottom';
      }
    }
  }
};

export const convertSecondsToText = (seconds: number | null): string => {
  if (typeof seconds !== 'number') return '';
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;
  return `${minutes} minutes ${secondsLeft} seconds`;
};

export const getSessionExpireCountDownTime = (): number | null => {
  const gatewaySessionExpiresAt = Cookie.getGatewaySessionExpiresAt();
  if (!gatewaySessionExpiresAt) return null;
  const timeNow = new Date().getTime();
  const gatewaySessionDiffInMilliseconds = Number(gatewaySessionExpiresAt) - timeNow;
  const gatewaySessionDiffInSeconds = Math.floor(gatewaySessionDiffInMilliseconds / 1000);
  return gatewaySessionDiffInSeconds;
};

export enum TerminationErrors {
  GatewaySessionExpired = '1',
  FailedToRefreshOktaToken = '2',
  OktaTokenExtendSessionLimitReached = '2.1',
  FailedToRefreshOktaSession = '3',
}
