export enum DebugHeaders {
  LoggerId = 'x-mirador-logger-id',
  JobId = 'x-mirador-reli-job-id',
  FileGuid = 'x-mirador-file-guid',
  ReliFileGuid = 'x-mirador-reli-file-guid',
  UniqueSessionId = 'X-Mirador-RELI-Session-Id',
}

export enum ReliHeaders {
  ResponseType = 'x-mirador-reli-response',
  ConvertToXml = 'x-mirador-reli-convert-to-xml',
  Hostname = 'x-mirador-reli-hostname',
  MidasHostname = 'x-mirador-reli-midas-hostname',
  RELIApiVersion = 'reli-api-version',
}

export enum AuthHeaders {
  OktaToken = 'X-OKTA-TOKEN',
  AccessToken = 'X-ACCESS-TOKEN',
}
