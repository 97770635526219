import React from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import FolderIcon from '@mui/icons-material/Folder';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CreateIcon from '@mui/icons-material/Create';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PieChartIcon from '@mui/icons-material/PieChart';
import SettingsIcon from '@mui/icons-material/Settings';

import { IMenuItemsIcons } from '../Types';

export const menuItemsIcons = (cl: string): IMenuItemsIcons => {
  return {
    dashboardIcon: <HomeOutlinedIcon className={cl ?? ''} />,
    calendarIcon: <EventOutlinedIcon className={cl ?? ''} />,
    requestsIcon: <NotificationsNoneIcon className={cl ?? ''} />,
    employeeDirectoryIcon: <FolderSharedOutlinedIcon className={cl ?? ''} />,
    offlineDataManagementIcon: <DataUsageIcon className={cl ?? ''} />,
    reliIcon: <AssessmentOutlinedIcon className={cl ?? ''} />,
    k1Icon: <FolderIcon className={cl ?? ''} />,
    spacesIcon: <AppsOutlinedIcon className={cl ?? ''} />,
    privateInvestmnetDeskIcon: <BusinessCenterIcon className={cl ?? ''} />,
    insightsIcon: <CreateIcon className={cl ?? ''} />,
    documentLibraryIcon: <DescriptionOutlinedIcon className={cl ?? ''} />,
    toolsIcon: <CheckBoxOutlineBlankOutlinedIcon className={cl ?? ''} />,
    teamDirectoryIcon: <PeopleAltOutlinedIcon className={cl ?? ''} />,
    myClientsIcons: <AssignmentIndOutlinedIcon className={cl ?? ''} />,
    addeparLairdIcon: <PieChartIcon className={cl ?? ''} />,
    settingsIcon: <SettingsIcon className={cl ?? ''} />,
    unknownItemIcon: <DashboardIcon className={cl ?? ''} />,
  };
};
